
<ng-template #incomeStatementCategorySelectorTemplate  let-model="item" let-index="index">
    <span *ngIf="model.extraPath" class="tree-selector-path">{{model.extraPath}}</span>{{model.name}}
</ng-template>

<div class="input-group" aria-label="Select Income Statement Category">
    <input id="selectIncomeStatementCategory"
        type="text"
        placeholder="Filter Income Statement Category..."
        class="form-control exclude-modal-styling"
        [ngClass]="{ 'overridden': isOverridden }"
        autocomplete="off"
        (typeaheadOnBlur)="onIncomeStatementCategoryBlur($event)"
        [(ngModel)]="incomeStatementCategoryFilter"
        [typeahead]="incomeStatementCategories$"
        typeaheadOptionField="name"
        container="body"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        (typeaheadOnSelect)="onIncomeStatementCategorySelected($event)"
        [typeaheadOptionsLimit]="1000"
        [typeaheadItemTemplate]="incomeStatementCategorySelectorTemplate"
        [disabled]="isDisabled || !isInitialized"/>
    <button
        class="ace-btn btn-secondary has-icon"
        type="button"
        title="View all income statement categories"
        (click)="openPickerModal()"
        [disabled]="isDisabled || !isInitialized">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
</div>
