import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { CommonModule } from '@angular/common';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SiteDescriptorsListComponent } from './Site-Descriptors-List/siteDescriptorsList.component';
import { SiteDescriptorSelectorComponent } from './Site-Descriptor-Selector/siteDescriptorSelector.component';
import { SiteDescriptorsEditComponent } from './Site-Descriptors-Edit/siteDescriptorsEdit.component';
import { ClientServicesPanelComponent } from './Panel/clientServicesPanel.component';
import { DeliveryDetailsComplianceReturnsComponent } from './Panel/Delivery-Details/deliveryDetailsComplianceReturns.component';
import { DeliveryDetailsTeamRetrievesComponent } from './Panel/Delivery-Details/deliveryDetailsTeamRetrieves.component';
import { DeliveryDetailsTransmitComponent } from './Panel/Delivery-Details/deliveryDetailsTransmit.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DeliveryDetailsConfigureModalComponent } from './Panel/Delivery-Details/deliveryDetailsConfigureModal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DeliveryDetailsAssessmentAllocationComponent } from './Panel/Delivery-Details/deliveryDetailsAssessmentAllocation.component';
import { DeliveryDetailsPaymentBatchWorkflowComponent } from './Panel/Delivery-Details/deliveryDetailsPaymentBatchWorkflow.component';
import { deliveryDetailsFeedSpecsComponent } from './Panel/Delivery-Details/deliveryDetailsFeedSpecs.component';

@NgModule({
    imports: [
        BusyIndicatorModule,
        CommonModule,
        WeissmanSharedModule,
        TypeaheadModule,
        CurrencyMaskModule,
        PopoverModule
    ],
    declarations: [
        SiteDescriptorsListComponent,
        SiteDescriptorsEditComponent,
        SiteDescriptorSelectorComponent,
        ClientServicesPanelComponent,
        DeliveryDetailsComplianceReturnsComponent,
        DeliveryDetailsTeamRetrievesComponent,
        DeliveryDetailsTransmitComponent,
        DeliveryDetailsConfigureModalComponent,
        DeliveryDetailsAssessmentAllocationComponent,
        DeliveryDetailsPaymentBatchWorkflowComponent,
        deliveryDetailsFeedSpecsComponent
    ],
    exports: [ClientServicesPanelComponent]
})
export class ClientServicesModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('siteDescriptorsEdit', SiteDescriptorsEditComponent);
        hybridAdapterUtility.downgradeNg2Component('clientServicesPanel', ClientServicesPanelComponent);
    }
}
