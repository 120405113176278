import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, RowNode } from 'ag-grid-community';
import { AssetListAgGridDataSource, AssetListDataSourceParams } from './agGridDataSource';
import {
    AssetRepository,
    CompanyAssetDescriptorRepository,
    CostAdjustmentRepository,
    EntityImportRepository
} from '../../Repositories';
import { DecimalPipe } from '@angular/common';
import { WeissmanDateFormatPipe, WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../Busy-Indicator';
import { AssetSummary } from '../Models/assetSummary';
import {
    AssetListGridActionCellRendererComponent,
    ICellRendererParamsForAssetListGridAction
} from './agGridActionCellRenderer.component';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../AgGrid/MultiSelectTracker';
import { AssetDetailsComponent, AssetDetailsParams } from '../Asset-Details/assetDetails.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import { BehaviorSubject, lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { InstanceRights, RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { AssetLienDate } from '../Asset-Lien-Date/assetLienDate.component';
import { AssetLimitOption } from './Asset-Limit/assetLimit.component';
import { AssetBulkUpdateComponent, AssetBulkUpdateParams } from './Asset-Bulk-Update/assetBulkUpdate.component';
import { AssetChangeIconCellRendererComponent } from './agGridChangeIconCellRenderer.component';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../AgGrid/ToolPanel/models';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { ASSET_LIST_HELP } from './assetList.component.help';
import { HotkeyService } from '../../../UI-Lib/HotKeys/hotKeys.service';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import {
    MessageBoxButtons,
    MessageBoxOptions,
    MessageBoxResult,
    MessageBoxService
} from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { takeUntil } from 'rxjs/operators';
import {
    DeleteConfirmationComponent,
    DeleteConfirmationComponentParams
} from '../../../Common/Delete-Confirmation/deleteConfirmation.component';
import {
    AgGridLinkCellRendererComponent,
    AgGridLinkCellRendererParams
} from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { ParcelService } from '../../../Entity/Parcel/parcel.service.upgrade';
import { SiteRepository } from '../../../Core-Repositories';
import { AgGridYesNoFloatingFilterComponent } from '../../AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';
import {
    AgGridTextWithTotalsUpdateCellRendererComponent,
    AgGridTextWithTotalsUpdateCellRendererParams
} from '../../AgGrid/CellRenderers/agGridTextWithTotalsUpdateCellRenderer.component';
import { AgGridPersistenceService } from '../../AgGrid/agGridPersistence.service';
import { TimerService } from '../../../UI-Lib/Utilities';

import * as _ from 'lodash';
import {
    AssetImportProgressComponent,
    AssetImportProgressParams
} from './Asset-Import-Progress/assetImportProgress.component';
import { AssetImportProgressService } from './Asset-Import-Progress/assetImportProgress.service';

enum GridSourceOptionEnum {
    Imported,
    Reporting
}

@Component({
    selector: 'asset-list',
    templateUrl: './assetList.component.html',
    styleUrls: ['./assetList.component.scss'],
    providers: [HotkeyService]
})
export class AssetListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _assetRepository: AssetRepository,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _companyService: CompanyService,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _modalService: WeissmanModalService,
        private readonly _restrictService: RestrictService,
        private readonly _companyAssetDescriptorRepository: CompanyAssetDescriptorRepository,
        private readonly _costAdjustmentRepository: CostAdjustmentRepository,
        private readonly _helpService: HelpService,
        private readonly _hotkeyService: HotkeyService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _siteService: SiteRepository,
        private readonly _parcelService: ParcelService,
        private readonly _agGridPersistenceService: AgGridPersistenceService,
        private readonly _assetImportProgressService: AssetImportProgressService,
        private readonly _timer: TimerService
    ) { }

    @Input() companyIdFilter: number;
    @Input() siteIdFilter: number;
    @Input() parcelIdFilter: number;
    @Input() isEmbeddedMode: boolean = false;
    @Input() loading: boolean;
    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private readonly _GRID_SOURCE_OPTION_IMPORTED_VALUES: Compliance.NameValuePair<GridSourceOptionEnum> = {
        name: 'Source Values',
        value: GridSourceOptionEnum.Imported
    };

    private readonly _GRID_SOURCE_OPTION_REPORTING_VALUES: Compliance.NameValuePair<GridSourceOptionEnum> = {
        name: 'Reporting Values',
        value: GridSourceOptionEnum.Reporting
    };

    private readonly _IMPORTED_VALUES_FILTER_OPTION_LIEN: Compliance.NameValuePair<string> = {
        name: 'Lien Date',
        value: 'imported-values-filter-lien'
    };

    private readonly _IMPORTED_VALUES_FILTER_OPTION_IMPORT: Compliance.NameValuePair<string> = {
        name: 'Source File',
        value: 'imported-values-filter-import-file'
    };

    private readonly _IMPORTED_VALUES_GRID_ID: System.Guid = '454CB87C-DE80-4334-9CE0-49A828063D7E';
    private readonly _REPORTING_VALUES_GRID_ID: System.Guid = '5EF912A2-28AA-4143-A625-2CACDAD86F10';

    breadcrumbs: BreadCrumb[] = [];
    assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];
    lienDate: AssetLienDate;
    assetGridTotalsRow: any;
    assetGridSelectedTotalsRow: any;
    selectedTotalsLoading: boolean = true;
    agGridReady: boolean;
    companyId: number;
    newCompanyId: number;
    companyName: string;
    canEdit: boolean = false;
    canViewRyanPrivateItems: boolean = false;
    hasComplianceFeaturesOverview: boolean = false;
    isInitialized: boolean = false;
    importFiles: Compliance.ImportFileInfoModel[] = null;
    gridId: System.Guid = this._REPORTING_VALUES_GRID_ID;
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    limitOption: AssetLimitOption;
    advancedFilters: Compliance.AdvancedAssetFilters;
    totalsLoading: any;
    busyRefId: string = this._busyIndicatorService.generateUniqueMessageIdentifier();
    filterHref: string;
    siteName: string;
    parcelName: string;

    activeImportLongRunningProcessId: number;
    activeImportSelectedRowsCount: number;
    activeImportProcessedRowsCount: number;
    activeImportTimeRemaining: string;

    gridSourceOptions: Compliance.NameValuePair<GridSourceOptionEnum>[] = [
        this._GRID_SOURCE_OPTION_IMPORTED_VALUES,
        this._GRID_SOURCE_OPTION_REPORTING_VALUES
    ];

    importGridFilterOptions: Compliance.NameValuePair<string>[] = [
        this._IMPORTED_VALUES_FILTER_OPTION_LIEN,
        this._IMPORTED_VALUES_FILTER_OPTION_IMPORT
    ];

    selectedGridSourceOption = this._GRID_SOURCE_OPTION_REPORTING_VALUES;
    selectedImportGridFilterOption = this._IMPORTED_VALUES_FILTER_OPTION_LIEN;
    selectedImportFileId: number;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as AssetSummary).reportedAssetId),
                'totals-row': (params) => params.data && params.data.totalsRow,
                'selected-totals-row': (params) => params.data && params.data.selectedTotalsRow
            }
        })
        .withColumnPinning()
        .buildDefault(this);

    gridTracker: AgGridMultiSelectTracker;

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();

            if (this.selectedGridSourceOption === this._GRID_SOURCE_OPTION_REPORTING_VALUES){
                searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();
                if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0){
                    searchParams.selectedRows.selectAllRows = true;
                }
            }
            const exportModel: Compliance.AssetExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn,
                fileFormat,
                layoutName: this._layoutName,
                viewName: this.selectedGridSourceOption.name
            };

            let lrp$: Observable<number>;

            switch (this.selectedGridSourceOption.value) {
                case GridSourceOptionEnum.Imported:
                    lrp$ = this._assetRepository.startSourceAssetExport(this.companyId, exportModel);
                    break;
                case GridSourceOptionEnum.Reporting:
                    lrp$ = this._assetRepository.startReportingAssetExport(this.companyId, exportModel);
                    break;
            }

            const longRunningProcessId = await lastValueFrom(lrp$);
            const longRunningProcessTypeId = this.selectedGridSourceOption === this._GRID_SOURCE_OPTION_IMPORTED_VALUES
                ? Compliance.LongRunningProcessTypeEnum.ExportSourceAssets : Compliance.LongRunningProcessTypeEnum.ExportReportingAssets;

            return { longRunningProcessId, longRunningProcessTypeId };
        },
        canCancel: true,
        showFileFormatSelection: true,
        getDisabled: () => !this._gridDataSource || this._gridDataSource.isRefreshing
    };

    private _gridApi: GridApi;
    private _gridDataSource: AssetListAgGridDataSource;
    private _gridMultiSelectSub: Subscription;
    private _costAdjustmentTypes: Compliance.CostAdjustmentTypeModel[];
    private _autoUpdateTotals: boolean;
    private _busyRef: BusyIndicatorRef;
    private _destroy$: Subject<void> = new Subject();
    private _layoutDestroy$: Subject<void> = new Subject();
    private _assetImportInProgressModalOpened: boolean = false;
    private _layoutName: string;
    private _calculateTotalsClicked: boolean;

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    get importFilterOptionVisible(): boolean {
        return this.selectedGridSourceOption === this._GRID_SOURCE_OPTION_IMPORTED_VALUES && !this.isEmbeddedMode;
    }

    get lienDateVisible(): boolean {
        return this.selectedGridSourceOption === this._GRID_SOURCE_OPTION_REPORTING_VALUES ||
            this.selectedImportGridFilterOption === this._IMPORTED_VALUES_FILTER_OPTION_LIEN;
    }

    get furtherLimitVisible(): boolean {
        return this.selectedGridSourceOption === this._GRID_SOURCE_OPTION_REPORTING_VALUES && !this.isEmbeddedMode;
    }

    get importFileListVisible(): boolean {
        return (this.selectedGridSourceOption === this._GRID_SOURCE_OPTION_IMPORTED_VALUES &&
            this.selectedImportGridFilterOption === this._IMPORTED_VALUES_FILTER_OPTION_IMPORT) && !this.isEmbeddedMode;
    }

    get isLienDatePrior(): boolean {
        return this.lienDate?.lienDateType === Compliance.AssetLienDateTypeEnum.Prior;
    }

    get isLienDateExact(): boolean {
        return this.lienDate?.lienDateType === Compliance.AssetLienDateTypeEnum.Exact;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(ASSET_LIST_HELP);
        this._hotkeyService.add({ keys: 'alt.r' }).subscribe(() => this.gridTracker.getSelectedRowsCount() && this.bulkUpdate('r'));

        this._agGridPersistenceService.totalsRowAutomaticUpdate$(this.gridId as string)
            .pipe(takeUntil(this._destroy$))
            .subscribe(autoUpdate => this._autoUpdateTotals = autoUpdate);
        this._agGridPersistenceService.currentGridLayoutApplied$(this.gridId as string)
            .pipe(takeUntil(this._layoutDestroy$))
            .subscribe(layoutName => this._layoutName = layoutName);

        if(this.companyIdFilter) {
            this.companyId = this.companyIdFilter;
        } else {
            this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
        }

        if(!this.isEmbeddedMode && typeof this.siteIdFilter === 'undefined') {
            this.siteIdFilter = parseInt(this._routerService.getQuerystringParam('siteId'));
        }

        if(!this.isEmbeddedMode && typeof this.parcelIdFilter === 'undefined') {
            this.parcelIdFilter = parseInt(this._routerService.getQuerystringParam('parcelId'));
        }

        this.newCompanyId = this.companyId;

        let busyRef: BusyIndicatorRef;

        if(this.isEmbeddedMode) {
            this.loading = true;
            this.loadingChange.emit(true);

            if(typeof this.siteIdFilter !== 'undefined') {
                this.filterHref = this._routerService.getHrefFromState('assetListSiteFilter', { companyId: this.companyId,  siteId: this.siteIdFilter });
            }
            else if(typeof this.parcelIdFilter !== 'undefined') {
                this.filterHref = this._routerService.getHrefFromState('assetListParcelFilter', { companyId: this.companyId,  parcelId: this.parcelIdFilter });
            }
        }
        else {
            busyRef = this._busyIndicatorService.show({ message: 'Loading' });
        }

        try {
            this.canEdit = await this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write);
            this.canViewRyanPrivateItems = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);

            let sitePromise: Promise<void>;

            if(!this.isEmbeddedMode && this.siteIdFilter) {
                sitePromise = lastValueFrom(this._siteService.getSiteName(this.siteIdFilter)).then(result => {
                    this.siteName = result;
                });
            }

            let parcelPromise: Promise<void>;

            if(!this.isEmbeddedMode && this.parcelIdFilter) {
                parcelPromise = this._parcelService.getView(this.parcelIdFilter, false).then(result => {
                    this.parcelName = result.parcel.acctNum;
                });
            }

            let companyInfo: Core.CompanyModel;

            const companyPromise = this._companyService.getInfoForCompliance(this.companyId);
            companyPromise.then((company) => {
                this.companyName = company.companyName;
                companyInfo = company;
            });

            const assetDescriptorsPromise = lastValueFrom(this._companyAssetDescriptorRepository.getByCompanyId(this.companyId)).then(result => {
                    this.assetDescriptors = result;
                });

            const costAdjustmentTypesPromise = lastValueFrom(this._costAdjustmentRepository.getTypes()).then(result => {
                this._costAdjustmentTypes = result;
            });

            await Promise.all([
                companyPromise,
                assetDescriptorsPromise,
                costAdjustmentTypesPromise,
                sitePromise,
                parcelPromise
            ]);

            this.breadcrumbs.push({
                name: this.companyName,
                target: 'company',
                options: { companyId: this.companyId }
            });

            if(this.siteName) {
                this.breadcrumbs.push({
                    name: this.siteName,
                    target: 'siteRedirect',
                    options: { entityID: this.siteIdFilter }
                });
            }

            if(this.parcelName) {
                this.breadcrumbs.push({
                    name: this.parcelName,
                    target: 'parcelRedirect',
                    options: { entityID: this.parcelIdFilter }
                });
            }

            if (!companyInfo.ppReturnPreparationAllowed && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, companyInfo.instanceID)) {
                this._routerService.go('unauthorizedAccess', {});
                return Promise.resolve();
            }

            if (!this._restrictService.hasInstanceRight(InstanceRights.COMPANYDATAIMPORTSVIEW, companyInfo.instanceID)) {
                this.gridSourceOptions.splice(0, 1);
            }

            this.isInitialized = true;
        } finally {
            if(this.isEmbeddedMode) {
                this.loading = false;
                this.loadingChange.emit(false);
            }
            else {
                busyRef.hide();
            }
        }

        this._assetImportProgressService.importStarted$.pipe(takeUntil(this._destroy$))
            .subscribe(async x => {
                if (!x) {
                    return;
                }

                console.log(`AssetList (OnImportStarted) ${this.activeImportLongRunningProcessId} === ${x.longRunningProcessId} selectedRowsCount=${x.selectedRowsCount}`);
                if (this.activeImportLongRunningProcessId === x.longRunningProcessId && this.activeImportSelectedRowsCount) {
                    return;
                }

                this.activeImportLongRunningProcessId = x.longRunningProcessId;
                this.activeImportSelectedRowsCount = x.selectedRowsCount;

                await this.openAssetImportInProgressModal();
            });

        this._assetImportProgressService.importFinished$.pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.activeImportLongRunningProcessId = null;
                this.activeImportSelectedRowsCount = 0;});

        this._assetImportProgressService.processedRows$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.activeImportProcessedRowsCount = x);

        this._assetImportProgressService.timeRemaining$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.activeImportTimeRemaining = x);

        this._assetImportProgressService.selectedRowsCountLoaded$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.activeImportSelectedRowsCount = x);

        await this._assetImportProgressService.initialize(this.companyId);

        return Promise.resolve();
    }

    ngOnDestroy(): void {
        this._assetImportProgressService.ngOnDestroy();
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._destroy$.next();
        this._destroy$.complete();
        this._layoutDestroy$.next();
        this._layoutDestroy$.complete();
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;
        this._gridApi.showLoadingOverlay();

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this.gridTracker.setExternalFilterApplied(true);

        if (!this.agGridReady) {
            this._setGridColumns();
        }

        this.agGridReady = true;
        this._gridApi.hideOverlay();

        // notify the bulk update button (on the column tool panel) every time the grid selection changes
        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(async () => {
            const isBulkUpdateVisible = this.canEdit && this.lienDate && this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
            this.isBulkDeleteVisible$.next(isBulkUpdateVisible);
            if (this._calculateTotalsClicked) {
                await this._reloadTotals();
            }
        });

        this.gridTracker.isSelectingRange$.pipe(takeUntil(this._destroy$)).subscribe(loading => {
            if (!loading) {
                const isBulkUpdateVisible = this.canEdit && this.lienDate && this.gridTracker.hasSelectedRows();
                this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
                this.isBulkDeleteVisible$.next(isBulkUpdateVisible);
            } else {
                this.isBulkUpdateVisible$.next(false);
                this.isBulkDeleteVisible$.next(false);
            }
        });
    }

    onLienDateChanged(lienDate: AssetLienDate): void {
        this.lienDate = lienDate;
    }

    onAdvancedOptionChanged(advancedFilters: Compliance.AdvancedAssetFilters): void {
        this.advancedFilters = advancedFilters;
    }

    onLimitOptionChanged(limitOption: AssetLimitOption): void {
        this.limitOption = limitOption;
    }

    onImportGridFilterChanged(importGridFilterOption: Compliance.NameValuePair<string>): void {
        this.selectedImportGridFilterOption = importGridFilterOption;
    }

    onSelectedImportFileChanged(importFileId: number): void {
        this.selectedImportFileId = importFileId;
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    async bulkUpdate(hotkeyFocus?: string): Promise<void> {
        const reportingAssetIds: number[] = [];
        this._gridApi.forEachNode(x => {
            if (x.data) {
                reportingAssetIds.push(x.data.reportingAssetId);
            }
        });

        const params: AssetBulkUpdateParams = {
            companyId: this.companyId,
            lienDate: this.lienDate && this.lienDate,
            reportingAssetIds: reportingAssetIds,
            selection: this.gridTracker.getSelectedRowsModel(),
            selectedCount: this.gridTracker.getSelectedRowsCount(),
            assetListLastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
            hotkeyFocus: hotkeyFocus,
            siteId: this.siteIdFilter,
            parcelId: this.parcelIdFilter
        };

        const result = await this._modalService.showAsync(AssetBulkUpdateComponent, params, 'modal-lg');
        if (!result) {
            return Promise.resolve();
        }

        await this._refreshRows(result);

        return Promise.resolve();
    }

    async refresh(): Promise<void> {
        if (this.selectedGridSourceOption.value === GridSourceOptionEnum.Imported) {
            await this._refreshImportFiles();
        }

        await this._refreshDataSource();
    }

    async addAsset(): Promise<void> {
        const params: AssetDetailsParams = {
            reportingAssetId: null,
            assetId: null,
            lienDate: this.lienDate && this.lienDate.date,
            canEdit: this.canEdit,
            createNewAsset: true,
            topLevelCompanyId: this.companyId,
            newAssetCompanyId: this.newCompanyId || this.companyId,
            assetDescriptors: this.assetDescriptors,
            canViewRyanPrivateItems: this.canViewRyanPrivateItems
        };

        const result = await this._modalService.showAsync(AssetDetailsComponent, params, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        if (result.hasChanges) {
            this.newCompanyId = result.newAssetCompanyId;
            this._refreshDataSource();
        }

        return Promise.resolve();
    }

    async setSelectedGridSourceOption(value: Compliance.NameValuePair<GridSourceOptionEnum>): Promise<void> {
        if (this.selectedGridSourceOption === value) {
            return;
        }

        if (this._gridDataSource) {
            this._gridDataSource.clearTotals();
            this._gridDataSource = null;
            this._gridApi.setDatasource([] as any);
        }

        this.selectedGridSourceOption = value;
        this.gridId = this._getGridIdBySource(this.selectedGridSourceOption.value);
        this._setGridColumns();

        this._layoutDestroy$.next();
        this._agGridPersistenceService.currentGridLayoutApplied$(this.gridId as string)
            .pipe(takeUntil(this._layoutDestroy$))
            .subscribe(layoutName => this._layoutName = layoutName);

        if ((this.selectedGridSourceOption.value === GridSourceOptionEnum.Imported) && !this.importFiles) {
            await this._refreshImportFiles();
        }
    }

    getListViewHelpContentId(viewName: string): string {
        switch(viewName) {
            case 'Reporting Values':
                return 'asset-list.reporting-values-view';
            case 'Imported Values':
                return 'asset-list.imported-values-view';
            default:
                return '';
        }
    }

    async bulkDelete(): Promise<void> {
        const confirmOptions = {
            message: 'Are you sure you want to delete selected assets?',
            buttons: MessageBoxButtons.OKCancel
        } as MessageBoxOptions;

        const result = await this._messageBoxService.open(confirmOptions);

        if (result === MessageBoxResult.OK) {

            const params: DeleteConfirmationComponentParams = {
                item: 'Assets',
                message: 'Are you sure you want to delete selected assets? If you choose to proceed, all information related to assets will be removed and cannot be recovered.'
            };

            const deleteResult = await this._modalService.showAsync(DeleteConfirmationComponent, params, 'modal-md');

            if (deleteResult) {
                this._showBusyIndicator('Bulk Delete', 'Deleting assets', null, false, false);

                const model: Compliance.AssetBulkDeleteModel = {
                    lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
                    selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                    selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows,
                    lienDate: this.lienDate.date,
                    filterBySiteId: this.siteIdFilter,
                    filterByParcelId: this.parcelIdFilter
                };

                try {
                    const longRunningProcessId = await lastValueFrom(this._assetRepository.startBulkDelete(this.companyId, model));
                    await this._busyRef.setLongRunningProcessId(longRunningProcessId);
                }
                catch (e) {
                    await this._hideBusyIndicator();
                    return Promise.reject(e);
                }
            }
        }

        return Promise.resolve();
    }

    async openAssetImportInProgressModal(): Promise<void> {
        if (!this._assetImportInProgressModalOpened) {
            this._assetImportInProgressModalOpened = true;

            const params: AssetImportProgressParams = {
                assetImportProgressService: this._assetImportProgressService
            };

            try {
                await this._modalService.showAsync(AssetImportProgressComponent, params, 'modal-md');
            } finally {
                this._assetImportInProgressModalOpened = false;
            }
        }
    }

    private async _refreshImportFiles(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            const importContentTypes = [Compliance.ImportContentTypeIdEnum.Assets, Compliance.ImportContentTypeIdEnum.AssetUpdatesOnly];
            const result = await lastValueFrom(this._entityImportRepository.getByCompanyTransferred(this.companyId, importContentTypes));
            this.importFiles = result.data;

            let importFile = _.find(this.importFiles, x => x.importFileId === this.selectedImportFileId);

            if (!importFile) {
                importFile = _.first(this.importFiles);
            }

            this.selectedImportFileId = importFile && importFile.importFileId;
        } finally {
            busyRef.hide();
        }
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private async _viewAssetDetails(params: ICellRendererParamsForAssetListGridAction): Promise<void> {
        const assetSummary = params.data as AssetSummary;

        const inputParams: AssetDetailsParams = {
            reportingAssetId: assetSummary.reportingAssetId,
            assetId: assetSummary.assetId,
            lienDate: this.lienDate.date,
            canEdit: this.canEdit,
            createNewAsset: false,
            topLevelCompanyId: this.companyId,
            assetDescriptors: this.assetDescriptors,
            newAssetCompanyId: assetSummary.companyId,
            canViewRyanPrivateItems: this.canViewRyanPrivateItems
        };

        const result = await this._modalService.showAsync(AssetDetailsComponent, inputParams, 'modal-xl');

        if (!(result && result.hasChanges)) {
            return Promise.resolve();
        }

        if (result.savedModels && result.savedModels.length > 0) {
            const allNodesOnPage: RowNode[] = [];
            this._gridApi.forEachNode(x => {
                const gridRow = x.data as AssetSummary;
                if (gridRow.reportedAssetId) {
                    const row = result.savedModels.find(y => y.reportingAsset.reportingAssetId == gridRow.reportedAssetId);
                    if (row) {
                        gridRow.updateModel(row);
                    }
                    allNodesOnPage.push(x);
                }
            });
            this._gridApi.redrawRows({rowNodes: allNodesOnPage});

            if (this._autoUpdateTotals) {
                this._handleTotalsUpdate(result.totalsModel);
                this._gridDataSource.hasLatestTotals = true;
            } else {
                this._handleTotalsUpdate(null);
                this._gridDataSource.hasLatestTotals = false;
            }
        }

        return Promise.resolve();
    }

    private async _refreshRows(rowData: Compliance.QueryResultWithTotalsModel<Compliance.AssetModel, Compliance.AssetSearchTotalsModel>): Promise<void> {
        this.gridTracker.clear(false);

        this._handleTotalsUpdate(null);
        this._gridDataSource.hasLatestTotals = false;

        this.isBulkUpdateVisible$.next(false);
        this.isBulkDeleteVisible$.next(false);

        this._gridDataSource.clearTotals();

        const rowNodes: RowNode[] = [];
        this._gridApi.forEachNode(x => {
            const gridRow = x.data as AssetSummary;
            if (gridRow.reportedAssetId) {
                const row = rowData.data.find(y => y.reportingAsset.reportingAssetId === gridRow.reportedAssetId);
                if (row) {
                    gridRow.updateModel(row);
                    rowNodes.push(x);
                }
            }
        });

        this._gridApi.redrawRows({ rowNodes: rowNodes });

        this._handleTotalsUpdate(rowData.totals);
        this._gridDataSource.hasLatestTotals = true;

        this._gridDataSource.lastModifiedTimestamp = rowData.lastModifiedTimestamp;
    }

    private async _refreshDataSource(): Promise<void> {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);
        this.isBulkDeleteVisible$.next(false);

        this._gridDataSource.clearTotals();
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);
        this.isBulkDeleteVisible$.next(false);

        const dataSourceParams = (): AssetListDataSourceParams => {
            return {
                companyId: this.companyId,
                lienDate: this.lienDateVisible ? this.lienDate : { date: null, useStateLienDate: false, lienDateType: Compliance.AssetLienDateTypeEnum.Prior } as AssetLienDate,
                importId: this.importFileListVisible ? this.selectedImportFileId : null,
                limitOption: this.furtherLimitVisible ? this.limitOption : { invalidCostAllocations: false, noReportingParcel: false, overrideFilterColumns: [] } as AssetLimitOption,
                advancedFilters: this.advancedFilters && !this.isEmbeddedMode ? this.advancedFilters : null,
                fetchSourceAssets: this.selectedGridSourceOption === this._GRID_SOURCE_OPTION_IMPORTED_VALUES, // fetch source vs reporting assets
                parcelId: this.parcelIdFilter,
                siteId: this.siteIdFilter
            };
        };

        this._gridDataSource = new AssetListAgGridDataSource(
            this._gridApi,
            this._assetRepository,
            dataSourceParams,
            this._keyValueDisplayPipe,
            this._handleTotalsUpdate,
            () => this._autoUpdateTotals
        );

        this._gridApi.setDatasource(this._gridDataSource);

        return true;
    }

    private _setGridColumns(): void {
        this._gridApi.setColumnDefs([]);
        const columns = this._getGridColumnsBySource(this.selectedGridSourceOption.value);
        this._gridApi.setColumnDefs(columns);
        this._gridApi.setFilterModel(null);

        const defaultSortModel = [
            {
                colId: this.selectedGridSourceOption.value === GridSourceOptionEnum.Imported
                    ? `${Compliance.AssetPropertyEnum.SourceAssetNumber}`
                    : `${Compliance.AssetPropertyEnum.ReportingAssetNumber}`,
                sort: 'asc'
            }
        ];
        this._gridApi.setSortModel(defaultSortModel);
    }

    private _getGridColumnsBySource(gridSourceOption: GridSourceOptionEnum): ColDef[] {
        const columns: ColDef[] = [];

        const effectiveDateColDef: ColDef = {
            colId: `${Compliance.AssetPropertyEnum.EffectiveDate}`,
            headerName: 'Effective Date',
            toolPanelClass: 'Asset',
            field: 'effectiveDate',
            width: AgGridColumns.dateColumnWidth,
            filter: 'agDateColumnFilter',
            filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
            valueFormatter: (params) => params.value ? this._datePipe.transform(params.value, true) : ''
        };

        const actionsColDef: ColDef = {
            colId: 'grid-column-actions',
            headerName: '',
            field: 'actions',
            width: AgGridColumns.getActionColumnWidth(2),
            minWidth: AgGridColumns.getActionColumnWidth(2),
            maxWidth: AgGridColumns.getActionColumnWidth(2),
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            lockPinned: true,
            pinned: 'right',
            lockVisible: true,
            sortable: false,
            cellRendererFramework: AssetListGridActionCellRendererComponent,
            cellRendererParams: {
                viewDetails: this._viewAssetDetails.bind(this),
                canViewRyanPrivateItems: this.canViewRyanPrivateItems
            } as ICellRendererParamsForAssetListGridAction,
            pinnedRowCellRenderer: () => {return '';}
        };

        // get asset columns for specified source
        switch (gridSourceOption) {
            case GridSourceOptionEnum.Imported:
                columns.push(
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceAssetNumber}`,
                        headerName: 'S. Asset Number',
                        toolPanelClass: 'Source Values',
                        field: 'sourceAssetNumber',
                        width: AgGridColumns.textColumnWidth,
                        pinned: 'left',
                        lockPinned: true,
                        lockVisible: true,
                        lockPosition: true,
                        suppressMovable: true,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellRendererFramework: AgGridTextWithTotalsUpdateCellRendererComponent,
                        cellRendererParams: {
                            getTooltipText: (params: AgGridTextWithTotalsUpdateCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }

                                return `${asset.sourceAssetNumber}`;
                            },
                            totalsNeedRefresh: () => !this._gridDataSource.hasLatestTotals,
                            onUpdateTotalsClicked: () => {
                                this._calculateTotalsClicked = true;
                                this._gridDataSource.getAllRowTotals(this._gridApi.getCacheBlockState(), true);
                            }
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.CompanyCode}`,
                        headerName: 'Company Code',
                        toolPanelClass: 'Asset',
                        field: 'companyCode',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.CompanyName}`,
                        headerName: 'Company Name',
                        toolPanelClass: 'Asset',
                        field: 'companyName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Asset Ownership Type',
                        toolPanelClass: 'Asset',
                        field: 'assetOwnershipType',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceDescription}`,
                        headerName: 'S. Description',
                        toolPanelClass: 'Source Values',
                        field: 'sourceDescription',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteNameOrNumber}`,
                        headerName: 'S. Site',
                        toolPanelClass: 'Source Values',
                        field: 'sourceSiteDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!(asset && asset.sourceSite)) {
                                    return '';
                                }
                                return `#/site/${asset.sourceSite.siteID}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteName}`,
                        headerName: 'S. Site Name',
                        toolPanelClass: 'Source Values',
                        field: 'sourceSiteName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!(asset && asset.sourceSite)) {
                                    return '';
                                }
                                return `#/site/${asset.sourceSite.siteID}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteNumber}`,
                        headerName: 'S. Site Number',
                        toolPanelClass: 'Source Values',
                        field: 'sourceSiteNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!(asset && asset.sourceSite)) {
                                    return '';
                                }
                                return `#/site/${asset.sourceSite.siteID}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteAddress}`,
                        headerName: 'S. Site Address',
                        toolPanelClass: 'Source Values',
                        field: 'sourceSiteAddress',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceState}`,
                        headerName: 'S. State',
                        toolPanelClass: 'Source Values',
                        field: 'sourceState',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceGLAccountNameOrNumber}`,
                        headerName: 'S. G/L Account',
                        toolPanelClass: 'Source Values',
                        field: 'sourceGlAccountDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceGLAccountNumber}`,
                        headerName: 'S. G/L Account Number',
                        toolPanelClass: 'Source Values',
                        field: 'sourceGlAccountNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourcePriorCost}`,
                        headerName: 'S. Prior Cost',
                        toolPanelClass: 'Source Values',
                        field: 'sourcePriorCost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceCost}`,
                        headerName: 'S. Cost',
                        toolPanelClass: 'Source Values',
                        field: 'sourceCost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell'
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceCostChange}`,
                        headerName: 'S. Cost Change',
                        toolPanelClass: 'Source Values',
                        field: 'sourceCostChange',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceNetBookValue}`,
                        headerName: 'S. Net Book Value',
                        toolPanelClass: 'Source Values',
                        field: 'sourceNetBookValue',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceAdjustedCost}`,
                        headerName: 'S. Adj. Cost',
                        toolPanelClass: 'Source Values',
                        field: 'sourceAdjustedCost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell'
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceAcqDate}`,
                        headerName: 'S. Acquisition Date',
                        toolPanelClass: 'Source Values',
                        field: 'sourceAcqDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true)
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceDisposedDate}`,
                        headerName: 'S. Disposed Date',
                        toolPanelClass: 'Source Values',
                        field: 'sourceDisposedDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true)
                    } as ColDef,
                    {
                        headerName: 'S. Lease Location Name',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseLocationName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Location Address',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseAddress',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease City',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseCity',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease State',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseState',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Zip',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseZip',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Term (Months)',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseTermInMonths',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell'
                    } as ColDef,
                    {
                        headerName: 'S. Lease Monthly Payment',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseMonthlyPayment',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell'
                    } as ColDef,
                    {
                        headerName: 'S. Lease Type',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseTypeId',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Asset',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseAssetId',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Number',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing Name',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing Address',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingAddress',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing City',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingCity',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing State',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingState',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing Zip',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingZip',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Lease Start Date',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseStartDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true)
                    } as ColDef,
                    {
                        headerName: 'S. Lease End Date',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseEndDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true)
                    } as ColDef,
                    {
                        headerName: 'S. Lease Customer ID',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseClientId',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'S. Jan Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryJan',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Feb Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryFeb',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Mar Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryMar',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Apr Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryApr',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. May Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryMay',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Jun Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryJun',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Jul Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryJul',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Aug Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryAug',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Sep Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventorySep',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Oct Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryOct',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Nov Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryNov',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Dec Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryDec',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Avg Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryAvr',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Aggregated Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryAggregatedString',
                        width: AgGridColumns.textColumnLargeWidth,
                        sortable: false,
                        cellClass: 'ag-text-cell',
                        hide: true
                    } as ColDef,
                    effectiveDateColDef,
                    actionsColDef
                );
                break;
            case GridSourceOptionEnum.Reporting:
                columns.push(
                    {
                        colId: 'grid-column-multiselect',
                        headerName: '',
                        field: 'reportedAssetId',
                        width: AgGridColumns.selectionColumnWidth,
                        suppressSizeToFit: true,
                        resizable: false,
                        suppressColumnsToolPanel: true,
                        pinned: 'left',
                        lockPinned: true,
                        lockPosition: true,
                        editable: false,
                        headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                        headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                        cellRendererFramework: AgGridMultiSelectedCellRenderer,
                        cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                        pinnedRowCellRenderer: () => {return '';}
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingAssetNumber}`,
                        headerName: 'Asset Number',
                        toolPanelClass: 'Asset',
                        field: 'reportedAssetNumber',
                        width: AgGridColumns.textColumnWidth,
                        lockVisible: true,
                        lockPosition: true,
                        pinned: 'left',
                        lockPinned: true,
                        suppressMovable: true,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithCommaParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellRendererFramework: AgGridTextWithTotalsUpdateCellRendererComponent,
                        cellRendererParams: {
                            getTooltipText: (params: AgGridTextWithTotalsUpdateCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }

                                return `${asset.reportedAssetNumber}${asset.reportingIsPerpetual ? ' (Perpetual)' : ''}`;
                            },
                            totalsNeedRefresh: () => !this._gridDataSource?.hasLatestTotals,
                            onUpdateTotalsClicked: () => {
                                this._calculateTotalsClicked = true;
                                this._gridDataSource?.getAllRowTotals(this._gridApi.getCacheBlockState(), true);
                            }
                        },
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedAssetNumberHasOverride ? 'ws-override' : '';
                        },
                        valueFormatter: (params) => {
                            const asset = params.data as AssetSummary;
                            if (!asset) {
                                return '';
                            }

                            return `${asset.reportedAssetNumber}${asset.reportingIsPerpetual ? ' (P)' : ''}`;
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceAssetNumber}`,
                        headerName: 'S. Asset Number',
                        toolPanelClass: 'Source Values',
                        field: 'sourceAssetNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingOldAssetNumber}`,
                        headerName: 'Old Asset Number',
                        toolPanelClass: 'Asset',
                        field: 'reportedOldAssetNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedOldAssetNumberHasOverride ? 'ws-override' : '';
                        },
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceOldAssetNumber}`,
                        headerName: 'S. Old Asset Number',
                        toolPanelClass: 'Source Values',
                        field: 'sourceOldAssetNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceDescription}`,
                        headerName: 'S. Description',
                        toolPanelClass: 'Source Values',
                        field: 'sourceDescription',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingDescription}`,
                        headerName: 'Description',
                        toolPanelClass: 'Asset',
                        field: 'reportedDescription',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedDescriptionHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'Change Status',
                        toolPanelClass: 'Asset',
                        field: 'changeStatus',
                        cellRendererFramework: AssetChangeIconCellRendererComponent,
                        width: 120,
                        suppressSizeToFit: true,
                        suppressAutoSize: true,
                        sortable: false,
                        cellRendererParams: {}
                    },
                    {
                        colId: `${Compliance.AssetPropertyEnum.CompanyCode}`,
                        headerName: 'Company Code',
                        toolPanelClass: 'Asset',
                        field: 'companyCode',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset
                                    && asset.getModel().sourceAsset
                                    && asset.getModel().sourceAsset.sourceCompanyCode !== asset.getModel().companyCode ? 'ws-calculated' : '';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.CompanyName}`,
                        headerName: 'Company Name',
                        toolPanelClass: 'Asset',
                        field: 'companyName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset
                                    && asset.getModel().sourceAsset
                                    && asset.getModel().sourceAsset.sourceCompanyName !== asset.getModel().companyName ? 'ws-calculated' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'Asset Ownership Type',
                        toolPanelClass: 'Asset',
                        field: 'assetOwnershipType',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Asset Verification Reason',
                        toolPanelClass: 'Asset',
                        field: 'assetVerificationReason',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceState}`,
                        headerName: 'S. State',
                        toolPanelClass: 'Source Values',
                        field: 'sourceState',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.State}`,
                        headerName: 'State',
                        toolPanelClass: 'Asset',
                        field: 'state',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.AssessorName}`,
                        headerName: 'Assessor',
                        toolPanelClass: 'Asset',
                        field: 'assessorName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.AssessorId}`,
                        headerName: 'Assessor ID',
                        toolPanelClass: 'Asset',
                        field: 'assessorId',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }
                                return `#/assessor/${asset.assessorId}`;
                            }
                        } as AgGridLinkCellRendererParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteNameOrNumber}`,
                        headerName: 'S. Site',
                        toolPanelClass: 'Source Values',
                        field: 'sourceSiteDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!(asset && asset.sourceSite)) {
                                    return '';
                                }
                                return `#/site/${asset.sourceSite.siteID}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingSiteNameOrNumber}`,
                        headerName: 'Site',
                        toolPanelClass: 'Asset',
                        field: 'reportedSiteDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedSiteHasOverride ? 'ws-override' : '';
                        },
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }
                                return `#/site/${asset.reportedSiteId}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteName}`,
                        headerName: 'S. Site Name',
                        toolPanelClass: 'Source Values',
                        field: 'sourceSiteName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!(asset && asset.sourceSite)) {
                                    return '';
                                }
                                return `#/site/${asset.sourceSite.siteID}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingSiteName}`,
                        headerName: 'Site Name',
                        toolPanelClass: 'Asset',
                        field: 'reportedSiteName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedSiteHasOverride ? 'ws-override' : '';
                        },
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }
                                return `#/site/${asset.reportedSiteId}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteNumber}`,
                        headerName: 'S. Site Number',
                        toolPanelClass: 'Source Values',
                        field: 'sourceSiteNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!(asset && asset.sourceSite)) {
                                    return '';
                                }
                                return `#/site/${asset.sourceSite.siteID}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingSiteNumber}`,
                        headerName: 'Site Number',
                        toolPanelClass: 'Asset',
                        field: 'reportedSiteNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedSiteHasOverride ? 'ws-override' : '';
                        },
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }
                                return `#/site/${asset.reportedSiteId}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SiteAddress}`,
                        headerName: 'Site Address',
                        toolPanelClass: 'Asset',
                        field: 'siteAddress',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedSiteAddressHasOverride  ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceSiteAddress}`,
                        headerName: 'S. Site Address',
                        toolPanelClass: 'Asset',
                        field: 'sourceSiteAddress',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingIsAnchored}`,
                        headerName: 'Is Anchored',
                        toolPanelClass: 'Asset',
                        field: 'reportedIsAnchored',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agYesNoColumnFilter',
                        filterParams: AgGridFilterParams.yesNoFilterParams,
                        floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        valueFormatter: (params) => {
                            if (params.value === true) {
                                return 'Yes';
                            }
                            else if (params.value === false) {
                                return 'No';
                            } else {
                                return '';
                            }
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingParcel}`,
                        headerName: 'Parcel',
                        toolPanelClass: 'Asset',
                        field: 'parcelDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedParcelHasOverride ? 'ws-override' : '';
                        },
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }
                                return `#/parcel/${asset.reportedParcelId}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ParcelSystemId}`,
                        headerName: 'Parcel System ID',
                        toolPanelClass: 'Asset',
                        field: 'reportedParcelId',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        cellRendererFramework: AgGridLinkCellRendererComponent,
                        cellRendererParams: {
                            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                            newWindow: true,
                            getLink: (params: AgGridLinkCellRendererParams) => {
                                const asset = params.data as AssetSummary;
                                if (!asset) {
                                    return '';
                                }
                                return `#/parcel/${asset.reportedParcelId}`;
                            }
                        } as AgGridLinkCellRendererParams
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingIsLocked}`,
                        headerName: 'Is Locked',
                        toolPanelClass: 'Asset',
                        field: 'reportedIsLocked',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agYesNoColumnFilter',
                        filterParams: AgGridFilterParams.yesNoFilterParams,
                        floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        valueFormatter: (params) => {
                            if (params.value === true) {
                                return 'Yes';
                            }
                            else if (params.value === false) {
                                return 'No';
                            } else {
                                return '';
                            }
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceGLAccountNameOrNumber}`,
                        headerName: 'S. G/L Account',
                        toolPanelClass: 'Source Values',
                        field: 'sourceGlAccountDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingGLAccountNameOrNumber}`,
                        headerName: 'G/L Account',
                        toolPanelClass: 'Asset',
                        field: 'reportedGlAccountDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedGlAccountHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceGLAccountNumber}`,
                        headerName: 'S. G/L Account Number',
                        toolPanelClass: 'Source Values',
                        field: 'sourceGlAccountNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingGLAccountNumber}`,
                        headerName: 'G/L Account Number',
                        toolPanelClass: 'Asset',
                        field: 'reportedGlAccountNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedGlAccountHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.AssetClassificationName}`,
                        headerName: 'Classification',
                        toolPanelClass: 'Asset',
                        field: 'reportedAssetClassificationDisplay',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.assetClassificationHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceCost}`,
                        headerName: 'S. Cost',
                        toolPanelClass: 'Source Values',
                        field: 'sourceCost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceAdjustedCost}`,
                        headerName: 'S. Adj. Cost',
                        toolPanelClass: 'Source Values',
                        field: 'sourceAdjustedCost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourcePriorCost}`,
                        headerName: 'S. Prior Cost',
                        toolPanelClass: 'Source Values',
                        field: 'sourcePriorCost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.PriorCost}`,
                        headerName: 'Prior Cost',
                        toolPanelClass: 'Asset',
                        field: 'priorCost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            if (asset && asset.priorCostHasOverride) {
                                return 'ag-numeric-cell ws-override';
                            } else {
                                return 'ag-numeric-cell';
                            }
                        },
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingCost}`,
                        headerName: 'Cost (reportable)',
                        toolPanelClass: 'Asset',
                        field: 'cost',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            if (asset && asset.alternativeCostDescriptorId) {
                                return 'ag-numeric-cell ws-calculated';
                            } else if (asset && asset.hasCostAdjustment && asset.hasCostAdjustment() && !asset.reportedCostHasOverride) {
                                return 'ag-numeric-cell ws-calculated';
                            } else if (asset && asset.reportedCostHasOverride) {
                                return 'ag-numeric-cell ws-override';
                            } else {
                                return 'ag-numeric-cell';
                            }
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceCostChange}`,
                        headerName: 'S. Cost Change',
                        toolPanelClass: 'Source Values',
                        field: 'sourceCostChange',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.PriorCost}`,
                        headerName: 'Cost Change',
                        toolPanelClass: 'Asset',
                        field: 'costChange',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            if (asset && asset.costChangeHasOverride) {
                                return 'ag-numeric-cell ws-override';
                            } else {
                                return 'ag-numeric-cell';
                            }
                        },
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.AlternativeCostDescriptorName}`,
                        headerName: 'Alternate Cost Source',
                        toolPanelClass: 'Asset',
                        field: 'alternativeCostDescriptorName',
                        width: AgGridColumns.textColumnMedWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingAreSplitAllocationsValid}`,
                        headerName: 'Cost Alloc. Valid',
                        toolPanelClass: 'Asset',
                        field: 'areSplitAllocationsValid',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.booleanFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                        valueFormatter: (params) => {
                            if (params.value === true) {
                                return 'Yes';
                            }
                            else if (params.value === false) {
                                return 'No';
                            } else {
                                return '';
                            }
                        },
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.areSplitAllocationsValid === false ? 'cell-validation-error' : '';
                        }
                    },
                    {
                        headerName: 'S. Net Book Value',
                        toolPanelClass: 'Source Values',
                        field: 'sourceNetBookValue',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => params.value || params.value === 0 ? this._decimalPipe.transform(params.value, '1.2-2') : null,
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Net Book Value',
                        toolPanelClass: 'Asset',
                        field: 'reportedNetBookValue',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => params.value || params.value === 0 ? this._decimalPipe.transform(params.value, '1.2-2') : null,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedNetBookValueHasOverride ? 'ag-numeric-cell ws-override' : 'ag-numeric-cell';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingSplitAllocationPercentage}`,
                        headerName: 'Split Allocation %',
                        toolPanelClass: 'Asset',
                        field: 'splitAllocationPercentage',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceAcqDate}`,
                        headerName: 'S. Acquisition Date',
                        toolPanelClass: 'Source Values',
                        field: 'sourceAcqDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true),
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingAcqDate}`,
                        headerName: 'Acquisition Date',
                        toolPanelClass: 'Asset',
                        field: 'acqDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedAcqDateHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.AlternativeAcqDateDescriptorName}`,
                        headerName: 'Alternate Acquisition Date Source',
                        toolPanelClass: 'Asset',
                        field: 'alternativeAcqDateDescriptorName',
                        width: AgGridColumns.textColumnMedWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.SourceDisposedDate}`,
                        headerName: 'S. Disposed Date',
                        toolPanelClass: 'Source Values',
                        field: 'sourceDisposedDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true),
                        hide: true
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingDisposedDate}`,
                        headerName: 'Disposed Date',
                        toolPanelClass: 'Asset',
                        field: 'reportedDisposedDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => params.value ? this._datePipe.transform(params.value, true) : '',
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedDisposedDateHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        colId: `${Compliance.AssetPropertyEnum.ReportingDoNotPotentiallyDispose}`,
                        headerName: 'Do Not Potentially Dispose',
                        toolPanelClass: 'Asset',
                        field: 'reportedDoNotPotentiallyDispose',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agYesNoColumnFilter',
                        filterParams: AgGridFilterParams.yesNoFilterParams,
                        floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        valueFormatter: (params) => {
                            if (params.value === true) {
                                return 'Yes';
                            }
                            else if (params.value === false) {
                                return 'No';
                            } else {
                                return '';
                            }
                        }
                    } as ColDef,
                    {
                        headerName: 'Perpetual',
                        toolPanelClass: 'Asset',
                        field: 'reportingIsPerpetual',
                        width: AgGridColumns.textColumnMedWidth,
                        hide: true,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.booleanFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                        valueFormatter: (params) => {
                            const asset = params.data as AssetSummary;
                            if (!asset) {
                                return '';
                            }

                            return asset.reportingIsPerpetual ? 'Yes' : 'No';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Location Name',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseLocationName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Location Name',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseLocationName',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseLocationNameHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Location Address',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseAddress',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Location Address',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseAddress',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseAddressHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease City',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseCity',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease City',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseCity',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseCityHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease State',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseState',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease State',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseState',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseStateHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Zip',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseZip',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Zip',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseZip',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseZipHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Term (Months)',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseTermInMonths',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell'
                    } as ColDef,
                    {
                        headerName: 'Lease Term (Months)',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseTermInMonths',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseTermInMonthsHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Monthly Payment',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseMonthlyPayment',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell'
                    } as ColDef,
                    {
                        headerName: 'Lease Monthly Payment',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseMonthlyPayment',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseMonthlyPaymentHasOverride ? 'ag-numeric-cell ws-override' : 'ag-numeric-cell';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Type',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseTypeId',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Type',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseTypeId',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseTypeIdHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Asset',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseAssetId',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Asset',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseAssetId',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseAssetIdHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Number',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseNumber',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Number',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseNumber',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseNumberHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing Name',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingName',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Billing Name',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseBillingName',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseBillingNameHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing Address',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingAddress',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Billing Address',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseBillingAddress',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseBillingAddressHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing City',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingCity',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Billing City',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseBillingCity',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseBillingCityHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing State',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingState',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Billing State',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseBillingState',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseBillingStateHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Billing Zip',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseBillingZip',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Billing Zip',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseBillingZip',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseBillingZipHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Start Date',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseStartDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true)
                    } as ColDef,
                    {
                        headerName: 'Lease Start Date',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseStartDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => params.value ? this._datePipe.transform(params.value, true) : '',
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseStartDateHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease End Date',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseEndDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => this._datePipe.transform(params.value, true)
                    } as ColDef,
                    {
                        headerName: 'Lease End Date',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseEndDate',
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: (params) => params.value ? this._datePipe.transform(params.value, true) : '',
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseEndDateHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Lease Customer ID',
                        toolPanelClass: 'Source Leasing',
                        field: 'sourceLeaseClientId',
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    } as ColDef,
                    {
                        headerName: 'Lease Customer ID',
                        toolPanelClass: 'Leasing',
                        field: 'reportedLeaseClientId',
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                        width: AgGridColumns.textColumnWidth,
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedLeaseClientIdHasOverride ? 'ws-override' : '';
                        }
                    } as ColDef,
                    {
                        headerName: 'User Created',
                        toolPanelClass: 'Asset',
                        field: 'isUserCreated',
                        width: AgGridColumns.textColumnMedWidth,
                        hide: true,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.booleanFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                        valueFormatter: (params) => {
                            const asset = params.data as AssetSummary;
                            if (!asset) {
                                return '';
                            }

                            return asset.isUserCreated ? 'Yes' : 'No';
                        }
                    } as ColDef,
                    {
                        headerName: 'S. Jan Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryJan',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Jan Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryJan',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryJanHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Feb Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryFeb',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Feb Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryFeb',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryFebHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Mar Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryMar',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Mar Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryMar',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryMarHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Apr Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryApr',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Apr Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryApr',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryAprHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. May Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryMay',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'May Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryMay',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryMayHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Jun Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryJun',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Jun Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryJun',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryJunHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Jul Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryJul',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Jul Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryJul',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryJulHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Aug Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryAug',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Aug Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryAug',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryAugHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Sep Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventorySep',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Sep Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventorySep',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventorySepHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Oct Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryOct',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Oct Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryOct',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryOctHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Nov Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryNov',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Nov Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryNov',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryNovHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Dec Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryDec',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Dec Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryDec',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            return asset && asset.reportedInventoryDecHasOverride
                                ? 'ag-numeric-cell ws-override'
                                : 'ag-numeric-cell';
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Avg Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryAvr',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: 'ag-numeric-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Avg Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryAvrCalculated',
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                        cellClass: (params: ICellRendererParams): string => {
                            const asset = params.data as AssetSummary;
                            let cellClass: string = 'ag-numeric-cell';

                            if (asset) {
                                if (asset.reportedInventoryAvrIsCalculated){
                                    cellClass += ' ws-calculated';
                                } else if (asset.reportedInventoryAvrHasOverride){
                                    cellClass += ' ws-override';
                                }
                            }

                            return cellClass;
                        },
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'S. Aggregated Balance',
                        toolPanelClass: 'Source Inventory',
                        field: 'sourceInventoryAggregatedString',
                        width: AgGridColumns.textColumnLargeWidth,
                        sortable: false,
                        cellClass: 'ag-text-cell',
                        hide: true
                    } as ColDef,
                    {
                        headerName: 'Aggregated Balance',
                        toolPanelClass: 'Inventory',
                        field: 'reportedInventoryAggregatedString',
                        width: AgGridColumns.textColumnLargeWidth,
                        sortable: false,
                        cellClass: 'ag-text-cell',
                        hide: true
                    } as ColDef,
                    effectiveDateColDef,
                    actionsColDef
                );
                break;
            default:
                throw new Error('An unknown source was specified for retrieving asset values.');
        }

        // get descriptor columns (user defined) and config by source
        this.assetDescriptors.forEach(x => {
            let columnWidth: number;
            let filterName: string;
            let filterParams: any;
            let floatingFilterComponentParams: any;
            let valueFormatter: any;
            let floatingFilterComponentFramework: any;

            switch (x.descriptor.fieldType) {
                case Core.DescriptorFieldTypes.Date:
                    columnWidth = AgGridColumns.dateColumnWidth;
                    filterName = 'agDateColumnFilter';
                    filterParams = AgGridFilterParams.dateFilterParamsWithBlankOptionsParams;
                    floatingFilterComponentParams = AgGridFilterParams.dateFloatingFilterParams;
                    valueFormatter = (y) => {
                        if (!y.value) {
                            return '';
                        }

                        const d = new Date(y.value);
                        return this._datePipe.transform(d, true);
                    };
                    break;
                case Core.DescriptorFieldTypes.Currency:
                case Core.DescriptorFieldTypes.Number:
                    columnWidth = AgGridColumns.numericColumnWidth;
                    filterName = 'agNumberColumnFilter';
                    filterParams = AgGridFilterParams.numberFilterWithBlankOptionsParams;
                    floatingFilterComponentParams = AgGridFilterParams.numberFloatingFilterParams;
                    valueFormatter = (params) => {
                        if (x.descriptor.format) {
                            switch (x.descriptor.format) {
                                case '(0000)':
                                    return params.value;
                                case '(#,###)':
                                case '(#,###,####)':
                                    return this._decimalPipe.transform(params.value, '1.0-0');
                                case '(#,###,####.00)':
                                    return this._decimalPipe.transform(params.value, '1.2-2');
                                case '(#,###,####.000)':
                                    return this._decimalPipe.transform(params.value, '1.3-3');
                                default:
                                    // fallback to old code if format property does not exist
                                    return this._decimalPipe.transform(params.value, '1.2-2');
                            }
                        } else {
                            // fallback to old code if format property does not exist
                            return this._decimalPipe.transform(params.value, '1.2-2');}
                        };
                    break;
                case Core.DescriptorFieldTypes.Text:
                case Core.DescriptorFieldTypes.Picklist:
                    columnWidth = AgGridColumns.textColumnMedWidth;
                    filterName = 'agTextColumnFilter';
                    filterParams = AgGridFilterParams.textFilterWithBlankOptionsParams;
                    floatingFilterComponentParams = AgGridFilterParams.textFloatingFilterParams;
                    break;
                case Core.DescriptorFieldTypes.YesNo:
                    columnWidth = AgGridColumns.textColumnMedWidth;
                    filterName = 'agYesNoColumnFilter';
                    filterParams = AgGridFilterParams.yesNoFilterWithBlankOptionsParams;
                    floatingFilterComponentFramework = AgGridYesNoFloatingFilterComponent,
                    floatingFilterComponentParams = AgGridFilterParams.textFloatingFilterParams;
                    valueFormatter = (y) => {
                        let result = '';

                        if (y.value === 0){
                            result = 'No';
                        } else if (y.value === 1){
                            result = 'Yes';
                        }

                        return result;
                    };
                    break;
            }

            let colDef: ColDef;

            switch (gridSourceOption) {
                case GridSourceOptionEnum.Imported:
                    colDef = this._getColDef(`S. ${x.descriptor.name}`, x.descriptor.assetUsage !== Core.DescriptorUsageEnum.Frequent,
                        columnWidth, filterName, filterParams, floatingFilterComponentParams, floatingFilterComponentFramework, valueFormatter,
                        `source${x.columnName}`, `source${x.columnName}`, 'Source Values');
                    columns.push(colDef);
                    break;
                case GridSourceOptionEnum.Reporting:
                    colDef = this._getColDef(`S. ${x.descriptor.name}`, x.descriptor.assetUsage !== Core.DescriptorUsageEnum.Frequent,
                        columnWidth, filterName, filterParams, floatingFilterComponentParams, floatingFilterComponentFramework, valueFormatter,
                        `source${x.columnName}`, `source${x.columnName}`, 'Source Values');
                    columns.push(colDef);

                    colDef = this._getColDef(x.descriptor.name, x.descriptor.assetUsage !== Core.DescriptorUsageEnum.Frequent,
                        columnWidth, filterName, filterParams, floatingFilterComponentParams, floatingFilterComponentFramework, valueFormatter,
                        `reported${x.columnName}`, `reported${x.columnName}`, 'Asset');
                    colDef.cellClass = (params: ICellRendererParams): string => {
                        const asset = params.data as AssetSummary;
                        return asset && asset.slotColumnHasOverride && asset.slotColumnHasOverride(x.columnName) ? 'ws-override' : '';
                    };
                    columns.push(colDef);
                    break;
                default:
                    throw new Error('An unknown source was specified for retrieving asset values.');
            }
        });

        this._costAdjustmentTypes.forEach(x => {
            const colDef = {
                colId: `reportedCostAdjustmentAmount${x.costAdjustmentTypeId}`,
                headerName: `${x.typeName  } Cost Adj. Amt.`,
                toolPanelClass: 'Asset',
                field: `reportedCostAdjustmentAmount${x.costAdjustmentTypeId}`,
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                suppressSizeToFit: false,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value || 0, '1.2-2'),
                cellClass: 'ag-numeric-cell'
            } as ColDef;
            columns.push(colDef);
        });

        const lastReturnColDef = {
            colId: `${Compliance.AssetPropertyEnum.LastReturnDescription}`,
            headerName: 'Last Return',
            toolPanelClass: 'Asset',
            field: 'lastReturnDescription',
            width: AgGridColumns.textColumnWidth,
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            hide: true,
            cellRendererFramework: AgGridLinkCellRendererComponent,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => 'asset-list.view-last-return',
                newWindow: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const asset = params.data as AssetSummary;
                    if (!asset) {
                        return '';
                    }
                    return `#/returnBatch/${asset.lastReturnId}`;
                }
            } as AgGridLinkCellRendererParams
        };
        columns.push(lastReturnColDef);

        return columns;
    }

    private _getColDef(headerName: string, hide: boolean, columnWidth: number, filterName: string,
                       filterParams: any, floatingFilterComponentParams: any,
                       floatingFilterComponentFramework: any, valueFormatter: any,
                       colId: string, field: string, group: string) : ColDef {
        const col = {
            colId: colId,
            field: field,
            headerName: headerName,
            toolPanelClass: group,
            width: columnWidth,
            filter: filterName,
            filterParams: filterParams,
            floatingFilterComponentParams: floatingFilterComponentParams,
            valueFormatter: valueFormatter,
            suppressSizeToFit: false,
            hide: hide
        } as ColDef;

        if (floatingFilterComponentFramework) {
            col['floatingFilterComponentFramework'] = floatingFilterComponentFramework;
        }

        return col;
    }

    private _getGridIdBySource(gridSourceOption: GridSourceOptionEnum): System.Guid {
        switch (gridSourceOption) {
            case GridSourceOptionEnum.Imported:
                return this._IMPORTED_VALUES_GRID_ID;
            case GridSourceOptionEnum.Reporting:
                return this._REPORTING_VALUES_GRID_ID;
            default:
                throw new Error('An unknown source was specified for retrieving asset values.');
        }
    }

    private _handleTotalsUpdate = (totals: Compliance.AssetSearchTotalsModel, selectedTotals: boolean = false, isLoading: boolean = false) => {
        const model = {
            //cost: totals.totalCost,
            //sourceCost: totals.totalSourceCost,
        };
        const assetModelRow: any = {
            model: model,
            getModel: () => { return model; },
            displayPipe: this._keyValueDisplayPipe,
            index: 0,
            cost: totals && totals.totalCost,
            sourceCost: totals && totals.totalSourceCost,
            sourceAdjustedCost: totals && totals.totalSourceAdjustedCost,
            sourceN1: totals && totals.totalSourceN1,
            sourceN2: totals && totals.totalSourceN2,
            sourceN3: totals && totals.totalSourceN3,
            sourceN4: totals && totals.totalSourceN4,
            sourceN5: totals && totals.totalSourceN5,
            sourceN6: totals && totals.totalSourceN6,
            sourceN7: totals && totals.totalSourceN7,
            sourceN8: totals && totals.totalSourceN8,
            sourceN9: totals && totals.totalSourceN9,
            sourceN10: totals && totals.totalSourceN10,
            reportedN1: totals && totals.totalN1,
            reportedN2: totals && totals.totalN2,
            reportedN3: totals && totals.totalN3,
            reportedN4: totals && totals.totalN4,
            reportedN5: totals && totals.totalN5,
            reportedN6: totals && totals.totalN6,
            reportedN7: totals && totals.totalN7,
            reportedN8: totals && totals.totalN8,
            reportedN9: totals && totals.totalN9,
            reportedN10: totals && totals.totalN10,
            reportedAssetNumber: this.selectedGridSourceOption.value === GridSourceOptionEnum.Reporting ? 'TOTAL' : '',
            sourceAssetNumber: this.selectedGridSourceOption.value === GridSourceOptionEnum.Imported ? 'TOTAL' : '',
            reportedCostAdjustmentAmount1: totals && totals.totalCostAdjustmentAmount1,
            reportedCostAdjustmentAmount2: totals && totals.totalCostAdjustmentAmount2,
            reportedCostAdjustmentAmount3: totals && totals.totalCostAdjustmentAmount3,
            reportedCostAdjustmentAmount4: totals && totals.totalCostAdjustmentAmount4,
            reportedInventoryJan: totals && totals.totalInventoryJan,
            reportedInventoryFeb: totals && totals.totalInventoryFeb,
            reportedInventoryMar: totals && totals.totalInventoryMar,
            reportedInventoryApr: totals && totals.totalInventoryApr,
            reportedInventoryMay: totals && totals.totalInventoryMay,
            reportedInventoryJun: totals && totals.totalInventoryJun,
            reportedInventoryJul: totals && totals.totalInventoryJul,
            reportedInventoryAug: totals && totals.totalInventoryAug,
            reportedInventorySep: totals && totals.totalInventorySep,
            reportedInventoryOct: totals && totals.totalInventoryOct,
            reportedInventoryNov: totals && totals.totalInventoryNov,
            reportedInventoryDec: totals && totals.totalInventoryDec,
            reportedInventoryAvrCalculated: totals && totals.totalInventoryAvrCalculated,
            sourceInventoryJan: totals && totals.totalSourceInventoryJan,
            sourceInventoryFeb: totals && totals.totalSourceInventoryFeb,
            sourceInventoryMar: totals && totals.totalSourceInventoryMar,
            sourceInventoryApr: totals && totals.totalSourceInventoryApr,
            sourceInventoryMay: totals && totals.totalSourceInventoryMay,
            sourceInventoryJun: totals && totals.totalSourceInventoryJun,
            sourceInventoryJul: totals && totals.totalSourceInventoryJul,
            sourceInventoryAug: totals && totals.totalSourceInventoryAug,
            sourceInventorySep: totals && totals.totalSourceInventorySep,
            sourceInventoryOct: totals && totals.totalSourceInventoryOct,
            sourceInventoryNov: totals && totals.totalSourceInventoryNov,
            sourceInventoryDec: totals && totals.totalSourceInventoryDec,
            sourceInventoryAvr: totals && totals.totalSourceInventoryAvr,
            reportedNetBookValue: totals && totals.totalNetBookValue,
            sourceNetBookValue: totals && totals.totalSourceNetBookValue
        };

        if (selectedTotals) {
            assetModelRow.selectedTotalsRow = true;
            assetModelRow.reportedAssetNumber = this.selectedGridSourceOption.value === GridSourceOptionEnum.Reporting ? 'SELECTED' : '';
            assetModelRow.sourceAssetNumber = this.selectedGridSourceOption.value === GridSourceOptionEnum.Imported ? 'SELECTED' : '';
            this.assetGridSelectedTotalsRow = assetModelRow;
        } else {
            assetModelRow.totalsRow = true;
            this.assetGridTotalsRow = assetModelRow;
        }

        if (isLoading) {
            this.totalsLoading = this._gridDataSource.getLoadingMessage(msg => {
                assetModelRow.reportedAssetNumber = msg;
                assetModelRow.sourceAssetNumber = msg;
                this._gridApi.setPinnedBottomRowData([assetModelRow]);
            });
            return;
        } else if (!isLoading && this.totalsLoading >= 0) {
            clearInterval(this.totalsLoading);
            this.totalsLoading = null;
        }

        const totalRows = [this.assetGridTotalsRow];
        if (this.gridTracker.hasAnythingSelected()) {
            totalRows.push(this.assetGridSelectedTotalsRow);
        }
        this._timer.setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    };

    private async _reloadTotals(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        this.selectedTotalsLoading = true;

        try {
            const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
            this.selectedTotalsLoading = false;
            this._handleTotalsUpdate(result, true);
        }
        finally {
            this.selectedTotalsLoading = false;
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number, canDismiss = true, hasProgressBar = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            await this._hideBusyIndicator();
            if (success) {
                this.refresh();
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }
}
