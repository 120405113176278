<ws-modal-wrapper [hideSave]="true"
                  [headerLabel]="(!recipientMode) ? header : ''"
                  (cancel)="cancel()">
    <div [ngClass]="{'container': !dialogMode}" style="height: 100%">
        <div style="height: 100%">
            <div class="row">
                <div class="col-lg-6 offset-md-3">
                    <div class="input-group">
                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                        <input type="text"
                               class="form-control"
                               placeholder="Filter..."
                               [(ngModel)]="filter"
                               (keydown)="checkForEnterKey($event)"
                               (ngModelChange)="validateFilter()">
						<button class="ace-btn btn-secondary has-icon" type="button" (click)="clearFilter()"><i class="fa fa-close"></i></button>
                    </div>
                    <div class="alert alert-danger" *ngIf="filterValidationError !== ''">
                        {{filterValidationError}}
                    </div>
                </div>
                <div class="alert alert-danger" *ngIf="filterValidationError !== ''">
                    {{filterValidationError}}
                </div>
            </div>
            <loading-spinner [show]="gettingDataForTheFirstTime" [size]="5"></loading-spinner>
            <div *ngIf="!(gettingDataForTheFirstTime || contacts.length === 0)" style="height: 75%;">
                <div class="row mt-2" style="width: 95%;">
                    <button *ngIf="recipientMode"
                            class="ace-btn btn-primary w-auto"
                            (click)="closeRecipientDialog(recipientTypes.Cc)"
                            style="margin-left: 40px;">
                        Add CC
                    </button>
                    <button *ngIf="recipientMode"
                            class="ace-btn btn-primary w-auto"
                            (click)="closeRecipientDialog(recipientTypes.Bcc)">
                        Add BCC
                    </button>
                </div>
                <!--style="height: 100%;"-->
                <div *ngIf="contacts.length > 0" class="row contacts-infinite-scroll" style="margin: 30px; margin-bottom: 0; width: 95%; max-height: 369px; overflow-y:scroll;">
                    <table class="table table-striped table-hover" infinite-scroll="searchContactsOnServer(filter);" infinite-scroll-disabled='gettingDataForTheFirstTime || gettingMoreData' infinite-scroll-immediate-check="false" infinite-scroll-distance='8' infinite-scroll-container='".contacts-infinite-scroll"'>
                        <thead>
                        <tr>
                            <th *ngIf="dialogMode" style="width: 20px;"></th>
                            <th>Name</th>
                            <th>Title</th>
                            <th>Phone</th>
                            <th>Company</th>
                        </tr>
                        </thead>
                        <tbody>
                        <!--contactsAlphaFilter:currentAlphaValue |-->
                        <tr *ngFor="let contact of contacts; trackBy:contactsTrackBy">
                            <td *ngIf="dialogMode">
                                <input type="checkbox" [(ngModel)]="contact.selected" (ngModelChange)="selectContact(contact)" />
                            </td>
                            <td>
                                <a class="clickable" (click)="openContactDialog(contact.contactID, contacts)">
                                    {{ contact.lastName }}, {{ contact.firstName }}
                                </a>
                                <loading-spinner [show]="contact.selected  && gettingContactRoles" [size]="3" [marginTop]="23" [marginBottom]="23"></loading-spinner>
                                <div *ngIf="dialogMode && contact.selected && (!gettingContactRoles) && !recipientMode" style="margin: 30px; width: 95%; white-space: nowrap;">
                                    <label class="control-label">Role:</label>
                                    <ws-select [options]="contact.availableRoles  | sortBy: 'asc': 'roleDesc'"
                                               labelProperty="roleDesc"
                                               [returnEntireOption]="true"
                                               [(ngModel)]="contact.selectedRole"></ws-select>
                                    <button style="margin-left: 12px;" class="ace-btn btn-sm btn-primary" *ngIf="contact.selectedRole" (click)="associateRole(contact)">
                                        Associate
                                    </button>
                                    <button class="ace-btn btn-sm btn-primary btn-danger" (click)="cancelContactAssignRole(contact)">
                                        Cancel
                                    </button>
                                </div>
                            </td>
                            <td>
                                <span>{{ contact.title }}</span>
                            </td>
                            <td>
                                {{getAnyContactPhone(contact)}}
                            </td>
                            <td>
                                <a class="clickable" (click)="openCompanyPage(contact.entityID)">
                                    {{ contact.entityName }}
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <loading-spinner [show]="gettingMoreData" [size]="5"></loading-spinner>
                </div>
            </div>
            <div *ngIf="(!gettingDataForTheFirstTime) && contacts.length == 0" class="row" style="text-align: center">
                <h3 style="padding-top: 60px;">No Matching Contacts Found</h3>
            </div>
            <div class="checkbox float-end" *ngIf="!(dialogMode || recipientMode)" style="margin-right: 30px; margin-top: 30px;">
                <input type="checkbox" [(ngModel)]="excludeInactive" (ngModelChange)="searchContactsOnServer(filter, true)"> Exclude Inactive
            </div>
        </div>
    </div>
</ws-modal-wrapper>
