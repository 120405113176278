<div class="ws-busy-indicator-backdrop" [@animateBackdrop]="backdropAnimationState" [style.z-index]="zIndexOverride"></div>
<div class="ws-busy-indicator-container"
     [class.spinner-only]="!(title || message || hasAction)"
     [@animateContainer]="containerAnimationState"
     tabindex="0"
     style="padding-bottom: 24px;"
     [style.padding-bottom]="paddingBottom">
    <div class="progress-container" [class.active]="progressPercentage">
        <div class="import-progress" [class.failed]="progressFailure" [ngStyle]="{ 'width': progressPercentage + '%' }"></div>
    </div>
    <div class="ws-busy-indicator-container__row">
        <div class="ws-busy-indicator-container__spinner" [class.active]="!progressPercentage">
            <div class="la-ball-spin-clockwise" [ngClass]="{'la-dark': (title || message || hasAction) }">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="ws-busy-indicator-container__summary" *ngIf="title || message || signalRDisconnected || hasAction">
            <div class="ws-busy-indicator-container__summary__title" *ngIf="title">{{ title }}</div>
            <div class="ws-busy-indicator-container__summary__message" *ngIf="message || signalRDisconnected || hasAction">
                <span *ngIf="isLongRunningProcessDelayed && !signalRDisconnected"
                      id="lrpDelayed"
                      helpTooltip
                      [hasIcon]="true"
                      [tooltipText]="longRunningProcessDelayHelp"
                      position="bottom">
                    Taking a bit longer...
                </span>
                <span *ngIf="signalRDisconnected" id="lrpDisconnected">
                    This process is taking longer than normal. PropertyPoint<br>
                    will periodically check for updates, but if you would like to<br>
                    check manually please click
                    <span [ngClass]="{ 'anchor-style': !manualUpdateLoading }" (click)="refreshFromLRPServer()">
                        here<i *ngIf="manualUpdateLoading" class="fa fa-spinner fa-pulse lrp-check-spin"></i>
                    </span>
                </span>
                <span *ngIf="!isLongRunningProcessDelayed && !signalRDisconnected" id="summaryMessage">
                    {{ message }}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="canDismiss || hasAction" class="ws-busy-indicator-container__row ws-busy-indicator-container__footer" [class.showing-progress]="progressPercentage">
        <button class="ace-btn btn-link"
                id="dismissButton"
                *ngIf="canDismiss"
                (click)="dismiss()"
                helpTooltip
                tooltipText="Click on OK to run the process in background."
                position="bottom">
            <strong>OK</strong>
        </button>
        <button class="ace-btn btn-link ms-2"
                id="actionButton"
                *ngIf="hasAction"
                [disabled]="actionTriggered"
                (click)="action()"
                helpTooltip
                [tooltipText]="actionMessage"
                position="bottom">
            <strong>{{ actionMessage }}</strong>
        </button>
    </div>
</div>
