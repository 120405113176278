<div class="card panel-flat-color secondary-panel small-panel" style="margin-top: 24px;">
	<div class="panel-flat-header small-header" style="margin-bottom: 0;">
		<div class="header-icon"><i class="fa fa-pie-chart"></i></div>
		<h3>Fee Allocation</h3>
	</div>
	<div class="panel-flat-body">
		<div *ngIf="editMode" class="text-end">
			<button class="ace-btn btn-sm btn-primary" (click)="addNew()">Add Revenue Share Code</button>
		</div>
		<div style="height: 200px;">
            <ws-ag-grid-angular [gridOptions]="gridOptions"
                                (gridReady)="onAgGridReady($event)"
                                [gridId]="gridId">
            </ws-ag-grid-angular>
        </div>
	</div>
</div>
