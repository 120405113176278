import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { HelpService } from 'src/app/UI-Lib/Help-Tooltip';
import { DELIVERY_DETAILS_HELP } from './deliveryDetails.help';
import { PaymentBatchService } from 'src/app/Payment-Batch/paymentBatch.service';
import { endsWith, find, isEmpty, mapValues } from 'lodash';
import { efAction } from 'src/app/constants.new';
import { TeamService } from 'src/app/Team/team.service';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';

export type DeliveryDetailsPaymentBatchResult = [Weissman.Model.Domain.DeliveryDetailPaymentBatchDTOIn, Core.PaymentBatchDeliveryDetail];

@Component({
    selector: 'payment-batch-workflow',
    templateUrl: './deliveryDetailsPaymentBatchWorkflow.component.html',
    styleUrls: ['./deliveryDetailsPaymentBatchWorkflow.component.scss']
})
export class DeliveryDetailsPaymentBatchWorkflowComponent implements OnInit {
    constructor(
        private readonly _helpService: HelpService,
        private readonly _teamService: TeamService,
        private readonly _paymentBatchService: PaymentBatchService
    ) { }

    @Input() editMode: boolean;
    @Input() entity: CompanyEntity;
    @Input() userSearchInstanceId: number;
    @Input() currentPaymentBatchData: Core.PaymentBatchDeliveryDetail;
    @Input() dataHubEnabled: boolean;
    @Input() shouldDisplayDataHub: boolean;
    @Output() setEfAction: EventEmitter<efAction> = new EventEmitter<efAction>();
    @Output() setCurrentData: EventEmitter<Core.PaymentBatchDeliveryDetail> = new EventEmitter<Core.PaymentBatchDeliveryDetail>();
    @Output() setIsDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
    // params: DeliveryDetailsPaymentBatchParams;
    // result: DeliveryDetailsPaymentBatchResult = [undefined, undefined] as DeliveryDetailsPaymentBatchResult;

    taskSeriesList: Core.PaymentBatchConfigModel[];
    selectedWorkflow: Core.PaymentBatchConfigModel;
    currentData: Core.PaymentBatchDeliveryDetail = {} as Core.PaymentBatchDeliveryDetail;
    parentUsers: Core.UserTeamModel[];

    readonly prefixMap: { [taskTypeId: number]: string; } = {
        61: 'preparePreliminaryAPFeed',
        62: 'reviewPreliminaryAPFeed',
        64: 'requestFundingApproval',
        65: 'prepareCheckFeed',
        66: 'prepareFinalAPFeed',
        67: 'confirmFunding',
        68: 'reviewJurisProtocols',
        69: 'printChecks',
        70: 'mailChecks',
        71: 'archiveChecks',
        74: 'approvePayments'
    };

    loading: boolean = false;

    async ngOnInit() {
        this._helpService.setContent(DELIVERY_DETAILS_HELP);

        this.loading = true;

        try {
            this.taskSeriesList = await this._paymentBatchService.getTaskSeriesConfig();
            this.currentData = this.currentPaymentBatchData
                || await this._paymentBatchService.getDeliveryDetailPaymentBatch(this.entity.id)
                || {} as Core.PaymentBatchDeliveryDetail;

            this.setCurrentData.emit(this.currentData);

            if(!isEmpty(this.currentData)) {
                this.selectedWorkflow = find(this.taskSeriesList, { paymentBatchTaskSeriesOptionId: this.currentData.paymentBatchTaskSeriesOptionId });
                this.setEfAction.emit('update');
            } else {
                this.selectedWorkflow = this.taskSeriesList[0];
                this.workflowChanged();
                this.setEfAction.emit('add');
            }

            if (this.editMode) {
                this.parentUsers = await this._teamService.getAllAssignableUsers(false, false, this.entity.id, this.entity.type, this.userSearchInstanceId);
            }

        } finally {
            this.loading = false;
        }
    }

    userChanged(newUser: Core.UserTeamModel, userTypePrefix: string, isRequired: boolean): void {
        if (!newUser) {
            this._removeUser(userTypePrefix, isRequired);
        } else {
            this.currentData[`${userTypePrefix}UserId`] = newUser.userID;
            this.currentData[`${userTypePrefix}TeamId`] = newUser.teamID;

            this.currentData[`${userTypePrefix}Team`] = {
                name: newUser.teamName,
                teamID: newUser.teamID
            } as Weissman.Model.Workflow.Team;
            this.currentData[`${userTypePrefix}User`] = {
                efAction: null,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                userID: newUser.userID
            };
        }

        this.setCurrentData.emit(this.currentData);
        this.setIsDirty.emit(true);
    }

    getDisplayName(user: Core.ContactShortDTO, team: Weissman.Model.Workflow.Team): string {
        //consultant person assigned - show consulting company name if not consultant viewing
        // if (team.instanceID !== this.entityInstanceId && this.isConsultantPerspective === false) {
        //     //look up consultant's name
        //     const consultantName = this.ces.find(ce => ce.instanceId === team.instanceID)?.name;
        //     return consultantName ? `Consultant- ${consultantName}` : '';
        // }
        //client person assigned - show person's name
        // else {
            return `${user.lastName}, ${user.firstName} (${team.name})`;
        // }
    }

    workflowChanged() {
        this.currentData = mapValues(this.currentData, (val, key) => {
            if(endsWith(key, 'UserId') || endsWith(key, 'TeamId')) {
                return undefined;
            } else {
                return val;
            }
        }) as Core.PaymentBatchDeliveryDetail;

        this.currentData.paymentBatchTaskSeriesOptionId = this.selectedWorkflow.paymentBatchTaskSeriesOptionId;

        this.setCurrentData.emit(this.currentData);
        this.setIsDirty.emit(true);
    }

    private _removeUser(userTypePrefix: string, isRequired: boolean): void {
        this.currentData[`${userTypePrefix}User`] = {};
        this.currentData[`${userTypePrefix}Team`] = {};

        this.currentData[`${userTypePrefix}UserId`] = null;
        this.currentData[`${userTypePrefix}TeamId`] = null;
    }
}