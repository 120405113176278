import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BillClusterService, BillViewModel } from '../../../../../Annual-Details/Taxes/bill-cluster.service';
import { Bill } from '../../../../../Annual-Details/Taxes/bill.model';
import { IWeissmanModalComponent } from '../../../../../Compliance/WeissmanModalService';
import { IdpService } from '../idp.service';
import { IdpCard } from './Card/idpDetailCard.component';

import * as moment from 'moment';

import DetailsInstallmentMessageItem = Weissman.Model.Workflow.DocumentIntake.OcrResponse.Message.DetailsInstallmentMessageItem;
import { flatMap, minBy, some } from 'lodash';
import { Payment } from 'src/app/Annual-Details/Taxes/payment.model';
import { ProductAnalyticsService } from 'src/app/Common/Amplitude/productAnalytics.service';

export interface IdpBillDetailModalParams {
    confidence: string;
    idpResults: Core.TaxBillPushMessageDto;
    billDetail: BillViewModel;
    disableApplyAll: boolean
}

@Component({
    selector: 'idp-bill-detail-modal',
    templateUrl: './idpBillDetailModal.component.html',
    styleUrls: ['./idpBillDetailModal.component.scss']
})
export class IdpBillDetailModalComponent implements OnInit, AfterViewInit, IWeissmanModalComponent<IdpBillDetailModalParams, Bill[]> {
    constructor(private readonly _bsModalRef: BsModalRef,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _billClusterService: BillClusterService,
        private readonly _idpService: IdpService) {
    }

    params: IdpBillDetailModalParams;
    result: Bill[];

    billCard: IdpCard;
    payments: IdpCard[] = [];

    // todo update the value keys once we know the correct values
    private _cardValues = [
        { name: 'Base Pay Amt', field: 'grossPayment', type: 'currency' },
        { name: 'Discount Amt', field: 'discountAmount', type: 'currency' },
        { name: 'Interest', field: 'interestAmount', type: 'currency' },
        { name: 'Penalty', field: 'penaltyAmount', type: 'currency' },
        { name: 'Due Date', field: 'dueDate', type: 'date' }
    ];

    private _billDetail: BillViewModel;
    private _idpResults: Core.TaxBillPushMessageDto;

    get title(): string {
        return `Detected Values: ${this.params?.confidence} Confidence`;
    }

    ngOnInit(): void {
        this._billDetail = this.params.billDetail;
        this._idpResults = this.params.idpResults;

        const bill = this._billDetail.model[0];

        this.billCard = {
            title: 'Bill',
            total: { name: 'Bill Amt', value: this._idpResults.totalAmount },
            details: [
                // { name: 'Assoc. Revision', value: this._getAssocRevisionDesc(bill), type: 'string' },
                // { name: 'Payment Option', value: this._getBillPaymentOptionName(bill), type: 'string' }
                { name: 'Direct Asmt', value: this._idpResults.directAssessmentAmount || 0, type: 'currency'}
            ]
        };

        // If the PropertyPoint bill has one payment and there is no canonical installment for just one payment,
        // use the canonical bill's totalAmount due and the earliest payment's due date within the canonical bill.
        // This is basically paying the full amount due by the first payment due date.
        if(bill.payments.length === 1 && !some(this._idpResults.installments, x => x.details.length === 1)) {
            const idpPayments = flatMap(this._idpResults.installments, 'details') as DetailsInstallmentMessageItem[];
            const idpPayment = minBy(idpPayments, x => new Date(x.dueDate));

            if(!idpPayment) return;

            bill.payments[0].dueDate = idpPayment.dueDate;
            bill.payments[0].grossPayment = this._idpResults.totalAmount;
            bill.payments[0].paymentAmount = this._billClusterService.paymentPropChanged(bill.payments[0]);

            this.payments = [{
                title: 'Payment 1 of 1',
                total: { name: 'Payment Amt', value: bill.payments[0].paymentAmount },
                details: [{
                    name: 'Base Pay Amt', value: this._idpResults.totalAmount, type: 'currency'
                }, {
                    name: 'Due Date', value: idpPayment.dueDate, type: 'date'
                }]
            }];

            return;
        }


        const installment = this._idpResults.installments.find(x => x.details.length === bill.payments.length);
        let discountIndex = 0;
        if (bill.payments.length === 1) {
            // if bill payment has one payment, find installment with one detail
            // find the payment that matches the due date
            const payment = installment?.details.find(x => moment(x.dueDate).isSame(moment(bill.payments[0].dueDate)));
            discountIndex = payment?.discount.findIndex(x => x.dueDate === bill.payments[0].dueDate) || 0;
        }
        // if the bill payment option has multiple payments, find the installment with the same number of details
        // we need to show all the payments available, regardless of due date
        bill.payments.forEach((payment: Payment, pmtIdx: number) => {
            const ocrPayment = installment?.details[pmtIdx];

            if(!ocrPayment) return;

            payment.grossPayment = ocrPayment?.amount || 0;
            payment.discountAmount = -this._idpService.getDiscount(ocrPayment, discountIndex);
            payment.interestAmount = ocrPayment?.interest[discountIndex]?.interestAmount || 0;
            payment.penaltyAmount = ocrPayment?.penalty[discountIndex]?.penaltyAmount || 0;
            payment.dueDate = ocrPayment?.discount[discountIndex]?.dueDate || ocrPayment.dueDate;

            payment.paymentAmount = this._billClusterService.paymentPropChanged(payment);

            this.payments.push({
                title: `Payment ${pmtIdx + 1} of ${installment.details.length}`,
                total: { name: 'Payment Amt', value: payment.paymentAmount },
                details: this._cardValues.map(card => {
                    return { name: card.name, value: payment[card.field], type: card.type };
                })
            });
        });
    }

    ngAfterViewInit(): void {
        const modalContainer = document.querySelector('modal-container');
        if (modalContainer) {
            modalContainer.classList.add('no-modal-backdrop');
        }
    }

    applyAll(): void {
        this._productAnalyticsService.logEvent('apply-detected-values', { OcrApplyAll: 'Apply' });
        this._idpService.mapBillVMFromIDP(this._billDetail, this._idpResults);

        this.result = this._billDetail.model;

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._productAnalyticsService.logEvent('apply-detected-values', { OcrApplyAll: 'Cancel' });

        this._bsModalRef.hide();
    }

    private _getAssocRevisionDesc(bill: Bill): string {
        const yearRevision = this.params.billDetail.yearRevisions.find(x => x.annualAssessmentID === bill.annualAssessmentID);

        return yearRevision?.revisionDesc || '';
    }

    private _getBillPaymentOptionName(bill: Bill): string {
        if (!bill.collectorPaymentOptionID || !bill.collectorPaymentOptions) {
            return '';
        } else if (bill.collectorPaymentOptions.length === 0) {
            return bill.collectorPaymentOption.name || 'N/A';
        }

        const option = bill.collectorPaymentOptions.find(x => x.collectorPaymentOptionID === bill.collectorPaymentOptionID);

        return option?.name || 'N/A';
    }
}
