<ws-modal-wrapper [headerLabel]="modalTitle"
                  (save)="save()"
                  (cancel)="cancel()"
                  [useAceStyles]="true">
    <div class="ace-con ace-con-inline d-flex flex-column mb-4">
        <div>
            <label class="control-label">Category:</label>
            <div class="input-group">
                <ws-ace-select [options]="categories"
                               labelProperty="categoryName"
                               placeholder="Select a category"
                               [returnEntireOption]="true"
                               [disabled]="creatingNewCategory || !categories || categories.length === 0"
                               [(ngModel)]="selectedCategory">
                </ws-ace-select>
                <ws-ace-button class="ms-2"
                               icon="plus"
                               size="small"
                               state="action"
                               buttonStyle="secondary"
                               (click)="creatingNewCategory = true"
                               [isDisabled]="creatingNewCategory">
                </ws-ace-button>
            </div>
        </div>
        <div *ngIf="creatingNewCategory">
            <label class="col-md-2 control-label">New Category:</label>
            <div class="d-flex">
                <input type="text"
                       id="new-search-category"
                       class="form-control form-control-sm"
                       [(ngModel)]="newCategory">
                <ws-ace-button class="ms-2"
                               icon="check"
                               state="success"
                               buttonStyle="secondary"
                               size="small"
                               [isDisabled]="!newCategory"
                               (click)="createNewCategory()">
                </ws-ace-button>
                <ws-ace-button class="ms-2"
                               icon="times"
                               state="danger"
                               buttonStyle="secondary"
                               size="small"
                               (click)="cancelCreateCategory()">
                </ws-ace-button>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
