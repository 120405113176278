import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../../Compliance/WeissmanModalService';
import { IdpService } from '../idp.service';
import { ProductAnalyticsService } from 'src/app/Common/Amplitude/productAnalytics.service';
import { AssessmentViewModel } from 'src/app/Annual-Details/Assessments/assessment.service';
import { Assessment } from 'src/app/Annual-Details/Assessments/assessment.model';
import { assign, chain, flatten, forEach, keys, map } from 'lodash';

export interface IdpAssessmentDetailModalParams {
    confidence: string;
    idpResults: Weissman.Model.Workflow.DocumentIntake.OcrResponse.Message.AssessmentPushMessageDto;
    assessmentDetail: AssessmentViewModel;
    disableApplyAll: boolean
}

type ComponentRow = {
    name: string;
    av: number;
    ratio: number
    fmv: number;
    type: string;
}

@Component({
    selector: 'idp-assessment-detail-modal',
    templateUrl: './idpAssessmentDetailModal.component.html',
    styleUrls: ['./idpAssessmentDetailModal.component.scss']
})
export class IdpAssessmentDetailModalComponent implements OnInit, AfterViewInit, IWeissmanModalComponent<IdpAssessmentDetailModalParams, Assessment> {
    constructor(private readonly _bsModalRef: BsModalRef,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _idpService: IdpService) {
    }

    params: IdpAssessmentDetailModalParams;
    result: Assessment;

    tableColumns = [{
        name: 'Component Type',
    }, {
        name: 'Component'
    }, {
        name: 'Assessed Value'
    }, {
        name: 'Ratio'
    },{
        name: 'FM Value'
    },{
        name: 'Total'
    },]
    rows: {
        // type: string;
        // total: number;
        // components: {
            name: string;
            av: number;
            ratio: number
            fmv: number;
            type: string;
        // }[]
    }[]
    hoveredRow: ComponentRow
    appealDeadline: Date;

    private _assessmentDetail: AssessmentViewModel;
    private _idpResults: Weissman.Model.Workflow.DocumentIntake.OcrResponse.Message.AssessmentPushMessageDto;

    get title(): string {
        return `Detected Values: ${this.params?.confidence} Confidence`;
    }

    ngOnInit(): void {
        this._assessmentDetail = this.params.assessmentDetail;
        this._idpResults = this.params.idpResults;

        this.appealDeadline = this._idpResults.assessmentRevision.appealDeadline

        const avComponents = map(this._idpResults.assessmentRevision.assessedValue.components, x => ({
            name: x.name,
            av: x.amount
        }))

        const fmvComponents = map(this._idpResults.assessmentRevision.fairMarketValue.components, x => ({
            name: x.name,
            fmv: x.amount
        }))


        // const exemComponents = map(this._idpResults.assessmentRevision.exemptions.components, x => ({
        //     name: x.name,
        //     av: x.amount
        // }))

        // const capComponents = map(this._idpResults.assessmentRevision.cap.components, x => ({
        //     name: x.name,
        //     av: x.amount
        // }))

        const ratioComponents = map(this._idpResults.assessmentRevision.components, x => ({
            name: x.componentName,
            ratio: x.ratio
        }))

        this.rows = chain([...avComponents, ...fmvComponents, ...ratioComponents])
            .groupBy('name')
            .map((vallArra, componentName) => {
                const comp = {
                    name: componentName,
                    type: 'Normal'
                }

                forEach(vallArra, val => {
                    assign(comp, val)
                })

                return comp
            })
            .value() as any[]
        
    }

    ngAfterViewInit(): void {
        const modalContainer = document.querySelector('modal-container');
        if (modalContainer) {
            modalContainer.classList.add('no-modal-backdrop');
        }
    }

    applyAll(): void {
        this._productAnalyticsService.logEvent('apply-detected-values', { OcrApplyAll: 'Apply' });
        // this._idpService.mapBillVMFromIDP(this._assessmentDetail, this._idpResults);

        this.result = this._assessmentDetail.model;

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._productAnalyticsService.logEvent('apply-detected-values', { OcrApplyAll: 'Cancel' });

        this._bsModalRef.hide();
    }
}
