<ws-modal-wrapper headerLabel="Billing Scenario"
                  acceptLabel="Save"
                  (cancel)="cancel()"
                  (save)="save()"
                  [disableSave]="scenarioForm?.invalid"
                  [hideSave]="viewOnly"
                  [suppressKeyboardEvents]="true"
                  [hideAuxButton]="viewOnly || scenarioForm?.value.efAction==='add'"
                  [disableAuxButton]="scenarioForm?.value.inUse"
                  [auxButtonHelpContentId]="(scenarioForm?.value.inUse ? 'collector.scenario-in-use' : '')"
                  auxButtonClass="btn-primary btn-danger"
                  (auxButton)="deleteScenarioModal()">
    <loading-spinner [marginBottom]="50" [show]="loading" [size]="5"></loading-spinner>
    <div [formGroup]="scenarioForm" *ngIf="!loading && scenarioForm">
        <div class="row scenario">
            <div class="col-lg-6">
                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <label class="mb-0">Scenario Name</label>
                    <div class="col-md-8 mx-3">
                        <input type="text"
                               class="form-control"
                               maxlength="100"
                               formControlName="name">
                    </div>
                </div>
                <div class="bill-number ws-flex-container-horizontal ws-flex-align-items-center mt-1">
                    <label class="mb-0"
                           helpTooltip
                           helpContentId="collector.bill-number">
                        Number of Bills
                    </label>
                    <div class="count-controls">
                        <button class="direction"
                                (click)="removeBill()"
                                [disabled]="collectorBills.length === 1">
                            <i class="fa fa-arrow-down"></i>
                        </button>
                        <div helpTooltip
                             helpContentId="collector.scenario-in-use"
                             [helpDisabled]="!scenario?.inUse">
                            <input type="text"
                                   [numbersOnly]="true"
                                   [negativeAllowed]="false"
                                   [decimalAllowed]="false"
                                   [minValue]="1"
                                   [maxValue]="4"
                                   maxlength="1"
                                   formControlName="billNumber"
                                   class="form-control">
                        </div>
                        <button class="direction"
                                (click)="addBill()"
                                [disabled]="collectorBills.length === 4">
                            <i class="fa fa-arrow-up"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div *ngIf="isDefault.value" class="default-scenario">
                    <h4 class="ws-no-margin">Default Scenario for Collector</h4>
                </div>
                <div *ngIf="!(isDefault.value || viewOnly)">
                    <button class="ace-btn btn-sm btn-primary"
                            (click)="makeDefault()">Make Default Scenario for Collector
                    </button>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" formControlName="enabled">Enabled
                    </label>
                </div>
            </div>
        </div>
        <ng-container formArrayName="collectorBills">
            <ng-container *ngFor="let bill of collectorBills.controls; let i = index">
                <div *ngIf="bill.value.efAction !== 'delete'" [formGroupName]="i" class="bill panel panel-default">
                    <div class="card-body p-4">
                        <div class="bill-name form-inline">
                            <div class="d-inline-block mb-0 ws-vertical-align-middle">
                                <label>Bill Name</label>
                                <input type="text" class="form-control" maxlength="100" formControlName="name">
                            </div>
                            <div class="d-inline-block mb-0 ws-vertical-align-middle" style="margin-left: 20px">
                                <label>Procure</label>
                                <input type="number"
                                       min="1"
                                       style="width: 60px;"
                                       class="form-control"
                                       formControlName="procureDays">
                                <label>days in advance of earliest payment due date</label>
                            </div>
                        </div>
                        <div *ngIf="bill.value.collectorPaymentOptions.length > 0" class="options-list-header">
                            <span class="bold-text">Payment Options:</span>
                        </div>
                        <ng-container formArrayName="collectorPaymentOptions">
                            <ng-container *ngFor="let option of bill.get('collectorPaymentOptions')['controls']; let j = index">
                                <div *ngIf="option.value.efAction !== 'delete'"
                                     [formGroupName]="j"
                                     class="payment-option">
                                    <div class="option-header">
                                        <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                                            <div class="default-option-radio" helpTooltip helpContentId="collector.default-payment-option">
                                                <input type="checkbox"
                                                       [id]="'isDefault' + j"
                                                       formControlName="isDefault"
                                                       (change)="undefaultOthers(bill, option, j)">
                                                <label [for]="'isDefault' + j" class="mb-0"></label>
                                            </div>
                                            <div *ngIf="option.value.isDefault" class="default-scenario">
                                                <span class="ws-no-margin ws-font-weight-normal">Default</span>
                                            </div>
                                            <div class="ws-flex-container-horizontal">
                                                <label class="mb-0">Option Name</label>
                                                <input type="text"
                                                       formControlName="name"
                                                       maxlength="100"
                                                       class="form-control">
                                            </div>
                                            <div *ngIf="!collector.isCompanyCollector" class="checkbox" helpTooltip helpContentId="collector.ptx-discount" style="margin-left: 20px;">
                                                <label [for]="'ptxDiscount' + j" class="mb-0">
                                                    <input type="checkbox"
                                                        [id]="'ptxDiscount' + j"
                                                        formControlName="ptxDiscount"
                                                        (change)="undiscountOthers(bill, option, $event)"> PTX Discount
                                                </label>
                                            </div>
                                        </div>
                                        <div class="count-controls"
                                             helpTooltip
                                             helpContentId="collector.payment-in-use"
                                             [helpDisabled]="!option.value.inUse">
                                            <button class="remove"
                                                    (click)="removeCollectorPaymentOption(bill, option, j)"
                                                    *ngIf="!viewOnly"
                                                    [disabled]="option.value.inUse">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="option-body ws-flex-container-vertical">
                                        <div class="payment-count ws-stretch ws-flex-container-horizontal ws-flex-justify-content-center ws-flex-align-items-center">
                                            <label># of Payments:</label>
                                            <div class="count-controls">
                                                <button class="direction"
                                                        (click)="removePayment(option, j)"
                                                        [disabled]="option.get('collectorPayments').length === 1 || option.get('paymentNumber').disabled">
                                                    <i class="fa fa-arrow-down"></i>
                                                </button>
                                                <div helpTooltip
                                                     helpContentId="collector.payment-in-use"
                                                     [helpDisabled]="!option.value.inUse">
                                                    <input type="text"
                                                           [numbersOnly]="true"
                                                           [negativeAllowed]="false"
                                                           [decimalAllowed]="false"
                                                           [minValue]="1"
                                                           [maxValue]="12"
                                                           maxlength="2"
                                                           formControlName="paymentNumber"
                                                           (change)="addRemovePayments(option, j)"
                                                           class="form-control">
                                                </div>
                                                <button class="direction"
                                                        (click)="addPayment(option, j)"
                                                        [disabled]="option.get('collectorPayments').length === 12 || option.get('paymentNumber').disabled">
                                                    <i class="fa fa-arrow-up"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="ws-flex-container-horizontal">
                                            <div class="payment-labels">
                                                <span>Payment Name:</span>
                                                <span>Pmt Due Date:</span>
                                                <span>Tax Projection:</span>
                                                <span>Tax Period:</span>
                                                <span>Discount Pct:</span>
                                            </div>
                                            <div class="payments ws-stretch">
                                                <ng-container formArrayName="collectorPayments">
                                                    <ng-container *ngFor="let payment of option.get('collectorPayments')['controls']; let k = index">
                                                        <div [formGroupName]="k"
                                                             *ngIf="payment.value.efAction !== 'delete'"
                                                             class="payment">
                                                            <div class="ws-flex-container-horizontal">
                                                                <input type="text"
                                                                       class="form-control"
                                                                       maxlength="100"
                                                                       formControlName="name">
                                                            </div>
                                                            <div class="ws-flex-container-horizontal">
                                                                <input type="text"
                                                                       class="form-control md-date"
                                                                       [ngClass]="{ 'form-error': payment.controls['dueMonthDay']?.invalid }"
                                                                       placeholder="MM/DD"
                                                                       formControlName="dueMonthDay">
                                                                <ws-select [options]="whens"
                                                                           valueProperty="id"
                                                                           labelProperty="name"
                                                                           formControlName="dueYearID">
                                                                </ws-select>
                                                            </div>
                                                            <div class="ws-flex-container-horizontal">
                                                                <input type="text"
                                                                       name="projectionPercentage"
                                                                       toPercentage
                                                                       [precision]="2"
                                                                       max="1"
                                                                       class="form-control"
                                                                       formControlName="projectionPercentage">
                                                                <ws-select [options]="projTypes"
                                                                           labelProperty="name"
                                                                           valueProperty="id"
                                                                           formControlName="projectionType">
                                                                </ws-select>
                                                            </div>
                                                            <div class="accrual ws-flex-container-horizontal ws-flex-align-items-center">
                                                                <input type="text"
                                                                       class="form-control md-date"
                                                                       [ngClass]="{ 'form-error': payment.controls['accrualBeginDate']?.invalid }"
                                                                       placeholder="MM/DD"
                                                                       maxlength="5"
                                                                       formControlName="accrualBeginDate">
                                                                <ws-select [options]="whens"
                                                                           labelProperty="name"
                                                                           valueProperty="id"
                                                                           formControlName="accrualBeginYearID">
                                                                </ws-select>
                                                                <span class="date-to-date">to</span>
                                                                <input type="text"
                                                                       class="form-control md-date"
                                                                       [ngClass]="{ 'form-error': payment.controls['accrualEndDate']?.invalid }"
                                                                       placeholder="MM/DD"
                                                                       maxlength="5"
                                                                       formControlName="accrualEndDate">
                                                                <ws-select [options]="toWhens(payment.controls['accrualBeginYearID'].value)"
                                                                           labelProperty="name"
                                                                           valueProperty="id"
                                                                           formControlName="accrualEndYearID">
                                                                </ws-select>
                                                            </div>
                                                            <div class="ws-flex-container-horizontal">
                                                                <input type="text"
                                                                       class="form-control"
                                                                       formControlName="discountPercent"
                                                                       name="discountPercent"
                                                                       toPercentage
                                                                       (change)="discountPercentChanged(option, $event)"
                                                                       [precision]="2">
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <button class="ace-btn btn-sm btn-primary"
                                (click)="addOption(bill)"
                                *ngIf="!viewOnly">Add Payment Option to Bill
                        </button>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ws-modal-wrapper>

