import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { DeliveryDetailFeedSpecification } from '../../clientServices.model';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { endsWith, omitBy } from 'lodash';
import { RyanInstanceId } from 'src/app/constants.new';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';

export interface DeliveryDetailsConfigureParams {
    editMode: boolean;
    feedSpecifications: DeliveryDetailFeedSpecification[];
    clientAbbreviation: string;
    entity: CompanyEntity;
    isBillPay: boolean;
    userSearchInstanceId: number;
    currentPaymentBatchData: Core.PaymentBatchDeliveryDetail
    isTopLevelCompany: boolean;
    dataHubEnabled: boolean;
    isEntityInRyanInstance: boolean;
}

interface DeliveryDetailsFeedSpecResult {
    clientAbbreviation: string;
    feedSpecifications: DeliveryDetailFeedSpecification[];
}

type DeliveryDetailsPaymentBatchResult = [Weissman.Model.Domain.DeliveryDetailPaymentBatchDTOIn, Core.PaymentBatchDeliveryDetail];

export interface DeliveryDetailsConfigureResult {
    feedSpecResult?: DeliveryDetailsFeedSpecResult;
    paymentBatchResult?: DeliveryDetailsPaymentBatchResult
}

@Component({
    selector: 'delivery-details-configure-modal',
    templateUrl: './deliveryDetailsConfigureModal.component.html'
})
export class DeliveryDetailsConfigureModalComponent implements IWeissmanModalComponent<DeliveryDetailsConfigureParams, DeliveryDetailsConfigureResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _featureFlagService: FeatureFlagsService
    ) { }

    params: DeliveryDetailsConfigureParams;
    result: DeliveryDetailsConfigureResult;

    paymentBatchData = {
        efAction: 'add',
        currentData: {} as Core.PaymentBatchDeliveryDetail,
        isDirty: false
    };

    get shouldDisplayPaymentBatch() {
        return this.params.userSearchInstanceId === RyanInstanceId 
            && this.params.isBillPay
            && this.params.isTopLevelCompany
    }

    get shouldDisplayDataHub() {
        return this.params.isTopLevelCompany && this._featureFlagService.featureFlags.enablePaymentBatchDataHub && this.params.isEntityInRyanInstance
    }

    get isSaveDisabled() {
        return this.params.isBillPay && this.params.feedSpecifications.length === 0;
    }

    save() {
        let paymentBatchResult;

        const feedSpecResult =  {
            feedSpecifications: this.params.feedSpecifications,
            clientAbbreviation: this.params.clientAbbreviation
        };

        if(this.paymentBatchData.isDirty) {
            const idsOnly =  omitBy(this.paymentBatchData.currentData, (val, key) => {
                return endsWith(key, 'User') || endsWith(key, 'Team');
            });
            paymentBatchResult =  [{
                ...idsOnly,
                efAction: this.paymentBatchData.efAction,
                ownerEntityID: this.params.entity.id,
                paymentBatchTaskSeriesOptionId: this.paymentBatchData.currentData.paymentBatchTaskSeriesOptionId
            } as Weissman.Model.Domain.DeliveryDetailPaymentBatchDTOIn,
            this.paymentBatchData.currentData];
        }

        this.result = {feedSpecResult, paymentBatchResult};
        this.close();
    }

    close() {
        this._bsModalRef.hide();
    }
}