<ws-modal-wrapper headerLabel="Tax Bill Transmit Delivery Details"
    [cancelLabel]="params.editMode ? 'Cancel' : 'Close'"
    [hideSave]="!params.editMode"
    saveLabel="OK"
    [disableSave]="isSaveDisabled"
    (save)="save()"
    (cancel)="close()">
    <tabset #paymentDetailsTabs>
        <tab heading="Feed Specifications">
            <delivery-details-feed-specs [editMode]="params.editMode"
                [(feedSpecifications)]="params.feedSpecifications"
                [(clientAbbreviation)]="params.clientAbbreviation"
                [entity]="params.entity"
                [dataHubEnabled]="params.dataHubEnabled"
                [shouldDisplayDataHub]="shouldDisplayDataHub"
                [isBillPay]="params.isBillPay">
            </delivery-details-feed-specs>
        </tab>
        <tab *ngIf="shouldDisplayPaymentBatch" heading="Payment Batch Workflow">
            <payment-batch-workflow 
                [editMode]="params.editMode"
                [entity]="params.entity"
                [userSearchInstanceId]="params.userSearchInstanceId"
                [currentPaymentBatchData]="params.currentPaymentBatchData"
                [dataHubEnabled]="params.dataHubEnabled"
                (setEfAction)="paymentBatchData.efAction = $event"
                (setCurrentData)="paymentBatchData.currentData = $event"
                [shouldDisplayDataHub]="shouldDisplayDataHub"
                (setIsDirty)="paymentBatchData.isDirty = $event"
                >
            </payment-batch-workflow>
        </tab>
    </tabset>
</ws-modal-wrapper>
