<ws-modal-wrapper headerLabel="Form Overrides"
                  [hideCancel]="true"
                  [suppressKeyboardEvents]="true"
                  (save)="cancel()"
                  (cancel)="cancel()">
    <div class="app-ui-view">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
