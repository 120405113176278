
<loading-spinner [show]="loading" [size]="4" [hasOverlay]="false"></loading-spinner>
<table class="workflows" *ngIf="!loading && selectedWorkflow" style="margin-top: 15px;">
    <tr *ngIf="shouldDisplayDataHub">
        <td style="font-weight: bold;">Data Hub Enabled:</td>
        <td>
            {{dataHubEnabled ? 'Yes' : 'No'}}
        </td>
    </tr>
    <tr>
        <td style="font-weight: bold;">Workflow:</td>
        <td>
            <select class="form-select form-select-sm"
                [(ngModel)]="selectedWorkflow"
                (change)="workflowChanged()"
                [disabled]="!editMode">
                <option *ngFor="let workflow of taskSeriesList" [ngValue]="workflow">{{workflow.seriesOptionName}}</option>
            </select>
        </td>
    </tr>
    <tr *ngFor="let task of selectedWorkflow.availableTasks">
        <td>{{task.taskName}}:</td>
        <td *ngIf="editMode" class="pbw-user-picker">
            <user-team-picker
                [userId]="currentData[prefixMap[task.taskTypeId] + 'UserId']"
                [teamId]="currentData[prefixMap[task.taskTypeId] + 'TeamId']"
                [entityIdScope]="entity.id"
                [entityTypeScope]="entity.type"
                [instanceIdScope]="userSearchInstanceId"
                (userChange)="userChanged($event, prefixMap[task.taskTypeId], task.isRequired)"
                [parentUsers]="parentUsers"
                [required]="task.isRequired"
                [disabled]="!editMode">
            </user-team-picker>
            <button *ngIf="!task.isRequired"
                class="ace-btn has-icon btn-primary btn-danger"
                (click)="userChanged(undefined, prefixMap[task.taskTypeId], false)"
                style="margin:0;">
                    <i class="fa fa-trash"></i>
            </button>
        </td>
        <td *ngIf="currentData[prefixMap[task.taskTypeId] + 'User'] && (!editMode || !entity.isTopLevel)">
            {{getDisplayName(currentData[prefixMap[task.taskTypeId] + 'User'], currentData[prefixMap[task.taskTypeId] + 'Team'])}}
        </td>
    </tr>
</table>