
<ng-template #reportingParcelSelectorTemplate  let-model="item" let-index="index">
    {{model.parcelAcctNum}}
</ng-template>

<div class="input-group" aria-label="Select Reporting Parcel">
    <input id="selectParcel"
        type="text"
        placeholder="Filter Reporting Parcels..."
        class="form-control exclude-modal-styling"
        [ngClass]="{ 'overridden': isOverridden }"
        autocomplete="off"
        container="body"
        (blur)="onReportingParcelBlur()"
        [(ngModel)]="reportingParcelFilter"
        [typeahead]="reportingParcels$"
        typeaheadOptionField="name"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        (typeaheadOnSelect)="onReportingParcelSelected($event)"
        [typeaheadOptionsLimit]="1000"
        [typeaheadItemTemplate]="reportingParcelSelectorTemplate"
        [disabled]="isDisabled || !isInitialized" />
    <button
        class="ace-btn btn-secondary has-icon"
        type="button"
        title="View all reporting parcels"
        (click)="openPickerModal()"
        [disabled]="isDisabled || !isInitialized">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
</div>
