<ws-modal-wrapper headerLabel="Accounting Periods"
                  cancelLabel="Close"
                  [hideSave]="true"
                  [hideCancel]="deleting"
                  (cancel)="close()">
    <div class="text-center loading-all" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loading && deleting">
        <div class="h3" style="margin-bottom: 1em;">Are you sure you want to delete this fiscal year?</div>
        <div>
            <button class="ace-btn btn-primary btn-danger" (click)="delete()">Delete</button>
            <button class="ace-btn btn-secondary ms-2" (click)="cancelDelete()">Cancel</button>
        </div>
    </div>
    <div *ngIf="!loading && !deleting">
        <div class="ws-flex-container-horizontal" style="align-items: baseline; justify-content: space-between;">
            <div *ngIf="selectedFiscalYear" class="ws-flex-container-horizontal" style="align-items: baseline;">
                <label>Fiscal Year:</label>
                <select [(ngModel)]="selectedFiscalYear" class="form-select form-select-sm" style="width: auto; margin-left: 5px;" *ngIf="!editMode" (click)="newFiscalYearPop.hide()">
                    <option *ngFor="let year of companyAccounting.companyFiscalYears" [ngValue]="year">{{year.name}}</option>
                </select>
                <input type="text" [(ngModel)]="selectedFiscalYear.name" class="form-control" style="width: 100px; margin-left: 5px;" *ngIf="editMode">
            </div>
            <div *ngIf="selectedFiscalYear">
                <weissman-datetime [readOnly]="true" [(inputDate)]="selectedFiscalYear.startDate" [dateOnly]="true"></weissman-datetime> - <weissman-datetime [readOnly]="true" [(inputDate)]="selectedFiscalYear.endDate" [dateOnly]="true"></weissman-datetime>
            </div>
            <div>
                <button class="ace-btn btn-sm btn-primary" #newFiscalYearPop="bs-popover" [disabled]="editMode || !canEdit" [popover]="newFiscalYear" [outsideClick]="true" (click)="addFiscalYearClicked()">Add Fiscal Year</button>
            </div>
            <div *ngIf="selectedFiscalYear">
                <button *ngIf="!editMode" class="ace-btn has-icon btn-primary" (click)="newFiscalYearPop.hide(); goToEditMode()" [disabled]="!canEdit"><i class="fa fa-pencil"></i></button>
                <div *ngIf="editMode">
                    <button class="ace-btn has-icon btn-primary" (click)="save()" [disabled]="!undeletedPeriods().length"><i class="fa fa-save"></i></button>
                    <button class="ace-btn has-icon btn-primary btn-danger" (click)="cancel()"><i class="fa fa-close"></i></button>
                </div>
            </div>
        </div>
        <div *ngIf="companyAccounting.companyFiscalYears.length">
            <table class="table table-striped table-condensed accounting-periods-table">
                <thead>
                    <tr>
                        <th style="text-align: left;">Period</th>
                        <th>Begin Date</th>
                        <th>End Date</th>
                        <th>Duration</th>
                        <th>Frozen</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let period of undeletedPeriods(); let last=last; let i=index;">
                        <td style="text-align: left;">
                            <span *ngIf="!editMode || period.isFrozen">{{period.name}}</span>
                            <input class="form-control" type="text" *ngIf="editMode && !period.isFrozen" [(ngModel)]="period.name" maxlength="100">
                        </td>
                        <td><weissman-datetime [readOnly]="!editMode || period.isFrozen" [(inputDate)]="period.startDate" [dateOnly]="true" (change)="dateChange('start', i)"></weissman-datetime></td>
                        <td><weissman-datetime [readOnly]="!editMode || period.isFrozen" [(inputDate)]="period.endDate" [dateOnly]="true" (change)="dateChange('end', i)"></weissman-datetime></td>
                        <td>{{period.durationText}}</td>
                        <td>{{period.isFrozen ? 'Yes' : 'No'}}</td>
                        <td>
                            <button class="ace-btn has-icon btn-sm btn-primary btn-danger"
                                    *ngIf="editMode && last && !period.isFrozen"
                                    (click)="remove(period)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <button class="ace-btn btn-sm btn-primary" *ngIf="editMode" (click)="addSinglePeriod()">Add Single Period</button>
                <button class="ace-btn btn-sm btn-primary" *ngIf="editMode && canDelete" (click)="beginDelete()">Delete Fiscal Year</button>
            </div>
        </div>
        <div *ngIf="!companyAccounting.companyFiscalYears.length" class="text-center" style="margin-top: 25px;">
            <p class="lead"><em>Add fiscal year to display periods</em></p>
        </div>
    </div>
</ws-modal-wrapper>

<ng-template #newFiscalYear>
    <div *ngIf="!placementChosen" style="display: flex; flex-direction: column; align-items: stretch;">
        <span style="text-align: center;">Add to...</span>
        <div style="margin-top: 10px;">
            <button class="ace-btn btn-primary" style="width: 100%;" (click)="choosePlacement('beginning', $event)">Beginning</button>
        </div>
        <div style="margin-top: 10px;">
            <button class="ace-btn btn-primary" style="width: 100%;" (click)="choosePlacement('end', $event)">End</button>
        </div>
    </div>
    <div *ngIf="placementChosen">
        <button *ngIf="!forcePlacement" class="ace-btn has-icon btn-secondary btn-xs" (click)="placementChosen=false"><i class="fa fa-arrow-left"></i></button>
        <table class="new-fiscal-year-table">
            <tr>
                <td>Name:</td>
                <td><input type="text" class="form-control" [(ngModel)]="newFiscalYearRequest.name"></td>
            </tr>
            <tr>
                <td>Begin Date:</td>
                <td><weissman-datetime [(inputDate)]="newFiscalYearRequest.startDate" [dateOnly]="true" [readOnly]="!beginningChosen && !!companyAccounting.companyFiscalYears.length"></weissman-datetime></td>
            </tr>
            <tr>
                <td>End Date:</td>
                <td><weissman-datetime [(inputDate)]="newFiscalYearRequest.endDate" [dateOnly]="true" [readOnly]="beginningChosen && !!companyAccounting.companyFiscalYears.length"></weissman-datetime></td>
            </tr>
            <tr>
                <td>Duration Type:</td>
                <td>
                    <select class="form-select form-select-sm" [(ngModel)]="newFiscalYearRequest.accountingDurationTypeId" style="width: auto;">
                        <option *ngFor="let type of durationTypes" [ngValue]="type.id">{{type.label}}</option>
                    </select>
                </td>
            </tr>
        </table>
        <div class="text-end">
            <button class="ace-btn btn-sm btn-primary" [disabled]="!newFiscalYearRequest.name || !newFiscalYearRequest.startDate || !newFiscalYearRequest.endDate" (click)="addFiscalYear()">Add</button>
        </div>
    </div>
</ng-template>
