
<loading-spinner [show]="loading" [size]="4" [hasOverlay]="false"></loading-spinner>
<div *ngIf="availableFeedSpecs" style="margin-top: 15px;">
    <div style="margin-bottom: 15px; display: flex; flex-direction: column; gap: 10px;">
        <div style="display: flex; align-items: center; gap: 15px;">
            <div style="white-space: nowrap; min-width: 118px; font-weight: bold;">Client Abbreviation:</div>
            <input type="text"
                maxlength="50"
                [(ngModel)]="clientAbbreviation"
                (change)="clientAbbreviationChange.emit(clientAbbreviation)"
                class="form-control"
                [disabled]="!editMode">
        </div>
        <div style="display: flex; align-items: center; gap: 15px;">
            <div style="white-space: nowrap; min-width: 118px; font-weight: bold;">Data Hub Enabled:</div>
            <div>{{dataHubEnabled ? 'Yes' : 'No'}}</div>
        </div>
    </div>
    <div *ngIf="!feedSpecifications" class="text-center text-muted">
        <em>Click to Add Feed Specifications</em>
    </div>
    <div style="max-height: 400px; overflow: auto; margin-bottom: 20px;">
        <table class="table" *ngIf="feedSpecifications">
            <thead>
                <tr>
                    <th>Name</th>
                    <th class="text-center">Included in Email</th>
                    <th *ngIf="isBillPay">Feed Applicability</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let spec of feedSpecifications" (mouseenter)="hoveredSpecId = spec.feedSpecificationID" (mouseleave)="hoveredSpecId = null">
                    <td style="white-space: nowrap;">{{spec.feedSpecification.name}}</td>
                    <td class="text-center">
                        <div class="checkbox" style="margin: 0;">
                            <label>
                                <input type="checkbox" [(ngModel)]="spec.includedInEmail" [disabled]="!editMode">
                            </label>
                        </div>
                    </td>
                    <td  *ngIf="isBillPay">
                        {{getFeedApplicability(spec)}}
                    </td>

                    <td>
                        <button class="ace-btn btn-primary btn-danger has-icon"
                            [ngClass]="{'invisible': !editMode || hoveredSpecId !== spec.feedSpecificationID}"
                            (click)="removeSpec(spec)">
                                <i class="fa fa-trash" ng-click="vm.removeSpec(spec)"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ws-select *ngIf="editMode"
            placeholder="Add Specification"
            [(ngModel)]="chosenSpec"
            (selectedOptionChanged)="addSpec()"
            [options]="availableFeedSpecs"
            labelProperty="name"
            [returnEntireOption]="true">
    </ws-select>
</div>