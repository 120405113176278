<ws-busy-indicator [message]="busyIndicatorMessageManager.message"
    *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="modalTitle"
                  (save)="save()"
                  (cancel)="cancel()"
                  [hideSave]="!params.canEdit">
    <form #formDetailsForm="ngForm">
        <div class="row">
            <div class="col-md-6 mb-3">
                <div class="mb-3">
                    <label for="state">State</label><br>
                    <select name="state" class="form-select form-select-sm"
                        [disabled]="params.mode !== formMode.Create || params.type !== formType.Form"
                        [(ngModel)]="selectedStateId">
                        <option *ngFor="let state of states" [ngValue]="state.stateID">{{state.fullName}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="formType">Form Type</label>
                    <select name="formType" class="form-select form-select-sm" [(ngModel)]="params.formTypeId"
                        [disabled]="params.mode !== formMode.Create || params.type === formType.FormRevision || params.type === formType.SupplementalForm">
                        <option *ngFor="let formType of formTypes" [ngValue]="formType.value">{{formType.name}}</option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="params.mode === formMode.Create">
                    <label for="taxYear">Tax Year</label>
                    <ws-select name="taxYear" [options]="taxYears" [isPrimitive]="true" [(ngModel)]="params.taxYear"
                        [canSearch]="false"></ws-select>
                </div>
                <div class="mb-3" *ngIf="formTypesEqual(params.formTypeId, FormTypeEnum.ComplianceType) && (params.type !== formType.Form || params.mode === formMode.Create)">
                    <label for="electronicFilingType">Electronic Filing Type</label>
                    <div helpTooltip
                         [helpContentId]="getElectronicFilingTypeHelpContentId()">
                        <select name="electronicFilingType"
                                class="form-select form-select-sm"
                                [(ngModel)]="params.electronicFilingTypeId"
                                [disabled]="!this.doesStateHaveElectronicFilingType || params.isFormRevisionInLockedBatch">
                            <option [ngValue]="null">None</option>
                            <option *ngFor="let electronicFilingType of electronicFilingTypes" [ngValue]="electronicFilingType.electronicFilingTypeId">{{electronicFilingType.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3"
                    *ngIf="formTypesEqual(params.formTypeId, FormTypeEnum.ComplianceType) && (params.type === formType.FormRevision || params.type === formType.SupplementalForm || params.type == formType.Form && !params.formId)">
                    <label for="supportsEstFMVReporting">Supports Est. FMV reporting</label>
                    <select name="supportsEstFMVReporting" class="form-select form-select-sm"
                        [(ngModel)]="params.supportsEstFMVReporting" [disabled]="!params.canEdit">
                        <option [ngValue]="false" selected>No</option>
                        <option [ngValue]="true">Yes</option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="params.type === formType.SupplementalForm">
                    <label for="supplementFormName">Supplemental Form Name</label>
                    <input name="supplementFormName" type="text" class="form-control" autocomplete="off"
                        [(ngModel)]="params.supplementalFormName" maxlength="100" [disabled]="!params.canEdit" />
                </div>
                <div class="mb-3"
                    *ngIf="params.mode === formMode.Create && formTypesEqual(params.formTypeId, FormTypeEnum.Appeal)">
                    <label for="appealLevel">Appeal Level</label>
                    <select name="appealLevel" class="form-select form-select-sm" [(ngModel)]="params.appealLevelId">
                        <option [ngValue]="null" disabled selected>--Select--</option>
                        <option *ngFor="let al of params.appealLevels" [ngValue]="al.appealLevelID">{{ al.abbr }}
                        </option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="params.type === formType.FormRevision">
                    <label for="priorFormRevision">Prior Form Revision</label>
                    <select name="priorFormRevision" class="form-select form-select-sm"
                        [(ngModel)]="params.priorFormRevisionId" [disabled]="!params.canEdit">
                        <option [ngValue]="null" selected>None</option>
                        <option *ngFor="let pfr of priorFormRevisions" [ngValue]="pfr.formRevisionId">{{ pfr.name }}
                        </option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="params.type === formType.SupplementalForm">
                    <label for="supplementFormDescription">Supplemental Form Description</label>
                    <input name="supplementFormDescription" type="text" class="form-control" autocomplete="off"
                        [(ngModel)]="params.supplementalFormDescription" maxlength="250" [disabled]="!params.canEdit" />
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="mb-3">
                    <label for="formName">Form Name</label>
                    <input name="formName" type="text" class="form-control" autocomplete="off"
                        [(ngModel)]="params.formName" maxlength="100"
                        [disabled]="params.type === formType.FormRevision || params.type === formType.SupplementalForm || !params.canEdit" />
                </div>
                <div class="mb-3">
                    <label for="formDescription">Form Description</label>
                    <input name="formDescription" type="text" class="form-control" autocomplete="off"
                        [(ngModel)]="params.formDescription" maxlength="250"
                        [disabled]="params.type === formType.FormRevision || params.type === formType.SupplementalForm || !params.canEdit" />
                </div>
                <div class="mb-3"
                    *ngIf="params.type === formType.FormRevision || params.type === formType.SupplementalForm || params.type == formType.Form && !params.formId">
                    <label for="revisionName">Revision Name</label>
                    <input name="revisionName" type="text" class="form-control" autocomplete="off"
                        [(ngModel)]="params.revisionName" maxlength="100"
                        [disabled]="params.type === formType.SupplementalForm  || !params.canEdit" />
                </div>
                <div class="mb-3"
                    *ngIf="params.type === formType.FormRevision || params.type === formType.SupplementalForm || params.type == formType.Form && !params.formId">
                    <label for="revisionDescription">Revision Description</label>
                    <input name="revisionDescription" type="text" class="form-control" autocomplete="off"
                        [(ngModel)]="params.revisionDescription" maxlength="250"
                        [disabled]="params.type === formType.SupplementalForm  || !params.canEdit" />
                </div>
                <div class="mb-3"
                    *ngIf="formTypesEqual(params.formTypeId, FormTypeEnum.ComplianceType) && (params.type === formType.FormRevision || params.type === formType.SupplementalForm || params.type == formType.Form && !params.formId)">
                    <label for="supportsNBVReporting">Supports NBV reporting</label>
                    <select name="supportsNBVReporting" class="form-select form-select-sm"
                        [(ngModel)]="params.supportsNBVReporting" [disabled]="!params.canEdit">
                        <option [ngValue]="false" selected>No</option>
                        <option [ngValue]="true">Yes</option>
                    </select>
                </div>
                <div class="mb-3"
                    *ngIf="params.type === formType.FormRevision || params.type === formType.SupplementalForm">
                    <label for="stampLocation">Stamp Location</label>
                    <select name="stampLocation" class="form-select form-select-sm"
                        [(ngModel)]="params.revisionStampLocationId" [disabled]="!params.canEdit">
                        <option *ngFor="let sl of params.stampLocations" [ngValue]="sl.formRevisionStampLocationId">{{
                            sl.name }}</option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="params.type === formType.SupplementalForm">
                    <label for="priorSupplement">Prior Supplemental Form</label>
                    <select name="priorSupplement" class="form-select form-select-sm"
                        [(ngModel)]="params.priorFormRevisionId" [disabled]="!params.canEdit">
                        <option [ngValue]="null" selected>None</option>
                        <option *ngFor="let pfr of priorFormRevisions" [ngValue]="pfr.formRevisionId">{{ pfr.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
