import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { NavigationService } from '../../../Layout/navigation.service';
import { ReturnSettingsEntityTypeEnum } from '../../Return/Models/enums';
import { ReturnSettingsComponent } from '../../Return/Return-Parts/Settings/Return-Settings/returnSettings.component';

@Component({
    selector: 'compliance-company-return-settings-panel',
    templateUrl: './companyReturnSettingsPanel.component.html'
})
export class CompanyReturnSettingsPanelComponent implements OnInit, OnDestroy {
    constructor(private readonly _navigationService: NavigationService) {
    }

    @Input() companyId: number;
    @Input() allowEdit: boolean;

    @ViewChild(ReturnSettingsComponent) returnSettingsComponent: ReturnSettingsComponent;

    isShowInfo: boolean = false;
    editMode: boolean = false;
    editLock: boolean = false;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    ReturnSettingsEntityTypeEnum = ReturnSettingsEntityTypeEnum;
    hasChanges: boolean;
    isLoading: boolean;

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._navigationService.globalEditMode$.pipe(takeUntil(this._destroy$)).subscribe(editMode => {
            this.editLock = editMode;
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    expandOrCollapse(): boolean {
        if(this.editMode) {
            return true;
        }

        this.isShowInfo = !this.isShowInfo;

        if(this.isShowInfo) {
            this.showInfo();
        }
        return false;
    }

    showInfo(): void {
        this.isLoading = true;
    }

    async save(): Promise<void> {
        try{
            if (this.returnSettingsComponent){
                this.busyIndicatorMessageManager.add('Saving', 'saving');
                await this.returnSettingsComponent.save();
                this.busyIndicatorMessageManager.remove('saving');
            }
            this.editMode = false;
        }
        finally{
            this.busyIndicatorMessageManager.remove('saving');
            this._navigationService.disableNavWarning();
        }
    }

    async setEditMode(): Promise<void> {
        this.editMode = true;
        this._navigationService.enableNavWarning('You have unsaved changes. Are you sure you want to leave this page?');
        await this.returnSettingsComponent.edit();
    }

    async cancelEdit(): Promise<void> {
        this.editMode = false;
        this._navigationService.disableNavWarning();
        await this.returnSettingsComponent.cancel();
    }

    onReturnSettingsInitialized() {
        this.isLoading = false;
    }

    onReturnSettingsChanged(value: boolean){
        this.hasChanges = value;
    }
}
