import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SupportInstanceRepository } from '../../Compliance/Repositories/supportInstance.repository';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';

export interface SupportInstanceDeactivateInstanceConfirmComponentParams {
    instanceId: number;
    pendingReason: string;
    pendingUserName: string;
    pendingDateTime: string;
}
@Component({
    selector: 'support-instance-deactivate-instance-confirm',
    templateUrl: './supportInstanceDeactivateInstanceConfirm.component.html',
    styleUrls: ['./supportInstanceDeactivateInstanceConfirm.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SupportInstanceDeactivateInstanceConfirmComponent implements OnInit, OnDestroy, IWeissmanModalComponent<SupportInstanceDeactivateInstanceConfirmComponentParams, Core.SupportInstanceInfoModel>{
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _supportInstanceRepository: SupportInstanceRepository,
        private readonly _toastr: ToastrService,
        private readonly _messageModalService: MessageModalService,
    ) {}

    params: SupportInstanceDeactivateInstanceConfirmComponentParams;
    result: Core.SupportInstanceInfoModel;
    reasonLength: number = 0;
    maxReasonLength: number = 1000;
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    form: UntypedFormGroup;

    private destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            reason: [null, [Validators.required, Validators.maxLength(this.maxReasonLength)]],
        });

        this.form.controls['reason'].valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(x => this.reasonLength = (x || '').length);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        const busyMsgIdSaving = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgIdSaving);

        const request: Core.SupportInstanceRequestModel = {
            instanceId: this.params.instanceId,
            reason: this.form.get('reason').value,
            checkForOpenAppeals: true
        }

        try{
            this.result = await this.confirmDeactivateInstance(request);

            if (this.result.hasOpenAppeals == false) {
                this._toastr.success(`The ${this.result.instanceName} instance was successfully deactivated.`);
                this.busyIndicatorMessageManager.remove(busyMsgIdSaving);
            } else {
                var message = `
                        This instance has open appeals. Are you sure you would like to continue?
        
                        Open Appeals:
                        ${this.result.openAppealNames.join('\n')}`;
        
                try {
                    await this._messageModalService.confirm(message, 'Warning');
                } catch (e) {
                    return Promise.resolve();
                }

                this.busyIndicatorMessageManager.remove(busyMsgIdSaving);

                const busyMsgIdOpenAppeals = 'deactivate with open appeals';
                this.busyIndicatorMessageManager.add('Saving', busyMsgIdOpenAppeals);

                try{
                    const requestTwo : Core.SupportInstanceRequestModel = {
                        instanceId: this.params.instanceId,
                        reason: this.form.get('reason').value,
                        checkForOpenAppeals: false
                    }

                    this.result = await this.confirmDeactivateInstance(requestTwo);
                    this._toastr.success(`The ${this.result.instanceName} instance was successfully deactivated.`)
                } finally {
                    this.busyIndicatorMessageManager.remove(busyMsgIdOpenAppeals);
                }
            }
        } finally {
            this._bsModalRef.hide();
        }
    }

    private async confirmDeactivateInstance(request: Core.SupportInstanceRequestModel): Promise<Core.SupportInstanceInfoModel> {
        return await lastValueFrom(this._supportInstanceRepository.deactivateInstanceConfirm(request));
    }

    async revert(): Promise<void> {
        const busyMsgId = 'reverting';
        this.busyIndicatorMessageManager.add('Reverting', busyMsgId);

        const request: Core.SupportInstanceRequestModel = {
            instanceId: this.params.instanceId,
            reason: this.form.get('reason').value,
        }

        try {
            this.result = await lastValueFrom(this._supportInstanceRepository.deactivateInstanceRevert(request));
            this._bsModalRef.hide();

            this._toastr.success(`The ${this.result.instanceName} instance was successfully reactivated.`)
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    onReasonChange(reason: string) {
        this.reasonLength = (reason || '').length;
    }
}

