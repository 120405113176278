
<div class="checkbox float-end" *ngIf="isSubsidiary">
    <label>
        <input type="checkbox"
            [(ngModel)]="currentData.useTopLevelCompanySettings"
            [disabled]="areNoTopLevelSettings || !editMode"
            (change)="useTopLevelChanged()"> Use Top Level Company Settings
    </label>
</div>

<fieldset [disabled]="currentData.useTopLevelCompanySettings || !isCompany" style="width: 100%;">
    <table class="delivery-details">
        <tr>
            <td>Output Type:</td>
            <td>
                <select class="form-select form-select-sm"
                    [(ngModel)]="currentData.transmittalOutputTypeID"
                    [disabled]="!editMode">
                        <option *ngFor="let option of transmittalOutputTypes" [ngValue]="option.id">{{option.name}}</option>
                </select>
                <button (click)="launchConfigureModal()"
                        type="button"
                        *ngIf="currentData.transmittalOutputTypeID"
                        class="ace-btn btn-sm btn-primary">
                {{ !currentData.useTopLevelCompanySettings && editMode ? 'Configure' : 'View'}}
                </button>
                <!-- <button class="ace-btn btn-sm btn-primary"
                    style="margin: 0;"
                    *ngIf="isPaymentBatchAvailable()"
                    (click)="launchPaymentBatchModal()">
                    Payment Batch
                </button> -->
            </td>
            <td>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                            [(ngModel)]="currentData.isMaxPackageSize"
                            [disabled]="!currentData.transmittalOutputTypeID || !editMode"
                            (change)="maybeNullInputs()"> Maximum
                    </label>
                </div>
            </td>
            <td>
                <input class="form-control"
                    type="text"
                    [(ngModel)]="currentData.maxPackageSize"
                    currencyMask
                    [options]="{ allowNegative: false, precision: 0, prefix: ''}"
                    [min]="1"
                    [max]="1000"
                    [disabled]="!currentData.transmittalOutputTypeID || !currentData.isMaxPackageSize || !editMode">
                    <span style="margin-left: 8px;">MB package size</span>
            </td>
        </tr>
        <tr>
            <td>Transmit Cover:</td>
            <td>
                <select class="form-select form-select-sm"
                    [(ngModel)]="currentData.transmittalFormatID"
                    [disabled]="!currentData.transmittalOutputTypeID || !editMode">
                        <option *ngFor="let option of transmittalFormats" [ngValue]="option.id">{{option.name}}</option>
                </select>
                <site-descriptors-edit
                    *ngIf="showSiteDescriptorsSelector"
                    [isEditMode]="editMode"
                    [companyId]="entity.id"
                    [siteDescriptors]="currentData.siteDescriptors"
                    (siteDescriptorsChanged)="onSiteDescriptorsChanged($event)">
                </site-descriptors-edit>
            </td>
            <td>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                            [(ngModel)]="currentData.isMaxPaymentItems"
                            [disabled]="!currentData.transmittalOutputTypeID || !editMode"
                            (change)="maybeNullInputs()"> Maximum
                    </label>
                </div>
            </td>
            <td>
                <input class="form-control"
                    type="text"
                    [(ngModel)]="currentData.maxPaymentItemsPerTransmittal"
                    currencyMask
                    [min]="1"
                    [max]="1000"
                    [options]="{ allowNegative: false, precision: 0, prefix: ''}"
                    [disabled]="!currentData.transmittalOutputTypeID || !currentData.isMaxPaymentItems || !editMode">
                    <span style="margin-left: 8px;">payment items per transmittal</span>
            </td>
        </tr>
        <tr>
            <td>Package Cover:</td>
            <td>
                <select
                    class="form-select form-select-sm"
                    [(ngModel)]="currentData.transmittalPackageCoverId"
                    [disabled]="!editMode">
                        <option *ngFor="let option of transmittalPackageCovers" [ngValue]="option.id">{{option.name}}</option>
                </select>
            </td>
            <td>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                            [(ngModel)]="currentData.isOne"
                            [disabled]="!currentData.transmittalOutputTypeID || !editMode"
                            (change)="maybeNullInputs()"> One
                    </label>
                </div>
            </td>
            <td>
                <select
                    class="form-select form-select-sm"
                    [(ngModel)]="currentData.deliveryDetailPerPackageLimitTypeID"
                    [disabled]="!currentData.transmittalOutputTypeID || !currentData.isOne || !editMode">
                        <option *ngFor="let option of deliveryDetailPerPackageLimitTypes" [ngValue]="option.id">{{option.name}}</option>
                </select>
                <span style="margin-left: 8px;">per package</span>
            </td>
        </tr>
        <tr>
            <td>Group By:</td>
            <td>
                <select
                    class="form-select form-select-sm"
                    [(ngModel)]="currentData.transmittalGroupByID"
                    [disabled]="!currentData.transmittalOutputTypeID || !editMode">
                        <option *ngFor="let option of transmittalGroupBys" [ngValue]="option.id">{{option.name}}</option>
                </select>
            </td>
            <td colspan="2">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                                [(ngModel)]="currentData.consolidateTransmittalAttachment"
                                [disabled]="!editMode">Consolidate Transmittal Attachment
                    </label>
                </div>
            </td>
        </tr>
        <tr>
            <td>Message:</td>
            <td>
                <select
                    class="form-select form-select-sm"
                    [ngModel]="currentData.packageEmailTemplateID"
                    (ngModelChange)="maybeLaunchCustomModal($event)"
                    [disabled]="!editMode">
                        <option *ngFor="let option of packageEmailTemplates" [ngValue]="option.packageEmailTemplateID">{{option.name}}</option>
                </select>
                <button class="ace-btn btn-sm btn-primary"
                        *ngIf="!currentData.packageEmailTemplateID"
                        [disabled]="!editMode" (click)="maybeLaunchCustomModal()">
                    Edit
                </button>
                <button class="ace-btn btn-sm btn-primary"
                        *ngIf="currentData.packageEmailTemplateID"
                        [disabled]="!editMode"
                        (click)="previewTemplate()">
                    View
                </button>
            </td>
            <td colspan="2">
                <div class="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            [(ngModel)]="currentData.skipBillImage"
                            [disabled]="!editMode">
                        Omit Transmittal Bill Images
                    </label>
                </div>
            </td>
        </tr>
    </table>
</fieldset>
