import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyModel, CompanyService } from '../company.service';
import { NavigationService } from '../../../Layout/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { DXPService } from '../../../Admin/DXP/dxp.service';
import { StateService } from '../../../Common/States/States.Service';
import { UserInstanceService } from '../../../User/userInstance.service';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { CompanyContractsModalParams, CompanyContractsModalComponent } from '../../../Consulting/CompanyContracts/companyContractsModal.component';
import { EntityTypeIds } from '../../../constants.new';
import { InstanceRights, RestrictService } from '../../../Common/Permissions/restrict.service';
import { SnackBarService } from '../../../Busy-Indicator';

import * as _ from 'lodash';
import * as Moment from 'moment';
import { UserService } from '../../../Account/user.service';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';

@Component({
    selector: 'company-settings',
    templateUrl: './companySettings.component.html',
    styleUrls: ['./companySettings.component.scss']
})
export class CompanySettingsComponent implements OnInit, OnDestroy {
    constructor(private readonly _companyService: CompanyService,
                private readonly _navigationService: NavigationService,
                private readonly _toastr: ToastrService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _dxpService: DXPService,
                private readonly _restrictService: RestrictService,
                private readonly _userInstanceService: UserInstanceService,
                private readonly _statesService: StateService,
                private readonly _modalService: WeissmanModalService,
                public readonly _snackBarService: SnackBarService,
                private readonly _userService: UserService,
                private readonly _featureFlagService: FeatureFlagsService
    ) {}

    @Input() company: CompanyModel;
    @Input() companyId: number;
    @Input() allowEdit: boolean;
    @Input() isTopLevelCompany: boolean;
    @Input() parentCompany: Weissman.Model.Domain.Company;

    editMode: boolean;
    editLock: boolean;
    addingYear: boolean;
    isShowSettings: boolean;
    isLoading: boolean;
    useParentVendorFlag: boolean = true;
    dxpSyncDisabled: boolean = true;
    syncDxpLocked: boolean;
    selectingState: boolean;
    accrualsEnabled: boolean;
    flatInUseDescriptors: Core.CompanyFeatureDescriptorModel[];
    inUseDescriptors: _.Dictionary<Core.CompanyFeatureDescriptorModel[]>;
    canImportData: boolean;
    hasManageConsultingEngagements: boolean;
    showAllocations: boolean = false;

    taxAmountAdjustments: Compliance.NameValuePair<number>[] = [
        { name: 'Amortize', value: 2 },
        { name: 'Single Period', value: 1 }
    ];

    exportFileTypes: Core.AllowedAccrualFileTypeModel[] = [];

    budgetBasisOptions: Compliance.NameValuePair<number>[] = [
        { name: 'Accrual', value: 1 },
        { name: 'Cash', value: 2 },
        { name: 'Accrual and Cash', value: 3 },
    ];

    exportProperties: Compliance.NameValuePair<number>[] = [
        { name: 'Site Number', value: Core.AccrualExportPropertyIdentificationEnum.SiteNumber}
    ];

    options;
    companyStateModel = {
        stateNames: []
    };

    yearToAdd: number;
    yearsToAdd: number[];
    economicUnitTypes: Core.EconomicUnitTypeModel[];
    companyStates: string[] = [];

    siteCodeEconUnitTypeId = 0;

    entityIsInRyanInstance = false;
    showInvoiceAppealSavingsSetting = false;
    enablePaymentBatchDataHub = false

    private _originalOptions;
    private _destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        this.showAllocations = this._featureFlagService.featureFlags.enableResponsibleEntityFeature;
        this.enablePaymentBatchDataHub = this._featureFlagService.featureFlags.enablePaymentBatchDataHub

        this.options = {
            useParentVendorFlag: this.company.useParentVendorCodes,
            invoiceAppealSavingsFlag: this.company.invoiceAppealSavings,
            syncDXPFlag: this.company.syncDXP,
            fiscalYearStart: this.company.fiscalYearStart,
            budgetsEnabled: this.company.budgetsEnabled,
            budgetBasis: this.company.budgetBasis,
            budgetDescriptors: this.company.budgetDescriptors,
            accrualsEnabled: this.company.accrualsEnabled,
            defaultAccrualAdjustmentMethodId: this.company.defaultAccrualAdjustmentMethodId,
            accrualEconUnitTypeId: this.company.accrualEconUnitTypeId,
            accrualEconUnitDescriptorId: this.company.accrualEconUnitDescriptorId,
            useTopLevelCompanyCOA: this.company.useTopLevelCompanyCOA,
            accrualFileTypeId: this.company.accrualFileTypeId,
            excludeFromAccruals: this.company.excludeFromAccruals,
            accrualDescriptors: this.company.accrualDescriptors,
            tlCompanyAccrualsEnabled: this.company.tlCompanyAccrualsEnabled,
            accrualExportPropertyIdentificationId: this.company.accrualExportPropertyIdentificationId,
            accrualExportPropIdentDescriptorId: this.company.accrualExportPropIdentDescriptorId,
            requireClientApprovalFlag: this.company.requireClientApproval,
            enableAllocations: this.company.enableAllocations,
            dataHubEnabled: this.company.dataHubEnabled
        };

        const promises: [Promise<Core.GetEconomicUnitTypesResult>, Promise<Core.AllowedAccrualFileTypesResultModel>, Promise<void>?] = [
            this._companyService.getEconomicUnitTypes(),
            this._companyService.getAllowedAccrualFileTypes(this.companyId)
        ];

        if (this.options.budgetsEnabled || this.options.accrualsEnabled) {
            promises.push(this._getInUseDescriptors());
        }

        const initialCalls = await Promise.all(promises);

        this.economicUnitTypes = (initialCalls[0] as Core.GetEconomicUnitTypesResult).types;
        if(this.economicUnitTypes.length) {
            // Make sure economic unit types are sorted in ascending Name order
            this.economicUnitTypes = this.economicUnitTypes.sort((a, b) => a.name.localeCompare(b.name));
            // Default Economic Unit to first in list if one hasn't been selected.
            this.options.accrualEconUnitTypeId = this.options.accrualEconUnitTypeId || this.economicUnitTypes[0].economicUnitTypeId;
            const entry = this.economicUnitTypes.find(x => x.name === 'Site Code');
            this.siteCodeEconUnitTypeId = entry ? entry.economicUnitTypeId : 0;
        }

        this.exportFileTypes = (initialCalls[1] as Core.AllowedAccrualFileTypesResultModel).entries;
        if(this.exportFileTypes.length) {
            // Make sure types are sorted in ascending Accrual File Type Id order
            this.exportFileTypes = this.exportFileTypes.sort((a, b) => a.accrualFileTypeId - b.accrualFileTypeId);
        }

        this.entityIsInRyanInstance = this.company.instanceID == this._userInstanceService.RyanInstanceId;
        this.canImportData = this._restrictService.hasInstanceRight(InstanceRights.COMPANYDATAIMPORTSVIEW, this.company.instanceID);
        this.hasManageConsultingEngagements = this._restrictService.hasInstanceRight(InstanceRights.MANAGECONSULTINGENGAGEMENT, this.company.instanceID);
        this.showInvoiceAppealSavingsSetting = this.company.instanceID == this._userInstanceService.RyanInstanceId ||
            this._userService.hasRyanInstanceMembership() && this._userInstanceService.isCurrentInstanceRyan();

        this._navigationService.globalEditMode$.pipe(takeUntil(this._destroy$)).subscribe(editMode => {
            this.editLock = editMode;
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    get siteCharSelectedForExport(): boolean {
        return this.options.accrualExportPropertyIdentificationId === Core.AccrualExportPropertyIdentificationEnum.SiteCharacteristic;
    }

    get siteNumberSelectedForExport(): boolean {
        return this.options.accrualExportPropertyIdentificationId === Core.AccrualExportPropertyIdentificationEnum.SiteNumber;
    }

    setEditMode(): void {
        this._originalOptions = _.cloneDeep(this.options);
        this.editMode = true;
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    cancelEdit() {
        this.options = this._originalOptions;
        this.editMode = false;
        this.selectingState = false;
        this._navigationService.disableNavWarning();
    }

    async save(): Promise<void> {
        if (!this.options.fiscalYearStart) {
            this._toastr.warning('Please enter Fiscal Year Start in the format MM/DD (for example 10/30 for October 30th)', 'Invalid Fiscal Year Start entered');
            return;
        }
        else{
            const dateSlashValid = Moment(this.options.fiscalYearStart, 'M/D', true).isValid();
            const dateDashValid = Moment(this.options.fiscalYearStart, 'M-D', true).isValid();

            if (!(dateSlashValid || dateDashValid)){
                this._toastr.warning(`${this.options.fiscalYearStart  } is not valid date`, 'Invalid Fiscal Year Start entered');
                return;
            }
        }
        if ( !this.options.accrualsEnabled || !this.isTopLevelCompany )
        {
            this.options.accrualEconUnitTypeId = null;
            this.options.accrualEconUnitDescriptorId = null;
            this.options.accrualFileTypeId = null;
        }
        else if ( this.options.accrualEconUnitTypeId )
        {
            if (this.options.accrualEconUnitTypeId == this.siteCodeEconUnitTypeId) {
                if (!this.options.accrualEconUnitDescriptorId) {
                    this._toastr.error('Economic Unit Site Code is required');
                    return;
                }
            } else {
                // Descriptor Id is non-applicable unless Econ Unit is SiteCode.
                this.options.accrualEconUnitDescriptorId = null;
            }
        }

        this.isLoading = true;
        try {
            const result = await this._companyService.updateCompanySetting(
                this.companyId,
                this.options.useParentVendorFlag,
                this.options.invoiceAppealSavingsFlag,
                this.options.fiscalYearStart,
                this.options.syncDXPFlag,
                this.options.budgetsEnabled,
                this.options.budgetBasis,
                this.options.budgetDescriptors,
                this.options.accrualsEnabled,
                this.options.defaultAccrualAdjustmentMethodId,
                this.options.useTopLevelCompanyCOA,
                this.options.accrualEconUnitTypeId,
                this.options.accrualEconUnitDescriptorId,
                this.options.accrualFileTypeId,
                this.options.accrualDescriptors,
                this.options.excludeFromAccruals,
                this.options.accrualExportPropertyIdentificationId,
                this.options.accrualExportPropIdentDescriptorId,
                this.options.requireClientApprovalFlag,
                this.options.enableAllocations,
                this.options.dataHubEnabled
            );

            if (this.company.useParentVendorCodes !== this.options.useParentVendorFlag) {
                this.company.vendorCodeCount = result.vendorCodeCount;
            }
            this.company.useParentVendorCodes = result.useParentVendorCodes;
            this.company.invoiceAppealSavings = result.invoiceAppealSavings;
            this.company.rowVersion = result.rowVersion;
            this.company.budgetsEnabled = result.budgetsEnabled;
            this.company.budgetBasis = this.options.budgetBasis;
            this.company.budgetDescriptors = result.budgetDescriptors;
            this.company.accrualsEnabled = result.accrualsEnabled;
            this.company.defaultAccrualAdjustmentMethodId = this.options.defaultAccrualAdjustmentMethodId;
            this.company.useTopLevelCompanyCOA = this.options.useTopLevelCompanyCOA;
            this.company.accrualEconUnitTypeId = this.options.accrualEconUnitTypeId;
            this.company.accrualEconUnitDescriptorId = this.options.accrualEconUnitDescriptorId;
            this.company.accrualFileTypeId = this.options.accrualFileTypeId;
            this.company.accrualDescriptors = result.accrualDescriptors;
            this.company.excludeFromAccruals = this.options.excludeFromAccruals;
            this.company.accrualExportPropertyIdentificationId = result.accrualExportPropertyIdentificationId;
            this.company.accrualExportPropIdentDescriptorId = result.accrualExportPropIdentDescriptorId;
            this.company.requireClientApproval = result.requireClientApproval;
            this.company.enableAllocations = result.enableAllocations;
            this.company.dataHubEnabled = result.dataHubEnabled;

            this.editMode = false;
        } finally {
            this.isLoading = false;
            this._navigationService.disableNavWarning();
        }
    }

    expandOrCollapse() {
        if(this.editMode) {
            return;
        }

        this.isShowSettings = !this.isShowSettings;

        if(this.isShowSettings) {
            this.showSettings();
        }
    }

    async showSettings(): Promise<void> {
        this.isLoading = true;

        const results = await Promise.all([
            this._companyService.getCompanyVendorCodesCount(this.companyId),
            this._companyService.getCompanyYears(this.companyId),
            this._companyService.getStatesWithSites(this.companyId, true, false),
            this._dxpService.getDXPMode(),
            this.isTopLevelCompany ? this._dxpService.IsSyncDxpLocked(this.companyId) : Promise.resolve(false),
            this._companyService.checkAccrualsEnabled()
        ]);

        // Company vendor code count
        this.company.vendorCodeCount = results[0];

        // Company years
        let companyYears = results[1];
        const currentYear = new Date().getFullYear();

        if (!companyYears)
        {
            companyYears = [];
        }
        else if (companyYears.length === 0){
            companyYears.push(currentYear);
        }

        const startingYear = companyYears.reduce((acc, x) => !acc || x < acc ? x : acc) - 5;

        this.yearToAdd = currentYear;
        this.yearsToAdd = Array
            .from({ length: companyYears.length + 10 }, (x, i) => i + startingYear)
            .sort()
            .reverse();

        // States with sites
        this.companyStates = results[2] || [];

        // DXP mode
        this.dxpSyncDisabled = results[3] === Core.DXPSyncModes.Disabled;

        // Sync DXP locked
        this.syncDxpLocked = results[4];

        // Check Accruals Enabled
        this.accrualsEnabled = results[5];

        this.isLoading = false;
    }

    startAddYear(): void {
        this.addingYear = true;
    }

    cancelAddYear(): void {
        this.addingYear = false;
    }

    async addYear(year): Promise<void> {
        // Exit if the user said they wanted to select states but has nothing selected
        if (this.selectingState && this.companyStateModel.stateNames.length < 1) {
            return;
        }
        let message = 'Confirm adding the given year to all the active parcels under the company?';
        if (this.selectingState) {
            message = 'Confirm adding the given year to all the active parcels under the company for the selected states?';
        }
        const result = await this._messageBoxService.open({
            title: 'Add Year',
            message: message,
            buttons: MessageBoxButtons.YesNo
        });

        if (result === MessageBoxResult.Yes) {
            const stateSummary = await this._statesService.getSummary();

            let selectedStates;
            if (this.selectingState) {
                selectedStates = stateSummary
                    .filter((st) => this.companyStateModel.stateNames.indexOf(st.abbr) >= 0)
                    .map((st) => st.stateID);
            }

            try {
                const lrp = await this._companyService.addYear(this.companyId, year, selectedStates);
                this._snackBarService.addById(lrp.longRunningProcessId, Compliance.LongRunningProcessTypeEnum.AddYearToCompanyPortfolio);
            } catch(error) {
                // If we get a forbidden error, just let default handling happen
                if (error.status !== 403) {
                    this._toastr.error('An error occurred.');
                }
                console.warn(error);
            } finally {
                this.addingYear = false;
            }
        }
    }

    invoiceAppealSavingsOverridden(){
        return this.companyId !== this.company.topLevelCompanyId
            && (!this.editMode && this.company.invoiceAppealSavings !== this.company.topLevelCompanyInvoiceAppealSavings
                || this.editMode && this.options.invoiceAppealSavingsFlag !== this.company.topLevelCompanyInvoiceAppealSavings);
    }

    requireClientApprovalOverridden(){
        return this.companyId !== this.company.topLevelCompanyId
            && (!this.editMode && this.company.requireClientApproval !== this.company.topLevelCompanyRequireClientApproval
                || this.editMode && this.options.requireClientApprovalFlag !== this.company.topLevelCompanyRequireClientApproval);
    }

    toggleSelectingState(): void {
        if (!this.editMode) { return; }
        this.selectingState = !this.selectingState;
    }

    descriptorSelected(descriptor) {
        this.options.accrualEconUnitDescriptorId = descriptor.descriptorId;
    }

    async showContracts() {
        const params: CompanyContractsModalParams = {company: this.company};
        await this._modalService.showAsync(CompanyContractsModalComponent, params, 'modal-lg');
    }

    budgetEnabledChanged( budgetEnabled: boolean): void {
        if(budgetEnabled && !this.inUseDescriptors) {
            this._getInUseDescriptors();
        }
    }

    accrualsEnabledChanged(accrualsEnabled: boolean): void {
        if(accrualsEnabled && !this.inUseDescriptors) {
            this._getInUseDescriptors();
        }
    }

    exportPropertyChanged(id: Core.AccrualExportPropertyIdentificationEnum): void {
        if(id !== this.options.accrualExportPropertyIdentificationId) {
            this.options.accrualExportPropIdentDescriptorId = null;
        }

        this.options.accrualExportPropertyIdentificationId = id;
    }

    enableAllocationsOverridden(): boolean {
        return this.companyId !== this.company.topLevelCompanyId
            && (!this.editMode && this.company.enableAllocations !== this.company.topLevelCompanyEnableAllocations
                || this.editMode && this.options.enableAllocations !== this.company.topLevelCompanyEnableAllocations);
    }

    get canChangeUseTopLevelCompanyCOA(): boolean {
        return this.company.topLevelCompanyId === this.company.companyID ||
            !this.company.hasIncomeStatements;
    }

    get useTopLevelCompanyCOATitle(): string {
        let result: string;

        if (this.editMode && !this.canChangeUseTopLevelCompanyCOA) {
            result = this.company.useTopLevelCompanyCOA
                ? 'Income Statements exist that use the Top-Level Company Chart of Accounts. Income Statements must be deleted in order to proceed.'
                : 'Income Statements exist that use the Subsidiary Chart of Accounts. Income Statements must be deleted in order to proceed.';
        }

        return result;
    }

    private async _getInUseDescriptors(): Promise<void> {
        this.flatInUseDescriptors = await this._companyService.getInUseCharacteristics(this.companyId, true);
        this.inUseDescriptors = _.chain(this.flatInUseDescriptors)
            .cloneDeep()
            .filter(x => _.includes([Core.DescriptorFieldTypes.Number, Core.DescriptorFieldTypes.Text], x.descriptorFieldTypeId))
            .sortBy('descriptorName')
            .groupBy(x => EntityTypeIds[x.entityTypeId])
            .value();

        if(this.inUseDescriptors.SITE) {
            this.exportProperties.push({ name: 'Site Characteristic', value: Core.AccrualExportPropertyIdentificationEnum.SiteCharacteristic});
        }
        if(this.inUseDescriptors.PARCEL) {
            this.exportProperties.push({ name: 'Parcel Characteristic', value: Core.AccrualExportPropertyIdentificationEnum.ParcelCharacteristic});
        }
    }
}
