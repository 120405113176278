<ws-modal-wrapper [headerLabel]="title"
                  (save)="applyAll()"
                  (cancel)="cancel()"
                  acceptLabel="Apply All"
                  [cancelLabel]="params.disableApplyAll ? 'Close' : 'Cancel'"
                  [disableSave]="params.disableApplyAll"
                  [useAceStyles]="true">
    <div>
        <h3>Appeal Deadline: {{appealDeadline | date:'M/d/yyyy':'utc'}}</h3>
        <table class="table" style="margin-top: 15px;">
            <thead>
                <th *ngFor="let col of tableColumns" class="px-4 border-bottom ws-vertical-align-middle">
                    <span class="m-0 p-0">{{ col.name }}</span>
                </th>
                <th class="border-bottom"></th>
            </thead>
            <tbody>
                <tr *ngFor="let row of rows; index as i" (mouseenter)="hoveredRow = row" (mouseleave)="hoveredRow = null">
                    <td class="px-4 py-0 border-0 ws-vertical-align-middle clickable">
                        <div class="cell-contents d-flex align-items-center">
                            {{row.type}}
                        </div>
                    </td>
                    <td class="px-4 py-0 border-0 ws-vertical-align-middle clickable">
                        <div class="cell-contents d-flex align-items-center">
                            {{row.name}}
                        </div>
                    </td>
                    <td class="px-4 py-0 border-0 ws-vertical-align-middle clickable">
                        <div class="cell-contents d-flex align-items-center">
                            {{row.av | currency:'USD'}}
                        </div>
                    </td>
                    <td class="px-4 py-0 border-0 ws-vertical-align-middle clickable">
                        <div class="cell-contents d-flex align-items-center">
                            {{row.ratio}}
                        </div>
                    </td>
                    <td class="px-4 py-0 border-0 ws-vertical-align-middle clickable">
                        <div class="cell-contents d-flex align-items-center">
                            {{row.fmv | currency:'USD'}}
                        </div>
                    </td>
                    <td class="px-4 py-0 border-0 ws-vertical-align-middle clickable">
                        <div class="cell-contents d-flex align-items-center">
                            <!-- total -->
                        </div>
                    </td>
                    <!-- <td class="px-4 py-0 border-0">
                        <div class="row-actions">
                            <ws-ace-button *ngIf="row.canFavorite"
                                        [iconFont]="row.isFavorite ? 'fa-solid fa-star' : 'fa-regular fa-star'"
                                        buttonStyle="text"
                                        state="action"
                                        size="small"
                                        [isDisabled]="hoveredRow !== row"
                                        (buttonClick)="toggleFavorite(row)">
                            </ws-ace-button>
                            <ws-ace-dropdown *ngIf="hasMenuOptions(selectedCategory, i, row)"
                                            class="d-inline"
                                            [icon]="(!loadingRowMenu ? 'more-outline' : 'spinner')"
                                            size="small"
                                            [isLoading]="loadingRowMenu"
                                            [isDisabled]="hoveredRow !== row || loadingRowMenu"
                                            (openChange)="getMenuOptions($event, selectedCategory, i)">
                                <ng-container *ngFor="let option of rowMenuOptions">
                                    <button *ngIf="!option.isDisabled(row)"
                                            type="button"
                                            class="option"
                                            (click)="option.onClick(row, selectedCategory.categoryType)">
                                        <span class="content">{{option.name}}</span>
                                    </button>
                                </ng-container>
                            </ws-ace-dropdown>
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</ws-modal-wrapper>
