<div class="modal-wrapper" draggable dragHandle=".drag-header" dragTarget=".modal-content"
     [dragTargetDirection]="searchDirection.Up">
    <div class="modal-header" [ngClass]="{'ace-modal-header': useAceStyles, 'drag-header': !useAceStyles}">
        <div *ngIf="useAceStyles && !hideCancelX" class="ace-modal-close-container">
            <div class="drag-header"></div>
            <div class="ace-modal-close" (click)="cancel.emit(false)">
                <span class="ace-modal-close-text">Close</span>
                <img class="ace-modal-close-icon" src="/images/Union.svg" alt="">
            </div>
        </div>
        <div class="header-contents ws-flex-container-horizontal ws-flex-align-items-center ws-stretch-width"
             [ngClass]="{'ace-modal-header-contents': useAceStyles}">
            <div class="ws-flex-container-vertical" [ngClass]="{'ace-modal-title-container': useAceStyles}">
                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <i *ngIf="headerIcon"
                       class="header-icon fa"
                       [ngClass]="headerIcon"
                       helpTooltip
                       [helpContentId]="headerIconHelpContentId"
                       [helpDisabled]="!headerIconHelpContentId">
                    </i>
                    <h4 *ngIf="headerLabel"
                        helpTooltip
                        [helpContentId]="headerHelpContentId"
                        [helpDisabled]="!headerHelpContentId"
                        class="modal-title float-start"
                        [ngClass]="{ 'clickable ace-btn btn-link': isHeaderLink, 'ace-modal-title': useAceStyles }"
                        (click)="headerClicked()">
                        {{ headerLabel }}
                    </h4>
                </div>
                <span *ngIf="subHeaderLabel" class="modal-header-subtext ws-text-align-left">{{ subHeaderLabel }}</span>
            </div>
            <span *ngIf="!useAceStyles" class="float-end">
                <button *ngIf="!hideCancelX" type="button" class="btn-close" aria-label="Close"
                        (click)="cancel.emit(false)"></button>
            </span>
        </div>
    </div>
    <div class="modal-body modal-wrapper-body exclude-modal-styling"
         [ngClass]="{ 'exclude-modal-styling-border': excludeModalStyling, 'ace-modal-body': useAceStyles }">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer" *ngIf="!hideFooter">
        <div class="ws-flex-container-horizontal ws-stretch-width"
             [ngClass]="{ 'ws-flex-justify-content-space-between': footerLabel || auxButtonLabel, 'ws-flex-justify-content-flex-end': !footerLabel, 'ws-flex-justify-content-center': !footerLabel && useAceStyles }">
            <div *ngIf="auxButtonLabel">
                <div helpTooltip [helpContentId]="auxButtonHelpContentId">
                    <button type="button"
                            class="ace-btn"
                            [ngClass]="auxButtonClass"
                            *ngIf="!hideAuxButton"
                            [disabled]="disableAuxButton"
                            (click)="auxButton.emit()"
                            [attr.tabindex]="(disableSave ? tabindex + 1 : tabindex + 2)">
                        {{ auxButtonLabel }}
                    </button>
                </div>
            </div>
            <div *ngIf="footerLabel">
                <span class="description" [attr.title]="footerLabel">
                    {{ footerLabel }}
                </span>
            </div>
            <div *ngIf="!useAceStyles" class="ws-flex-container-horizontal ws-flex-align-items-center">
                <div helpTooltip [helpContentId]="acceptHelpContentId">
                    <button type="button"
                            class="ace-btn has-text"
                            [ngClass]="acceptClass"
                            *ngIf="!hideSave"
                            [disabled]="disableSave"
                            (click)="save.emit()"
                            [attr.tabindex]="(!disableSave && tabindex)">
                        {{ acceptLabel }}
                    </button>
                </div>
                <div helpTooltip [helpContentId]="cancelHelpContentId">
                    <button type="button"
                            class="ace-btn"
                            [ngClass]="cancelClass"
                            *ngIf="!hideCancel"
                            [disabled]="disableCancel"
                            (click)="cancel.emit(false)"
                            [attr.tabindex]="(disableSave ? tabindex : tabindex + 1)">
                        {{ cancelLabel }}
                    </button>
                </div>
            </div>
            <div *ngIf="useAceStyles" class="d-flex button-con flex-row-reverse justify-content-center">
                <ws-ace-button *ngIf="!hideSave"
                               class="mx-2"
                               state="action"
                               buttonStyle="primary"
                               [content]="acceptLabel"
                               [isDisabled]="disableSave"
                               (buttonClick)="save.emit()"
                               [attr.tabindex]="(!disableCancel ? tabindex : tabindex + 1)"
                               helpTooltip
                               [helpContentId]="acceptHelpContentId">
                </ws-ace-button>
                <ws-ace-button *ngIf="!hideCancel"
                               class="mx-2"
                               state="action"
                               buttonStyle="secondary"
                               [content]="cancelLabel"
                               [isDisabled]="disableCancel"
                               (buttonClick)="cancel.emit(false)"
                               [attr.tabindex]="(!disableCancel && tabindex)"
                               helpTooltip
                               [helpContentId]="cancelHelpContentId">
                </ws-ace-button>
            </div>
        </div>
    </div>
</div>
