<div class="mb-4 input-group">
    <input type="text" [(ngModel)]="selectedDescriptorName" [typeaheadAsync]="true" [typeahead]="dataSource"
        (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadScrollable]="true"
        [typeaheadOptionsInScrollableView]="10" placeholder="Add Characteristic"
        (typeaheadOnSelect)="descriptorSelected($event.item)" [typeaheadMinLength]="2"
        typeaheadOptionField="typeaheadField" [typeaheadOptionsLimit]="20" [disabled]="disabled"
        class="form-control">
    <i class="fa fa-spinner fa-pulse form-control-feedback" *ngIf="typeaheadLoading"></i>
    <button type="button" class="ace-btn has-icon btn-secondary" (click)="this.openBrowseModal(browseCharacteristicsModal)" [disabled]="disabled">
        <i class="fa fa-binoculars"></i>
    </button>
</div>

<ng-template #browseCharacteristicsModal>
    <div class="modal-header">
        <h4 class="modal-title">Browse Characteristics</h4>
    </div>
    <div class="modal-body exclude-modal-styling">
        <div *ngIf="!descriptorCategories" class="text-center">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <tabset *ngIf="descriptorCategories" class="column-picker-tabs">
            <tab *ngFor="let category of descriptorCategories">
                <ng-template tabHeading>
                    {{category.name}}<span class="badge" *ngIf="category.checkedCount" style="margin-left: 5px;">{{category.checkedCount}}</span>
                </ng-template>
                <div style="display: flex; flex-flow: column wrap; height: 500px; overflow-x: auto; margin: 0 15px;">
                    <div *ngFor="let descriptor of category.descriptors" class="checkbox" style="margin-top: 0; margin-right: 15px;">
                        <label>
                            <input type="checkbox" [checked]="isDescriptorChecked(descriptor)" (change)="descriptorClicked(descriptor, category, $event)"> {{descriptor.name}}
                        </label>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>
    <div class="modal-footer">
        <div style="display: flex; justify-content: space-between;">
            <button class="ace-btn btn-secondary mx-1" type="button" (click)="clearSelection()">Clear Selection</button>
            <div>
                <button class="ace-btn btn-primary mx-1" type="button" (click)="addDescriptors()"
                    [disabled]="!descriptorsToAdd.length">
                    Add <span *ngIf="descriptorsToAdd.length">({{descriptorsToAdd.length}})</span>
                </button>
                <button class="ace-btn btn-secondary mx-1" type="button" (click)="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</ng-template>
