<div class="card panel-flat-color secondary-panel annual-details">
    <div class="panel-flat-header">
        <div class="header-icon">
            <i class="fa fa-calendar fa-fw" (click)="openParcelActivityModal()" style="cursor: pointer;"></i>
        </div>
        <h3>Annual Details</h3>
        <div class="header-button-container" style="display: inline-block; margin-left: 20px; vertical-align:top;">
            <add-year-modal [yearsList]="yearList" [navigationState]="navigationState" [parcelId]="parcelId" *ngIf="navigationState?.hasWritePermission && !navigationState?.editMode"></add-year-modal>
            <assessment-taxes-details-icon style="margin-left: 10px;" [parcelId]="parcelId" [selectableYears]="vanillaYears" [analyticsCategory]="'parcel'"></assessment-taxes-details-icon>
        </div>
        <div class="float-end header-button-container">
            <span *ngIf="navigationState?.viewTarget !== ViewTarget.grid && !navigationState?.editMode">
                <button class="ace-btn btn-text has-icon"
                        tooltip="Back to Years"
                        container="body"
                        (click)="navigationState.goToGrid()" style="margin-top:-1px; height: 24px; vertical-align: top;">
                    <i class="fa fa-arrow-left"></i>
                </button>
            </span>
            <span *ngIf="navigationState?.hasWritePermission && navigationState?.showEditControls">
                <span *ngIf="!navigationState.editMode">
                    <button class="ace-btn btn-text has-icon btn-primary" (click)="toggleEdit()">
                        <i class="fa fa-pencil" title="Edit"></i>
                    </button>
                </span>
                <span *ngIf="navigationState?.editMode">
                    <button class="ace-btn btn-text has-icon btn-primary btn-success"
                            (click)="saveEdit()"
                            tooltip="{{this.navigationState.validationMessage}}"
                            placement="left"
                            triggers="blur"
                            container="body"
                            [disabled]="!annualDetailsSaveAllowed"
                            #aDValidation="bs-tooltip">
                        <i class="fa fa-save" title="Save"></i>
                    </button>
                    <button class="ace-btn btn-text has-icon btn-primary btn-danger" (click)="cancelEdit()">
                        <i class="fa fa-close" title="Cancel"></i>
                    </button>
                </span>
            </span>
            <button class="ace-btn btn-text has-icon btn-secondary" *ngIf="!maximize" (click)="maximize_click()">
                <i class="fa fa-arrows-alt" title="Expand"></i>
            </button>
            <button class="ace-btn btn-text has-icon btn-secondary" *ngIf="maximize" (click)="unmaximize_click()">
                <i class="fa fa-compress" title="Collapse"></i>
            </button>
        </div>
        <div class="clearfix"></div>
    </div>
    <!--<div class="panel-flat-body" [class.x-scroll]="!active.year" loading-spinner waiting-for="years" style="margin-top:-8px; height: 348px;">-->
    <div class="panel-flat-body" *ngIf="navigationState" [ngSwitch]="navigationState.viewTarget" style="padding-bottom: 0;">
        <div class="col-lg-12" *ngSwitchCase="ViewTarget.grid">
            <annual-details-grid [navigationState]="navigationState"
                [components]="components"
                [years]="years"
                [componentTypes]="componentTypes"
                [filings]="filings"
                [filingTypes]="filingTypes"
                [hasComplianceResponsibility]="hasComplianceResponsibility"
                [hidden]="navigationState.entityLoading"
                [budgetsEnabled]="budgetsEnabled"
                [consolidatingTypeId]="consolidatingTypeId">
            </annual-details-grid>
            <div class="button-container" style="margin-top: 30px;" [hidden]="navigationState.entityLoading">
                <button class="ace-btn btn-primary show-all-years" (click)="getYearData(true)" *ngIf="this.totalYears > years?.length">
                    Show All Years
                </button>
            </div>
        </div>

        <div class="text-center" style="margin-top: 50px;" [hidden]="!navigationState.entityLoading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>

        <div [hidden]="navigationState.viewTarget === ViewTarget.grid || navigationState.entityLoading" style="display:flex;">

            <div *ngIf="navigationState.viewTarget !== ViewTarget.grid" style="flex: 0 0 300px; margin-right: 30px;">
                <annual-year [navigationState]="navigationState"
                             [currentYear]="navigationState.currentYear"
                             [currentAnnualDetailAssessment]="navigationState.currentRevision"
                             [componentTypes]="componentTypes"
                             [budgetsEnabled]="budgetsEnabled"
                             [consolidatingTypeId]="consolidatingTypeId"
                             [filingTypes]="filingTypes">
                </annual-year>
            </div>

            <div *ngSwitchCase="ViewTarget.appeal" style="flex: 1">
                <appeal [viewModel]="navigationState.currentAppealVM"
                        [currentYear]="navigationState.currentYear"
                        [currentRevision]="navigationState.currentRevision"
                        [assessmentNavigateHandler]="goToAssessment"
                        [editState]="navigationState">
                </appeal>
            </div>
            <div *ngSwitchCase="ViewTarget.tax" style="flex: 1">
                <taxes [viewModel]="navigationState.currentTaxesVM"
                       [currentYear]="navigationState.currentYear"
                       [editState]="navigationState"
                       [hasCompanyCollectors]="hasCompanyCollectors"
                       [gridNavigationHandler]="goToGrid"
                       [parcelID]="parcelId"
                       (AnnualDetailsSaveAllowedChangedX)="annualDetailsSaveAllowedChanged($event)"
                       >
                </taxes>
            </div>

            <div *ngSwitchCase="ViewTarget.assessment" style="flex: 1">
                <assessment [(viewModel)]="navigationState.currentAssessmentVM"
                            [currentYear]="navigationState.currentYear"
                            [componentTypes]="componentTypes"
                            [editState]="navigationState"
                            [propertyType]="propertyType"
                            [companyId]="companyId"
                            [gridNavigationHandler]="goToGrid"
                            (AssessmentChanged)="assessmentModelChange($event)">
                </assessment>
            </div>
            <div *ngSwitchCase="ViewTarget.compliance" style="flex: 1">
                <compliance [viewModel]="navigationState.currentComplianceVM"
                            [currentYear]="navigationState.currentYear"
                            [editState]="navigationState"
                            [companyId]="companyId"
                            [topLevelCompanyId]="topLevelCompanyId"
                            [returnPreparationAllowed]="returnPreparationAllowed"
                            [filingTypes]="filingTypes">
                </compliance>
            </div>
            <div *ngSwitchCase="ViewTarget.budget" style="flex: 1">
                <annual-budget [viewModel]="navigationState.currentAnnualBudgetVM"
                            [currentYear]="navigationState.currentYear"
                            [editState]="navigationState">
                </annual-budget>
            </div>
        </div>
    </div>
    <div class="panel-flat-body" *ngIf="!navigationState">
        <div class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
    </div>
</div>
