import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientServiceResponsibilityService } from '../../clientServiceResponsibility.service';
import { DeliveryDetailFeedSpecification, FeedSpecification } from '../../clientServices.model';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { cloneDeep, reject, remove, some, sortBy } from 'lodash';

@Component({
    selector: 'delivery-details-feed-specs',
    templateUrl: 'deliveryDetailsFeedSpecs.component.html'
})

export class deliveryDetailsFeedSpecsComponent implements OnInit {
   constructor(
           private readonly _clientServicesService: ClientServiceResponsibilityService
       ) { }

    @Input() editMode: boolean;
    @Input() feedSpecifications: DeliveryDetailFeedSpecification[];
    @Input() clientAbbreviation: string;
    @Input() entity: CompanyEntity;
    @Input() isBillPay: boolean;
    @Input() dataHubEnabled: boolean;
    @Input() shouldDisplayDataHub: boolean;

    @Output() feedSpecificationsChange: EventEmitter<DeliveryDetailFeedSpecification[]> = new EventEmitter<DeliveryDetailFeedSpecification[]>();
    @Output() clientAbbreviationChange: EventEmitter<string> = new EventEmitter<string>();

    availableFeedSpecs: FeedSpecification[];
    hoveredSpecId: number;
    chosenSpec: FeedSpecification;
    disableSave: boolean = false;
    loading: boolean = false;

    async ngOnInit() {
        this.loading = true;

        try {
            const availableFeedSpecs = await this._clientServicesService.getAvailableFeedSpecs(this.entity.typeId, this.entity.id);
            this.availableFeedSpecs = reject(availableFeedSpecs, spec => {
                return some(this.feedSpecifications, { feedSpecificationID: spec.feedSpecificationID });
            });

            this._sortAvailableFeedSpecs();

        if (this.isBillPay)
            {
            if (this.feedSpecifications.length < 1)
                this.disableSave = true;
            }

        } finally {
            this.loading = false;
        }
    }

    getFeedApplicability(spec: DeliveryDetailFeedSpecification): string {
        let feedApplicability: string = '';

        if (spec.feedSpecification.apFeed) {
            feedApplicability += 'AP';
        }

        if (spec.feedSpecification.checkFeed) {
            if (feedApplicability !== '') {
                feedApplicability += ', ';
            }
            feedApplicability += 'Check';
        }

        if (this.shouldDisplayDataHub &&
            spec.feedSpecification.dataHub) {
            if (feedApplicability !== '') {
                feedApplicability += ', ';
            }
            feedApplicability += 'Data Hub';
        }

        return feedApplicability;
    }

    addSpec(): void {
        const specToadd = {
            feedSpecification: cloneDeep(this.chosenSpec),
            feedSpecificationID: this.chosenSpec.feedSpecificationID,
            includedInEmail: false,
        } as DeliveryDetailFeedSpecification;

        this.feedSpecifications.push(specToadd);
        this.feedSpecificationsChange.emit(this.feedSpecifications);
        remove(this.availableFeedSpecs, this.chosenSpec);
        this.disableSave = false;

        setTimeout(() => {
            this.chosenSpec = undefined;
        });
    }

    removeSpec(spec: DeliveryDetailFeedSpecification) : void{
        this.availableFeedSpecs.push(spec.feedSpecification);
        this._sortAvailableFeedSpecs();

        remove(this.feedSpecifications, spec);
        this.feedSpecificationsChange.emit(this.feedSpecifications);

        if (this.isBillPay) {
            if (this.feedSpecifications.length < 1)
                this.disableSave = true;
        }
    }

    private _sortAvailableFeedSpecs() {
        this.availableFeedSpecs = sortBy(this.availableFeedSpecs, 'name');
    }
}