import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { Observable, Subject } from 'rxjs';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { RestrictService } from '../../Common/Permissions/restrict.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { ReportManagerService } from '../../Reporting/Manager/report.manager.service';
import { AdvancedSearchPersistenceService } from '../../Search/Advanced/advancedSearchPersistence.service';
import { SmartSearchService } from '../../Search/Advanced/smartSearch.service';
import { ActionViewService } from '../../Task/ActionView/action-view-service.upgrade';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { SavedActionViewService } from '../Saved-AV/savedActionView.service';
import { SavedReportService } from '../Saved-Report/savedReport.service';
import { SavedSmartService } from '../Saved-Smart/savedSmart.service';
import { UserSavePage } from './userSavePage.model';

export enum SearchPageType {
    Smart = 0,
    AV = 1,
    Report = 2
}

export enum SearchCategoryType {
    System = 'system',
    User = 'user',
    Favorite = 'favorite'
}

type SavePages = {
    [key in SearchPageType]?: UserSavePage<any>;
};

@Injectable({ providedIn: 'root' })
export class UserSaveService {
    constructor(private readonly _smartSearchService: SmartSearchService,
                private readonly _actionViewService: ActionViewService,
                private readonly _reportManagerService: ReportManagerService,
                private readonly _messageModalService: MessageModalService,
                private readonly _modalService: WeissmanModalService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService,
                private readonly _router: UIRouter,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                protected readonly _restrictService: RestrictService) {
    }

    private _currentSavePage: UserSavePage<any>;

    private _currentUserSavePage: Subject<UserSavePage<any>> = new Subject<UserSavePage<any>>();
    private _currentUserSavePage$: Observable<UserSavePage<any>> = this._currentUserSavePage.asObservable();
    private _savePages: SavePages = {
        [SearchPageType.Smart]: new SavedSmartService(this._smartSearchService, this._messageModalService, this._modalService, this._advancedSearchPersistenceService, this._router, this._productAnalyticsService, this._restrictService),
        [SearchPageType.AV]: new SavedActionViewService(this._actionViewService, this._messageModalService, this._modalService, this._actionViewPersistenceService, this._router, this._productAnalyticsService, this._restrictService),
        [SearchPageType.Report]: new SavedReportService(this._reportManagerService, this._messageModalService, this._modalService, this._advancedSearchPersistenceService, this._router, this._restrictService)
    };

    get selectedUserSavePage$(): Observable<UserSavePage<any>> {
        return this._currentUserSavePage$;
    }

    getSavePage(type: SearchPageType): UserSavePage<any> {
        this._currentSavePage = this._savePages[type];
        return this._savePages[type];
    }

    async refreshCurrentList(): Promise<void> {
        this._currentSavePage.dirty = true;
        await this._currentSavePage.getList();
        this._currentUserSavePage.next(this._currentSavePage);
    }

    async updateList(type: SearchPageType): Promise<void> {
        this._currentSavePage = this._savePages[type];
        await this._currentSavePage.getList();
        this._currentUserSavePage.next(this._currentSavePage);
    }

    setDirty(type: SearchPageType): void {
        this._savePages[type].dirty = true;
    }
}
