<ws-modal-wrapper headerLabel="Duplicate Intake Item"
                  [disableCancel]="isLoading"
                  [disableSave]="isLoading || !duplicate.attachmentTypeID"
                  (cancel)="cancel()"
                  (save)="save()">
    <div class="document-processing-exception-modal">
        <loading-spinner [show]="isLoading" [size]="5"></loading-spinner>
        <div *ngIf="!isLoading" class="form-horizontal" style="margin: 0 30px;">
            <div class="mb-3">
                <label class="row-label">Document Type:</label>
                <select id="intake-document-type"
                        class="form-select form-select-sm"
                        name="documentType"
                        [(ngModel)]="duplicate.attachmentTypeID"
                        required>
                    <option *ngFor="let doc of documentTypeList | sortBy: 'asc': 'attachmentTypeName'"
                            [value]="doc.attachmentTypeID">
                        {{ doc.attachmentTypeName }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label class="row-label">Number of Copies:</label>
                <input type="number"
                       class="form-control"
                       [(ngModel)]="duplicate.duplicateCount"
                       (change)="checkCopyNumber()"
                       (blur)="resetCopyNumber()"/>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
