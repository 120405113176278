import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RevenueShare } from '../invoice.model';

export interface ICellRendererParamsForEngagementNumber extends ICellRendererParams {
    onClick: (params: ICellRendererParamsForEngagementNumber) => void;
    editMode: () => boolean;
}

@Component({
    selector: 'Renderer-cell',
    template: `
        <span *ngIf="revenueShare && !revenueShare.isTotal && params">
            <span>{{revenueShare.engagementNum}}</span>
            <button *ngIf="params.editMode()" class="ace-btn has-icon btn-primary" (click)="params.onClick(params)">...</button>
        </span>
    `,
})
export class AgGridEngagementNumberCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForEngagementNumber;
    revenueShare: RevenueShare;

    agInit(params: ICellRendererParamsForEngagementNumber): void {
        this.params = params;
        this.revenueShare = params.data as RevenueShare;
    }

    refresh() {
        return false;
    }
}
