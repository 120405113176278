import { UIRouter } from '@uirouter/angular';
import { AmplitudeEvent, ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AdvancedSearchPersistenceService } from '../../Search/Advanced/advancedSearchPersistence.service';
import { SmartSearchService } from '../../Search/Advanced/smartSearch.service';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { SearchCategoryComponent } from '../User-Save/Modals/Category/searchCategory.component';
import { SearchDefaultDisplayComponent } from '../User-Save/Modals/Default-Display/searchDefaultDisplay.component';
import { SearchRenameComponent } from '../User-Save/Modals/Rename/searchRename.component';
import { SearchCategoryType } from '../User-Save/userSave.service';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import {
    ContextMenuItem,
    SaveCategory,
    SaveListItem,
    SaveTabColumn, SaveTabColumnType,
    UserSavePage
} from '../User-Save/userSavePage.model';

export class SavedSmartService extends UserSavePage<Core.AdvancedSearchList> {
    constructor(private readonly _smartSearchService: SmartSearchService,
                private readonly _messageModalService: MessageModalService,
                private readonly _modalService: WeissmanModalService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
                private readonly _router: UIRouter,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _restrictService: RestrictService
    ) {
        super();
    }

    protected _columns: SaveTabColumn[] = [
        {
            name: 'Name',
            key: 'name',
            type: SaveTabColumnType.String
        },
        {
            name: 'Change Date',
            key: 'changeDate',
            type: SaveTabColumnType.Date
        }
    ];

    private _baseValues: Core.SavedSearchCategoryManagementDTO[] = [];
    private userHasEditSystemSearch: boolean = this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);

    get tabTitle(): string {
        return 'Saved Smarts';
    }

    contextMenu(category: SaveCategory<Core.AdvancedSearchList>, index: number): ContextMenuItem<Core.AdvancedSearchList>[] {
        return [
            {
                name: 'Share',
                onClick: (item, searchType) => {
                    this._shareSmart(item, searchType);
                },
                isDisabled: () => {
                    return this._currentSelectedFilter === SearchCategoryType.Favorite;
                }
            },
            {
                name: 'Rename',
                onClick: (item, searchType) => {
                    this._modalService.showAsync(SearchRenameComponent, { search: item.originalModel, searchType }, 'modal-lg');
                },
                isDisabled: (item: SaveListItem<Core.AdvancedSearchList>) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite
                        || (this._currentSelectedFilter === SearchCategoryType.System && !this.userHasEditSystemSearch);
                }
            },
            {
                name: 'Change Category',
                onClick: (item, searchType) => {
                    this._modalService.showAsync(SearchCategoryComponent, { search: item.originalModel, searchType }, 'modal-lg');
                },
                isDisabled: (item: SaveListItem<Core.AdvancedSearchList>) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite
                        || (this._currentSelectedFilter === SearchCategoryType.System && !this.userHasEditSystemSearch);
                }
            },
            {
                name: 'Change Default Display',
                onClick: (item, searchType) => {
                    this._modalService.showAsync(SearchDefaultDisplayComponent, { search: item.originalModel, searchType }, 'modal-lg');
                },
                isDisabled: (item: SaveListItem<Core.AdvancedSearchList>) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite
                        || (this._currentSelectedFilter === SearchCategoryType.System && !this.userHasEditSystemSearch);
                }
            },
            {
                name: 'Delete',
                onClick: async(item, searchType) => {
                    try {
                        await this._messageModalService.confirm(`Are you sure you want to delete ${  item.name  }?`);
                    } catch(e) {
                        return;
                    }

                    this.dirty = true;
                    await this._smartSearchService.deleteSearch(item.originalModel, searchType);
                    category.savedItemList.splice(index, 1);
                    this._advancedSearchPersistenceService.deleteAdvancedSearch(item.originalModel);
                    await this.getList();
                },
                isDisabled: (item: SaveListItem<Core.AdvancedSearchList>) => {
                    return item.originalModel.isSystemSeeded
                        || this._currentSelectedFilter === SearchCategoryType.Favorite
                        || (this._currentSelectedFilter === SearchCategoryType.System && !this.userHasEditSystemSearch);
                }
            }
        ];
    }

    async getList(): Promise<void> {
        if (!this._dirty) {
            return;
        }
        this._baseValues = await this._smartSearchService.getSearchCategories();

        const categoryList: SaveCategory<Core.AdvancedSearchList>[] = this._baseValues.map(x => {
            const list: SaveListItem<Core.AdvancedSearchList>[] = x.searchList.map(y => {
                return {
                    id: y.searchId,
                    name: y.searchName,
                    isFavorite: y.isFavorite,
                    changeDate: y.changeDate,
                    canFavorite: true,
                    originalModel: y,
                    amplitudeEvent: this._getAmplitudeEvent(x, y)
                };
            });
            return {
                categoryId: x.categoryId,
                categoryName: x.categoryName,
                categoryType: x.categoryType,
                savedItemList: list
            };
        });

        this._separateByCategoryType(categoryList);
    }

    navigateTo(listItem: SaveListItem<Core.AdvancedSearchList>, categoryType: number): void {
        this._advancedSearchPersistenceService.clearSearchData();
        const params = {
            searchId: listItem.id,
            searchType: categoryType
        };
        this._router.stateService.transitionTo('search', params);
    }

    async toggleFavorite(search: SaveListItem<Core.AdvancedSearchList>): Promise<void> {
        this.dirty = true;
        this._productAnalyticsService.logEvent('click-explore-favorite', { exploreFavoriteToggle: search.isFavorite ? 'on' : 'off' });
        await this._smartSearchService.toggleSearchFavorite(search.originalModel, search.isFavorite);
    }

    private async _shareSmart(searchItem: SaveListItem<Core.AdvancedSearchList>, searchType: Core.SearchCategoryType): Promise<void> {
        // Go update the search to mark it as "Shared" if this is a custom search
        if (searchType === Core.SearchCategoryType.Custom) {
            await this._smartSearchService.share(searchItem.originalModel);
        }
        await this.getList();
        this._advancedSearchPersistenceService.clearSearchData();
        const params = {
            searchId: searchItem.id,
            searchType: searchType
        };
        const route = this._router.stateService.href('search', params, { absolute: true });
        this._showEmailWindow(route, searchItem.name, 'SMART');
    }

    private _getAmplitudeEvent(category: Core.SavedSearchCategoryManagementDTO, search: Core.AdvancedSearchList): AmplitudeEvent {
        const event = `click-explore-${category.categoryType ? 'saved' : 'system' }`;
        let properties: { [key: string]: string } = { exploreSystemSmart: category.categoryName };
        if (search.isFavorite && this._currentSelectedFilter === SearchCategoryType.Favorite) {
            properties = { exploreSystemSmart: 'favorite' };
        }
        return { event, properties };
    }
}
