<span class="weissman-datetime-picker">
    <div *ngIf="!readOnly" #dtDateInput class="input-group flex-nowrap" [ngStyle]="{'width': (fillWidth) ? '100%' : null}">
        <input type="text"
               class="weiss-datepicker-input form-control exclude-modal-styling"
               helpTooltip
               [helpContentId]="helpContentId"
               position="bottom"
               [ngClass]="className"
               [class.invalid]="isInvalid || setInvalid"
               [class.overridden]="isOverridden"
               [class.ng-touched]="setAsTouched"
               [class.ng-invalid]="(required && !inputDate) || setInvalid"
               [disabled]="isDisabled"
               [required]="required"
               (blur)="onBlur()" />

            <button *ngIf="!isDisabled"
                    type="button"
                    class="ace-btn btn-secondary has-icon"
                    (click)="openDatepicker()">
                <i class="fa fa-calendar"></i>
            </button>

        <input *ngIf="!readOnly && !dateOnly" #dtTimeInput type="text"
               class="weiss-datepicker-time-input form-control exclude-modal-styling d-inline-block"
               [class.invalid]="isInvalid"
               [class.overridden]="isOverridden"
               [(ngModel)]="internalTime"
               [disabled]="isDisabled"
               placeholder="--:-- --" />
    </div>

    <span *ngIf="readOnly">{{displayDate}}</span>
</span>
