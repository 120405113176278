import { Component, OnInit, OnDestroy} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { ReturnService } from '../../../return.service';
import { ReturnOutputService } from '../returnOutput.service';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'return-output-printing-mailing',
    templateUrl: './returnOutputPrintingMailing.component.html'
})
export class ReturnOutputPrintingMailingComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnOutputService: ReturnOutputService
    ) { }

    private _destroy$: Subject<void> = new Subject();

    get returnOutputModel(): Compliance.ReturnOutputModel {
        return this._returnOutputService.sharedState && this._returnOutputService.sharedState.returnOutputModel;
    }

    ngOnInit(): void {
        this._returnOutputService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnOutputService.unsubscribeFromServiceActivationCycle(this);
        this._destroy$.next();
        this._destroy$.complete();
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnService.start$.pipe(takeUntil(this._destroy$)).subscribe(async () => {
            await this._returnOutputService.loadSharedState(this._returnService.filingBatchId);
        });
        await this._returnOutputService.loadSharedState(this._returnService.filingBatchId);
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
    }

    async onOptionChange(): Promise<void> {
        if (!this._returnOutputService.sharedState.returnOutputModel.includeReturns) {
            this._returnOutputService.sharedState.returnOutputModel.embedExcelReportsInPdf = false;
        }

        await this._returnOutputService.saveSharedState();
    }
}
