import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AttachmentService } from '../../../Attachment/attachment.service';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { ProcessingService } from '../../processing.service.upgrade';

export interface DuplicateIntakeItem {
    taskIDs: number[];
    duplicateCount: number;
    searchTimestamp: Date;
    attachmentTypeID?: number;
}

export interface DuplicateIntakeItemModalParams {
    isBulk: boolean;
    duplicateItem: DuplicateIntakeItem;
    optionalArgs: { parentCallsService: true }
}

@Component({
    selector: 'app-duplicate-intake-item-modal',
    templateUrl: './duplicateIntakeItemModal.component.html'
})
export class DuplicateIntakeItemModalComponent implements OnInit, IWeissmanModalComponent<DuplicateIntakeItemModalParams, any> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _attachmentService: AttachmentService,
                private readonly _processingService: ProcessingService) {
    }

    params: DuplicateIntakeItemModalParams;
    result: () => Promise<any>;

    documentTypeList: Core.AttachmentTypeModel[];
    isLoading: boolean = true;
    duplicate: DuplicateIntakeItem;

    async ngOnInit(): Promise<void> {
        this.duplicate = this.params.duplicateItem;
        this.documentTypeList = await this._attachmentService.getAttachmentTypes();
        this.isLoading = false;
    }

    resetCopyNumber(): void {
        if(!this.duplicate.duplicateCount || this.duplicate.duplicateCount <= 0) {
            this.duplicate.duplicateCount = 1;
        }
    }

    checkCopyNumber(): void {
        if(this.duplicate.duplicateCount && this.duplicate.duplicateCount.toString().length > 2) {
            let newCount = this.duplicate.duplicateCount.toString();
            newCount = newCount.substring(0, 2);

            this.duplicate.duplicateCount = parseInt(newCount);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        this.isLoading = true;
        if(this.params.isBulk) {
            if (this.params.optionalArgs.parentCallsService) {
                this.result = () => {
                    return this._processingService.createDuplicateIntakeItemBulk(this.duplicate);
                };
                this.cancel();
            }
            else {
                this.result = await this._processingService.createDuplicateIntakeItemBulk(this.duplicate);
                this.isLoading = false;
                this.cancel();
            }
        } else {
            const result = this._processingService.createDuplicateIntakeItem(this.duplicate);

            if (result) {
                this.result = () => result;
            }

            this.isLoading = false;
            this.cancel();
        }
    }
}
