import { Injectable } from '@angular/core';
import { ColDef, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { GridType } from 'angular-gridster2';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { AppealService } from '../../Annual-Details/Appeals/appeal.service';
import { AppealApplicationService } from '../../Appeal-Application/appeal.application.service';
import {
    AppealRecommendationTemplateModalService
} from '../../Appeal-Recommendation/Appeal-Recommendation-Template/appealRecommendationTemplateModal.service';
import { CommentsService } from '../../Comments/comments.service';
import { CommentsModalService } from '../../Comments/commentsModal.service';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { AgGridMultiSelectTracker } from '../../Compliance/AgGrid/MultiSelectTracker';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { ReassignModes, TaskActionViewContextOption } from '../../constants.new';
import { ActionViewRepository, InvoiceRepository } from '../../Core-Repositories';
import { ObtainPaymentReceiptTasksRequest } from '../../Core-Repositories/actionView.repository';
import {
    AddPaymentsToBatchModalService
} from '../../Payment-Batch/Add-Payments-To-Batch-Modal/addPaymentsToBatchModal.service';
import {
    ChangeDueDateModalComponent,
    ChangeDueDateModalParams
} from '../../Processing/change-due-date.modal.component';
import {
    CompleteFileAppealModalComponent, CompleteFileAppealModalParams
} from '../../Processing/Complete-File-Appeal-Modal/completeFileAppealModal.component';
import {
    DocumentProcessingExceptionModalComponent, DocumentProcessingExceptionModalParams
} from '../../Processing/Documents/Modals/documentProcessingExceptionModal.component';
import {
    DuplicateIntakeItem, DuplicateIntakeItemModalComponent,
    DuplicateIntakeItemModalParams
} from '../../Processing/Documents/Modals/duplicateIntakeItemModal.component';
import {
    ProcessAppealWarrantedComponent, ProcessAppealWarrantedParams
} from '../../Processing/Process-Appeal-Warranted-Modal/processAppealWarranted.component';
import {
    ProcessNoAppealComponent,
    ProcessNoAppealParams
} from '../../Processing/Process-No-Appeal-Modal/processNoAppeal.component';
import { ProcessingService } from '../../Processing/processing.service.upgrade';
import { RetrievalStatusModalService } from '../../Processing/RetrievalStatus/retrieval-status-modal.service';
import { TransmittalService } from '../../Processing/Transmittal/transmittal.service';
import { AppealListReportRequest, ReportingService } from '../../Reporting/reporting.service';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import {
    ReassignTaskModalComponent,
    ReassignTaskModalParams
} from '../Reassign-Task-Modal/reassignTaskModal.component';
import { TaskService } from '../task.service.upgrade';
import { ActionViewPersistenceService } from './Action.View.Persistence.Service.upgrade';

import * as _ from 'lodash';

export interface ActionViewContextMenuItem {
    id: TaskActionViewContextOption;
    limitMessage?: string | boolean;
    function: (taskIDs?: number[], instanceId?: number) => any;
}

@Injectable({ providedIn: 'root' })
export class ActionViewContextMenuService {
    constructor(private readonly _weissmanModalService: WeissmanModalService,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _toastr: ToastrService,
                private readonly _taskService: TaskService,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService,
                private readonly _appealService: AppealService,
                private readonly _appealApplicationService: AppealApplicationService,
                private readonly _transmittalService: TransmittalService,
                private readonly _commentsService: CommentsService,
                private readonly _commentsModalService: CommentsModalService,
                private readonly _processingService: ProcessingService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _retrievalStatusModalService: RetrievalStatusModalService,
                private readonly _reportingService: ReportingService,
                private readonly _appealRecommendationModalService: AppealRecommendationTemplateModalService,
                private readonly _addPaymentsToBatchModalService: AddPaymentsToBatchModalService,
                private readonly _actionViewRepository: ActionViewRepository,
                private readonly _invoiceRepository: InvoiceRepository,
                private readonly _navigationServiceHandler: UpgradeNavigationServiceHandler
    ) {
    }

    private _loadingSubject: Subject<string> = new Subject();
    private _loading$: Observable<string> = this._loadingSubject.asObservable();

    private _errors: string[] = [];
    private _warningLimit: number = 50;

    private readonly PERMISSION_ERROR_MESSAGE = 'User does not have permission on these items.';
    private readonly GENERIC_ERROR_MESSAGE = 'An error has occured processing these tasks.';
    private readonly CONCURRENCY_ERROR_MESSAGE = 'Items have been modified since the search was performed. Please refresh the search result.';

    get loading$(): Observable<string> {
        return this._loading$;
    }

    //Using this to open modals for now, but the rest of the old service should be migrated over

    async openProcessNoAppealModal(taskIDs: number[], searchTimestamp: Date): Promise<Core.BulkOperationResult[]> {
        const params: ProcessNoAppealParams = {
            taskIDs: taskIDs,
            searchTimestamp: searchTimestamp,
            updateAppealRecommendation: false
        };
        return this._weissmanModalService.showAsync(ProcessNoAppealComponent, params, 'modal-lg');
    }

    async openProcessAppealWarrantedModal(taskIDs: number[], searchTimestamp: Date): Promise<Core.BulkOperationResult[]> {
        const params: ProcessAppealWarrantedParams = {
            taskIDs: taskIDs,
            searchTimestamp: searchTimestamp,
            runWithBuffer: true,
            updateAppealRecommendation: false
        };
        return await this._weissmanModalService.showAsync(ProcessAppealWarrantedComponent, params, 'modal-lg');
    }

    async getContextMenu(grid: GridApi, gridTracker: AgGridMultiSelectTracker, gridOptions: GridOptions, searchTimestamp: Date): Promise<ActionViewContextMenuItem[]> {
        if (gridTracker.getSelectedRowsCount() > 0) {
            this._productAnalyticsService.logEvent('select-action-view-tasks', { avTasksSelected: gridTracker.getSelectedRowsCount() });
            const menuItems: ActionViewContextMenuItem[] = [{
                id: TaskActionViewContextOption.ProcessDocument,
                function: async () => {
                    await this._updateChangedGridRows(grid, gridTracker);
                    const selectedRows = await this._getSortedSelection(grid, gridTracker);
                    this._navigationServiceHandler.go('documentProcessing', {
                        selectedRows,
                        searchTimestamp: searchTimestamp
                    });
                }
            }, {
                id: TaskActionViewContextOption.ChangeContact,
                limitMessage: 'Are you sure you want to change contacts for #taskcount tasks?',
                function: async (taskIDs: number[], instanceId: number) => {
                    const params: ReassignTaskModalParams = {
                        taskIDs: taskIDs,
                        reassignMode: ReassignModes.ChangeContact,
                        searchTimestamp: searchTimestamp,
                        instanceId: instanceId
                    };
                    const result = await this._weissmanModalService.showAsync(ReassignTaskModalComponent, params, 'modal-lg');

                    if (result) {
                        await this._runModalResult(grid, gridTracker, result, 'Processing contact change...');
                    }
                }
            }, {
                id: TaskActionViewContextOption.Reassign,
                limitMessage: 'Are you sure you want to reassign #taskcount tasks?',
                function: async (taskIDs: number[], instanceId: number) => {
                    const params: ReassignTaskModalParams = {
                        taskIDs: taskIDs,
                        reassignMode: ReassignModes.Default,
                        searchTimestamp: searchTimestamp,
                        instanceId: instanceId
                    };
                    const result = await this._weissmanModalService.showAsync(ReassignTaskModalComponent, params, 'modal-lg');

                    if (result) {
                        await this._runModalResult(grid, gridTracker, result, 'Reassigning...');
                    }
                }
            }, {
                id: TaskActionViewContextOption.AddComment,
                function: async (taskIDs: number[]) => {
                    let title = 'Add comment to ';
                    if (gridTracker.getSelectedRowsCount() === 1) {
                        title += ' task';
                    } else {
                        title += `${gridTracker.getSelectedRowsCount()  } tasks`;
                    }
                    const entityData = await this._commentsService.getEntityDataForTasks(taskIDs);
                    const years = _.uniq(entityData.map(x => x.year));
                    let defaultYear = years[0];
                    const multipleYears = years.length > 1;
                    if (multipleYears) { defaultYear = '(multiple)'; }
                    const comment = await this._commentsModalService.openAddCommentModal({
                        title: title,
                        allowBlank: true,
                        yearIsRelevant: this._commentsService.yearIsRelevantForEntityTypeID(entityData[0].entityTypeID),
                        showFull: true,
                        defaultYear: defaultYear
                    });
                    if (comment) {
                        let entityYearOverrides = null;
                        if (multipleYears) {
                            entityYearOverrides = entityData.map(entity => {
                                return {
                                    entityID: entity.entityID,
                                    entityTypeID: entity.entityTypeID,
                                    year: entity.year
                                };
                            });
                            comment.year = null;
                        }
                        this._loadingSubject.next('Saving...');
                        try {
                            await this._commentsService.sendTaskComments(taskIDs, comment, entityYearOverrides);
                            // TODO: We should change the comment API endpoint to return bulk operation data; ideally,
                            // it would even include new fields so the UI can automatically update the comment column.
                            // I think that the next four lines would look like:
                            // .then(processResult);
                            this._loadingSubject.next(null);
                            this._toastr.success(`Comment${  gridTracker.getSelectedRowsCount() !== 1 ? 's' : ''  } added`);
                            this._updateChangedGridRows(grid, gridTracker);
                        } catch(err) {
                            this._loadingSubject.next(null);
                            this._toastr.error('An unexpected error has occurred');
                        }
                    }
                }
            }, {
                id: TaskActionViewContextOption.Duplicate,
                limitMessage: true,
                function: async (taskIDs: number[]) => {
                    const duplicateItem: DuplicateIntakeItem = {
                        taskIDs: taskIDs,
                        duplicateCount: 1,
                        searchTimestamp: searchTimestamp
                    };
                    const params: DuplicateIntakeItemModalParams = {
                        isBulk: true,
                        duplicateItem: duplicateItem,
                        optionalArgs: { parentCallsService: true }
                    };
                    const result = await this._weissmanModalService.showAsync(DuplicateIntakeItemModalComponent, params, 'modal-lg');

                    if (result) {
                        await this._runModalResult(grid, gridTracker, result, 'Duplicating...');
                    }
                }
            }, {
                id: TaskActionViewContextOption.AppealWarranted,
                limitMessage: true,
                function: async (taskIDs: number[]) => {
                    let result = await this.openProcessAppealWarrantedModal(taskIDs, searchTimestamp);
                    if (result){
                        result = this._correctForAsyncError(result);
                        await this._processAddPaymentsToBatchResult(grid, gridTracker, result);
                    }
                }
            }, {
                id: TaskActionViewContextOption.AppealNotWarranted,
                limitMessage: true,
                function: async (taskIDs: number[]) => {
                    let result = await this.openProcessNoAppealModal(taskIDs, searchTimestamp);
                    if (result) {
                        result = this._correctForAsyncError(result);
                        await this._processAddPaymentsToBatchResult(grid, gridTracker, result);
                    }
                }
            }, {
                id: TaskActionViewContextOption.CompleteFileAppeal,
                limitMessage: true,
                function: async (taskIDs: number[]) => {
                    const params: CompleteFileAppealModalParams = {
                        taskIDs: taskIDs,
                        searchTimestamp: searchTimestamp,
                        optionalArgs: { parentCallsService: true }
                    };
                    const result = await this._weissmanModalService.showAsync(CompleteFileAppealModalComponent, params, 'modal-lg');
                    if (result) {
                        await this._runModalResult(grid, gridTracker, result, 'Processing...');
                    }
                }
            }, {
                id: TaskActionViewContextOption.DocumentException,
                limitMessage: true,
                function: async (taskIDs: number[], instanceId: number) => {
                    let isTaxBill = true;
                    grid.getModel().forEachNode(x => {
                        if (gridTracker.isRowSelected(x.data.n10908)) {
                            isTaxBill = isTaxBill && x.data.f9003 === 'Tax Bill';
                        }
                    });

                    const params: DocumentProcessingExceptionModalParams = {
                        exceptionData: { taskIDs: taskIDs },
                        searchTimestamp: searchTimestamp,
                        optionalArgs: { parentCallsService: true, isTaxBill: isTaxBill },
                        instanceId: instanceId
                    };
                    const result = await this._weissmanModalService.showAsync(DocumentProcessingExceptionModalComponent, params, 'modal-lg');

                    if (result) {
                        await this._runModalResult(grid, gridTracker, result, 'Processing...');
                    }
                }
            },
                { id: TaskActionViewContextOption.ChangeAppealDeadline, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 9) },
                { id: TaskActionViewContextOption.ChangeFilingDeadline, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 10) },
                {
                    id: TaskActionViewContextOption.AddPrepareApplicationTasks,
                    limitMessage: 'Are you sure you want to add up to #taskcount tasks?',
                    function: async (taskIDs: number[]) => {
                        this._loadingSubject.next('Adding prepare application tasks...');
                        try {
                            const result = await this._appealService.addPrepareApplicationTasks(taskIDs);
                            await this._processResult(grid, gridTracker, result);
                        } finally {
                            this._loadingSubject.next(null);
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.CreatePreviewAppealApplication,
                    function: async (taskIDs: number[]) => {
                        this._loadingSubject.next('Loading appeal applications...');
                        let searchResult = await this._appealApplicationService.searchByTaskIds(taskIDs);
                        const newKey = (new Date()).getTime();

                        let errorResults = searchResult.filter(r => r.errorMessage);
                        searchResult = searchResult.filter(r => !r.errorMessage);

                        const taskIdsNeedingApplications = searchResult
                            .filter(r => !r.applicationExists)
                            .map(r =>  r.taskID);

                        const appealIds = searchResult.map(r => r.appealId);

                        const callback = () => {
                            sessionStorage[`AppealApplicationBatch${  newKey}`] = JSON.stringify(appealIds);
                            this._updateChangedGridRows(grid, gridTracker);
                            this._navigationServiceHandler.go('appealApplicationBatch', { appealBatchId: newKey });
                            this._loadingSubject.next(null);
                        };

                        const errorCallback = (errorResult) => {
                            const results: string[] = _.uniq(errorResult.map(er => er.errorMessage));
                            this._taskService.showErrorNotificationModal(results, [],
                                'The following error or errors were encountered attempting to create appeal applications',
                                'The following warning or warnings were encountered attempting to create appeal applications');
                            this._loadingSubject.next(null);
                        };

                        // Kind of a hack; if we detect that there are some appeals where the user doesn't have permission,
                        // bail out by creating a result that looks like it came from a bulk API endpoint. This ensures
                        // the appropriate rows get highlighted in red and that the error message is displayed in a consistent
                        // way.
                        const t = searchResult
                            .filter(r => !r.hasPermission)
                            .map(r => {
                                return {
                                    taskID: r.taskID,
                                    errorMessage: 'You do not have permission to edit this appeal'
                                } as Compliance.AppealApplicationTaskSearchResult;
                            });
                        errorResults = [...errorResults, ...t];

                        if (errorResults.length > 0) {
                            try {
                                await this._processResultPromise(grid, gridTracker, errorResults as any);
                            } catch(err) {
                                errorCallback(err.errors);
                            }
                            return;
                        }

                        if (taskIdsNeedingApplications.length > 0) {
                            try {
                                const processingResult = await this._appealApplicationService.createNewApplicationBulk(taskIdsNeedingApplications);
                                const finalResult = await this._processResultPromise(grid, gridTracker, processingResult);
                                if (finalResult.errorResults.length < 1) {
                                    callback();
                                } else {
                                    errorCallback(finalResult.errorResults);
                                }
                            } catch(finalResult) {
                                errorCallback(finalResult.errors);
                            }
                        } else {
                            callback();
                        }
                    }
                },
                { id: TaskActionViewContextOption.ChangeSubmitEvidenceDate, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 11) },
                { id: TaskActionViewContextOption.ChangeInformalHearingDate, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 12) },
                { id: TaskActionViewContextOption.ChangeFormalHearingDate, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 13) },
                { id: TaskActionViewContextOption.ChangePaymentDueDate, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 14) },
                { id: TaskActionViewContextOption.ChangeIntakeItemDueDate, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 15) },
                { id: TaskActionViewContextOption.ChangeComplianceFilingDueDate, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 16) },
                {
                    id: TaskActionViewContextOption.Transmit,
                    limitMessage: true,
                    function: async (taskIDs: number[]) => {
                        this._loadingSubject.next('Creating payment packages...');
                        try {
                            const result = await this._transmittalService.CreatePaymentPackage(taskIDs);
                            try {
                                await this._processResultPromise(grid, gridTracker, result.operationResult);
                                this._loadingSubject.next(null);
                                const newKey = (new Date()).getTime();
                                sessionStorage[`PaymentPackageDrafts${newKey}`] = JSON.stringify(result.packageIDs);
                                this._navigationServiceHandler.go('paymentPackagesDraft', {
                                    draftID: newKey,
                                    isTransmittal: false,
                                    paymentBatchId: null,
                                    taskId: null
                                });
                            } catch (errorResult) {
                                this._loadingSubject.next(null);
                                const errors = _.uniq(_.map(_.filter(errorResult.errors, (error) => {
                                    return error.errorMessage;
                                }), 'errorMessage'));
                                const warnings = _.uniq(_.map(_.filter(errorResult.errors, (error) => {
                                    return !error.errorMessage;
                                }), 'warningMessage'));
                                this._taskService.showErrorNotificationModal(errors, warnings,
                                    'The following error or errors were encountered attempting to create bill ' +
                                    'payment packages; please attempt to correct these errors and try again',
                                    'The following issue or issues were encountered attempting to create bill ' +
                                    'payment packages; tasks which had issues will require re-review before ' +
                                    'they can be processed');
                                //this._toastr.error(errorResult.message, 'Error!');
                            }
                        } catch (err) {
                            console.error(err);
                            this._toastr.error('An unexpected error has occurred.');
                            this._loadingSubject.next(null);
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.ReviewPayment,
                    limitMessage: 'Are you sure you want to complete #taskcount tasks?',
                    function: async (taskIDs: number[]) => {
                        this._loadingSubject.next('Updating payment review status...');
                        // This should probably all be part of the processPaymentReviewed API call, but we want a specific message
                        // for missing attachment IDs. In the future, we should probably make this message part of the bulk operation
                        // result and look for it there as part of the payment reviewed call. This works fine for now, but is slightly
                        // slower.
                        try {
                            const result = await this._transmittalService.CheckForMissingAttachments(taskIDs);
                            if (result.length > 0) {
                                this._loadingSubject.next(null);
                                this._toastr.error('One or more payments do not have a bill image associated. A payment must have a bill image associated to be reviewed in an Action View.');
                                grid.forEachNode((node: RowNode) => {
                                    if (result.includes(node.data.n10908)) {
                                        node.data.error = true;
                                    }
                                });
                                this._actionViewPersistenceService.selectionData = await gridTracker.getSelectedRowIds();
                                grid.redrawRows();
                            } else {
                                try {
                                    const processResult = await this._processingService.processPaymentReviewed({
                                        taskIDs: taskIDs,
                                        searchTimestamp: searchTimestamp
                                    });
                                    this._loadingSubject.next(null);
                                    await this._processResult(grid, gridTracker, processResult);
                                } finally {
                                    this._loadingSubject.next(null);
                                }
                            }
                        } finally {
                            this._loadingSubject.next(null);
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.ReReviewPayment,
                    function: async (taskIDs: number[]) => {
                        try {
                            // TODO: Maybe shorten this message to less than book length
                            await this._messageBoxService.confirmYesNo('Payment Transmittal is blocked for any payments which have completed review tasks ' +
                                'where the parcel or collector has changed since the task was complete. Once you have verified that the changes ' +
                                'do not impact the payment you can mark the Transmit Payment task as reviewed, which will clear the error. Are ' +
                                `you sure you want to mark ${  (gridTracker.getSelectedRowsCount() === 1) ? 'this task' : (`these ${  gridTracker.getSelectedRowsCount()  } tasks`)
                                } as reviewed?`);
                        } catch(err) {
                            return;
                        }
                        this._loadingSubject.next('Updating payment review status...');

                        try {
                            const result = await this._transmittalService.ReReview({
                                taskIDs: taskIDs,
                                searchTimestamp: searchTimestamp
                            });
                            await this._processResult(grid, gridTracker, result);
                        } finally {
                            this._loadingSubject.next(null);
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.CreateInvoice,
                    function: async (taskIDs: number[]) => {
                        try {
                            await this._messageBoxService.confirmYesNo((gridTracker.getSelectedRowsCount() === 1) ?
                                'Selected Invoice Appeal Task will be marked complete and the related appeal savings converted into a saved ' +
                                'contingency invoice ready to process as a draft. Proceed?' :
                                'Selected Invoice Appeal Tasks will be marked complete and their related appeal savings converted into saved ' +
                                'contingency invoices ready to process as drafts. Proceed?');
                        } catch(err) {
                            return;
                        }

                        this._loadingSubject.next('Creating invoices...');

                        // TODO: Move this to the invoice service
                        try {
                            const result = await lastValueFrom(this._invoiceRepository.getInvoiceByTaskIds(taskIDs));
                            this._loadingSubject.next(null);
                            const callback = (resultObject) => {
                                let showToast = true;
                                if (resultObject.errorResults && resultObject.errorResults.length > 0) {
                                    // Get unique warningMessage properties out of the resultObject.errorResults array and add them to the errors array
                                    const warnings = _.uniq(_.map(_.filter(resultObject.errorResults, (error) => {
                                        return !error.errorMessage;
                                    }), 'warningMessage'));

                                    // If we got error messages but no user-friendly warning messages, don't bother with the
                                    // error-notification modal; the toast will be sufficient for that case
                                    if (_.some(warnings)) {
                                        const errors = [];
                                        // If results have 'warningMessage' properties, the API has sent a user-readable message in
                                        // that field; otherwise, there will be an 'errorMessage' property (that we surpress here)
                                        if (_.some(resultObject.errorResults, (errorResult) => !errorResult.warningMessage)) {
                                            errors.push('An unexpected error has occurred');
                                        }

                                        this._taskService.showErrorNotificationModal(errors, warnings,
                                            'The following error or errors were encountered attempting to create invoices');
                                        showToast = _.some(resultObject.successfulResults);
                                    }
                                }

                                if (showToast) {
                                    this._toastProcessResult(resultObject);
                                }
                            };

                            try {
                                const resultObject = await this._processResultPromise(grid, gridTracker, result.operationResult);
                                callback(resultObject);
                            } catch(errorResult) {
                                callback({
                                    errorResults: errorResult.errors
                                });
                            }
                        } finally {
                            this._loadingSubject.next(null);
                        }
                    }
                },
                { id: TaskActionViewContextOption.PrepareDraftInvoice, function: () => this._showInvoicePage(grid, gridTracker) },
                { id: TaskActionViewContextOption.ReviewDraftInvoice, function: () => this._showInvoicePage(grid, gridTracker) },
                { id: TaskActionViewContextOption.PreviewThenTransferInvoice, function: () => this._showInvoicePage(grid, gridTracker) },
                { id: TaskActionViewContextOption.PreviewThenTransferUBR, function: () => this._showInvoicePage(grid, gridTracker) },
                { id: TaskActionViewContextOption.RequestReliefOfUBR, function: () => this._showInvoicePage(grid, gridTracker) },
                {
                    id: TaskActionViewContextOption.MarkInvoiceUBR,
                    limitMessage: true,
                    function: async (taskIDs: number[]) => {
                        try{
                            await this._messageBoxService.confirmYesNo('Selected Invoices will have their UBR flag set. Proceed?');
                        } catch(err) {
                            return;
                        }

                        this._loadingSubject.next('Editing invoices...');
                        const result = await lastValueFrom(this._invoiceRepository.bulkMarkUBR(taskIDs));
                        this._loadingSubject.next(null);

                        const handleErrors = (errorResult) => {
                            const validationErrors = _.map(_.filter(errorResult, 'warningMessage'), 'warningMessage');

                            if (_.some(validationErrors)) {
                                _.forEach(validationErrors, (err) => {
                                    this._toastr.warning(err);
                                });
                            }
                        };

                        try {
                            const processingResult = await this._processResultPromise(grid, gridTracker, result);
                            handleErrors(processingResult.errorResults);
                            this._toastProcessResult(processingResult);
                        } catch(errorResult) {
                            handleErrors(errorResult.errors);
                            if (_.some(errorResult.errors, 'errorMessage')) {
                                this._toastr.error(errorResult.message, 'Error!');
                            }
                        } finally {
                            this._loadingSubject.next(null);
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.TransferInvoice,
                    limitMessage: true,
                    function: async (taskIDs: number[]) => this._bulkTransferToRIBS(grid, gridTracker, 'Invoice', taskIDs)
                }, {
                    id: TaskActionViewContextOption.TransferUBR,
                    limitMessage: true,
                    function: async (taskIDs: number[]) => this._bulkTransferToRIBS(grid, gridTracker, 'UBR', taskIDs)
                }, { id: TaskActionViewContextOption.ChangeInvoiceDueDate, function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 25) },
                {
                    id: TaskActionViewContextOption.Skip,
                    limitMessage: true,
                    function: async (taskIDs: number[]) => {
                        try {
                            await this._messageBoxService.confirmYesNo('Skipping a task completes it and marks it as "skipped"; no further operations ' +
                                `will be allowed on any skipped task. Are you sure you want to skip ${
                                    taskIDs.length === 1 ? 'this task' : (`these ${  taskIDs.length  } tasks`)  }?`);
                        } catch {
                            return;
                        }

                        try {
                            this._loadingSubject.next('Skipping...');
                            const result = await this._taskService.skipMany(taskIDs);
                            this._loadingSubject.next(null);
                            await this._processResult(grid, gridTracker, result);
                        } catch(err) {
                            this._loadingSubject.next(null);
                            this._toastr.error('An unexpected error has ocurred');
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.PerformOCR,
                    function: async (taskIDs: number[]) => {
                        try {
                            await this._messageBoxService.confirmYesNo(`Are you sure you want to perform OCR on ${
                                taskIDs.length === 1 ? 'this task' : (`these ${  taskIDs.length  } tasks`)  }?`);
                            this._loadingSubject.next('Updating tasks to perform OCR...');
                        } catch(err) {
                            return;
                        }

                        try {
                            const result = await this._processingService.performOCR(taskIDs);
                            this._loadingSubject.next(null);
                            await this._processResult(grid, gridTracker, result);
                        } catch(err) {
                            this._loadingSubject.next(null);
                            this._toastr.error('An unexpected error has ocurred');
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.PPReturnDataLoaded,
                    limitMessage: true,
                    function: async (taskIDs: number[]) => {
                        try {
                            await this._messageBoxService.confirmYesNo(`Are you sure you want to update ${
                                taskIDs.length === 1 ? 'this task' : (`these ${  taskIDs.length  } tasks`)  }?`);
                            this._loadingSubject.next('Updating Data Loaded tasks...');
                        } catch(err) {
                            return;
                        }

                        try {
                            const result = await this._taskService.completeMany(taskIDs);
                            this._loadingSubject.next(null);
                            await this._processResult(grid, gridTracker, result);
                        } catch(err) {
                            this._loadingSubject.next(null);
                            this._toastr.error('An unexpected error has ocurred');
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.SetDocumentRetrievalStatus,
                    limitMessage: true,
                    function: async (taskIDs: number[], instanceId: number) => {
                        const result = await this._retrievalStatusModalService.launchSetDocumentRetrievalStatusModal(taskIDs, instanceId);
                        await this._runModalResult(grid, gridTracker, result, 'Processing...');
                    }
                }, {
                    id: TaskActionViewContextOption.ChangeConfirmHearingDate,
                    function: () => this._changeDueDates(grid, gridTracker, searchTimestamp, 35)
                }, {
                    id: TaskActionViewContextOption.AddObtainPaymentReceiptTasks,
                    limitMessage: 'Are you sure you want to add up to #taskcount tasks?',
                    function: async (taskIDs: number[]) => {
                        this._loadingSubject.next('Adding obtain payment receipt tasks...');
                        try {
                            const result = await this._addObtainPaymentreceiptTasks(taskIDs);
                            await this._processResult(grid, gridTracker, result);
                        } finally {
                            this._loadingSubject.next(null);
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.CompleteObtainPaymentReceiptTasks,
                    limitMessage: 'Are you sure you want to complete up to #taskcount tasks?',
                    function: async (taskIDs: number[]) => {
                        this._loadingSubject.next('Completing obtain payment receipt tasks...');
                        try {
                            const result = await this._completeObtainPaymentReceiptTasks(searchTimestamp, taskIDs);
                            await this._processResult(grid, gridTracker, result);
                        } finally {
                            this._loadingSubject.next(null);
                        }
                    }
                }, {
                    id: TaskActionViewContextOption.InsertObtainWorkpapersTaskBefore, function: async (taskIDs: number[]) => {
                        this._loadingSubject.next('Adding obtain workpapers tasks...');
                        const result = await this._addObtainWorkpapersTasks(taskIDs);
                        await this._processResult(grid, gridTracker, result);
                        this._loadingSubject.next(null);
                    }
                }, {
                    id: TaskActionViewContextOption.GenerateAppealListReport,
                    function: async (taskIDs: number[]) => {
                        const request = {
                            taskIds: taskIDs
                        } as AppealListReportRequest;
                        await this._reportingService.runAppealListReport(request);
                        this._loadingSubject.next(null);
                    }
                }, {
                    id: TaskActionViewContextOption.AppealRecommendation,
                    function: async (taskIDs: number[], instanceId: number) => {
                        const rows = await this._getSortedSelection(grid, gridTracker);
                        const keyValues = ['Parcel Acct Num', 'Site Name', 'Site State', 'Parcel Assessor', 'Site Class', 'Prop Type', 'Appeal Deadline'].reduce((acc, x) => {
                            const col: ColDef = gridOptions.columnApi['columnController'].columnDefs.find(c => c.headerName === x);
                            acc[x] = col ? rows.map(y => y[col.field]) : [];
                            return acc;
                        }, {});
                        this._productAnalyticsService.logEvent('right-click-AV-appeal-recommendation', {
                            noOfParcels: _.uniq(keyValues['Parcel Acct Num']).length,
                            noOfSites: _.uniq(keyValues['Site Name']).length,
                            siteState: _.uniq(keyValues['Site State']),
                            siteJuris: _.uniq(keyValues['Parcel Assessor']),
                            siteClass: _.uniq(keyValues['Site Class']),
                            propertyType: _.uniq(keyValues['Prop Type']),
                            daysToAppealDeadline: _.uniq(keyValues['Appeal Deadline']),
                        });
                        const result = await this._appealRecommendationModalService.launchModal(taskIDs, instanceId);
                        await this._runModalResult(grid, gridTracker, result, 'Processing...');
                    }
                }, {
                    id: TaskActionViewContextOption.AddToPaymentBatch,
                    function: async (taskIDs: number[]) => {
                        const topLevelCompanyIds: number[] = [];
                        grid.forEachNode(x => {
                            if (!topLevelCompanyIds.includes(x.data.n110)) {
                                topLevelCompanyIds.push(x.data.n110);
                            }
                        });

                        if(topLevelCompanyIds.length > 1) {
                            this._toastr.error('Selected Payments must belong to the same Top Level Company.');

                        } else if (topLevelCompanyIds.length == 0) {
                            this._toastr.error('Unable to determine Top Level Company of selected Payments.');
                        } else {
                            const result = await this._addPaymentsToBatchModalService.launchModal(topLevelCompanyIds[0], taskIDs);

                            if(result) {
                                await this._runModalResult(grid, gridTracker, result, 'Adding Payments...');
                            }
                        }
                    }
                }
            ];


            const itemPayload = await gridTracker.getSelectedRowIds();

            const results = await lastValueFrom(this._actionViewRepository.getTaskContextOptions(itemPayload));

            return results.options.map((contextOption) => {
                const menuHandler = _.find(menuItems, { id: contextOption.optionId });
                return {
                    name: contextOption.text,
                    onClick: async () => {
                        this._productAnalyticsService.logEvent('select-action-view-right-click-option', { avAction: contextOption.text });
                        // Shouldn't happen; this will be if we add an operation to the API and don't handle
                        // it in the UI
                        if (!menuHandler) {
                            this._toastr.error('Error: processing is disabled for this operation.');
                        } else if (menuHandler.limitMessage && gridTracker.getSelectedRowsCount() >= this._warningLimit) {
                            let message: string;
                            if (menuHandler.limitMessage === true) {
                                message = `Are you sure you want to process ${gridTracker.getSelectedRowsCount()} tasks?`;
                            } else {
                                // Use the magic string #taskcount to insert the number of selected items into the message
                                message = menuHandler.limitMessage.replace('#taskcount', `${gridTracker.getSelectedRowsCount()}`);
                            }
                            try {
                                await this._messageBoxService.confirmYesNo(message);
                            } catch (err) {
                                return;
                            }
                            await menuHandler.function(itemPayload);
                        } else {
                            await menuHandler.function(itemPayload, results.singleInstanceId);
                        }
                    }
                };
            });
        } else {
            return [];
        }
    }

    hasErrors(): boolean {
        return this._errors.length > 0;
    }

    clearErrors(): void {
        this._errors = [];
    }

    private async _getSortedSelection(grid: GridApi, gridTracker: AgGridMultiSelectTracker) {
        const rows = [];
        grid.forEachNodeAfterFilterAndSort((row) => {
            if (gridTracker.isRowSelected(row.id)) {
                rows.push(row.data);
            }
        });
        return rows;
    }

    private async _runModalResult(grid: GridApi, gridTracker: AgGridMultiSelectTracker, modalResultFunction, loadingText?: string, callback?) {
        this._loadingSubject.next(loadingText);
        // Most of the time "callback" won't be specified, and we'll just want to go right to the default
        // "processResult" function
        try {
            let result = await modalResultFunction();
            this._loadingSubject.next(null);
            result = this._correctForAsyncError(result);
            if (callback) {
                callback(result);
            } else {
                // processResult(result);
                await this._processAddPaymentsToBatchResult(grid, gridTracker, result);
            }
        } catch(err) {
            this._loadingSubject.next(null);
            this._toastr.error('An unexpected error has occurred');
        }
    }

    private async _changeDueDates(grid: GridApi, gridTracker: AgGridMultiSelectTracker, searchTimestamp, optionID: number): Promise<void> {
        if (gridTracker.getSelectedRowsCount() >= this._warningLimit) {
            try {
                await this._messageBoxService.confirmYesNo(`Are you sure you want to change the due dates of ${gridTracker.getSelectedRowsCount()} tasks?`);
            } catch(err) {
                return;
            }
        }

        const taskIDs = await gridTracker.getSelectedRowIds();
        const params: ChangeDueDateModalParams = {
            taskIDs,
            optionID,
            searchTimestamp,
            parentCallsService: true
        };
        const result = await this._weissmanModalService.showAsync(ChangeDueDateModalComponent, params, 'modal-lg');
        if (result) {
            await this._runModalResult(grid, gridTracker, result, 'Processing date change...');
        }
    }

    private _addObtainPaymentreceiptTasks(taskIds: number[]): Promise<any> {
        return lastValueFrom(this._actionViewRepository.addObtainPaymentreceiptTasks(taskIds));
    }

    private _addObtainWorkpapersTasks(taskIds: number[]): Promise<any> {
        return lastValueFrom(this._actionViewRepository.addObtainWorkpapersTasks(taskIds));
    }

    private _completeObtainPaymentReceiptTasks(searchTimestamp: Date, taskIds: number[]): Promise<any> {
        const payload: ObtainPaymentReceiptTasksRequest = {
            taskIDs: taskIds,
            searchTimestamp: searchTimestamp
        };
        return lastValueFrom(this._actionViewRepository.completeObtainPaymentReceiptTasks(payload));
    }

    private async _showInvoicePage(grid: GridApi, gridTracker: AgGridMultiSelectTracker): Promise<void> {
        const taskIds = await gridTracker.getSelectedRowIds();
        await this._updateChangedGridRows(grid, gridTracker);
        const newKey = (new Date()).getTime();
        sessionStorage[`InvoiceDrafts${  newKey}`] = JSON.stringify(taskIds);
        this._navigationServiceHandler.go('processInvoice', { draftId: newKey });
    }

    private async _bulkTransferToRIBS(grid: GridApi, gridTracker: AgGridMultiSelectTracker, label: string, taskIDs: number[]): Promise<void> {
        try {
            await this._messageBoxService.confirmYesNo(`Selected ${  label  }s will be transferred to RIBS. Proceed?`);
        } catch(err) {
            return;
        }

        this._loadingSubject.next('Transferring invoices...');
        try {
            // TODO: Move this to the invoice service
            const result = await lastValueFrom(this._invoiceRepository.bulkTransferToRIBS(taskIDs));
            this._loadingSubject.next(null);
            const handleErrors = (errorResult) => {
                const validationErrors = _.map(_.filter(errorResult, 'warningMessage'), 'warningMessage');

                if (_.some(validationErrors)) {
                    _.forEach(validationErrors, (err, key) => {
                        this._toastr.warning(err);
                    });
                }
            };
            try {
                const processingResult = await this._processResultPromise(grid, gridTracker, result);
                handleErrors(processingResult.errorResults);
                this._toastProcessResult(processingResult);
            } catch(errorResult) {
                handleErrors(errorResult.errors);
                if (_.some(errorResult.errors, 'errorMessage')) {
                    this._toastr.error(errorResult.message, 'Error!');
                }
            }
        } finally {
            this._loadingSubject.next(null);
        }
    }

    /* Example bulk processing payload (reassign in this case):
    [{
        "taskID": 23254880,
        "entityID": 29522,
        "entityTypeID": 15,
        "errorMessage": null,
        "isAuthorized": true,
        "authorizationReason": "Assigned User",
        "changedFields": {
            "n10900": "5b1d7e74-771b-4619-a54c-f2b135fb515d",
            "n10901": 72,
            "f10002": "Rann, Jeremy",
            "f10003": "Team Project Weissman"
        },
        "markStale": false // Typically there's either a changedFields object or markStake is true
    }]*/
    private async _processResultPromise(grid: GridApi, gridTracker: AgGridMultiSelectTracker, processingResult: Core.BulkOperationResult[]) {
        const successfulResults = _.filter(processingResult, (result) => {
            return !result.errorMessage && !result.warningMessage && !result.isConcurrencyCheckFailed;
        });
        const errorResults = _.filter(processingResult, (result) => {
            return result.errorMessage || result.warningMessage || result.isConcurrencyCheckFailed;
        });

        this._markErrorResults(grid, gridTracker, successfulResults, errorResults);

        if (successfulResults.length < 1) {
            if (errorResults.length < 1) {
                console.log('Processing result successfully retrieved from server but could not be parsed');
                return Promise.reject({
                    message: this.GENERIC_ERROR_MESSAGE,
                    errors: errorResults
                });
            } else if (_.every(errorResults, (result) => { return !result.isAuthorized; })) {
                // All results have authorization errors
                return Promise.reject({
                    message: this.PERMISSION_ERROR_MESSAGE,
                    errors: errorResults
                });
            } else if (_.every(errorResults, (result) => { return result.isConcurrencyCheckFailed; })) {
                // All results have concurrency errors
                return Promise.reject({
                    message: this.CONCURRENCY_ERROR_MESSAGE,
                    errors: errorResults
                });
            } else {
                console.log(`Processing result successfully retrieved from server, but processing indicated errors (taskID: error):\n${
                    _.map(processingResult, (result) => {
                        return `${result.taskID  }: ${  result.errorMessage || result.warningMessage}`;
                    }).join('\n')}`);
                return Promise.reject({
                    message: this.GENERIC_ERROR_MESSAGE,
                    errors: errorResults
                });
            }
        } else {
            const authReasons = [];
            _.forEach(successfulResults, (result) => {
                authReasons.push(`${result.taskID  }: ${  result.authorizationReason}`);

                let row: RowNode;
                grid.forEachNode(x =>  {
                    if (x.data.n10908 === result.taskID) {
                        row = x;
                    }
                });

                let persistIndex = null;
                if (this._actionViewPersistenceService && this._actionViewPersistenceService.detailResults
                    && this._actionViewPersistenceService.detailResults.dataTable) {
                    persistIndex = _.findIndex(this._actionViewPersistenceService.detailResults.dataTable, { n10908: result.taskID });
                }

                // It is valid that a result may come back for a row that isn't in the results; at least one example
                // is a dismiss intake item operation, which may affect both an Identify/Data Enter task and a
                // Perform QC task where only one of those is actually in the grid
                if (row) {
                    // If the actionResult has a column named n10908 (which is the taskID), that's a flag that
                    // this row contains search result data that sould be live-updated; if this is not present,
                    // simply mark the corresponding row as stale
                    if (result.markStale) {
                        // Note that row options such as .stale are processed in SD.AgGrid.Directive.js (getRowStyle function)
                        row.data.stale = true;
                    } else {
                        gridTracker.clear();
                        _.assign(row.data, result.changedFields);
                        if (persistIndex !== null && persistIndex >= 0) {
                            _.assign(this._actionViewPersistenceService.detailResults.dataTable[persistIndex], result.changedFields);
                        }
                    }
                }
            });
            grid.redrawRows();

            if (authReasons.length > 0) {
                console.log('Task authorization reasons (taskID: reason):\n', authReasons.join('\n'));
            }

            return {
                successfulResults: successfulResults,
                errorResults: errorResults
            };
        }
    }

    private async _markErrorResults(grid: GridApi, gridTracker: AgGridMultiSelectTracker, successfulResults, errorResults): Promise<void> {
        const consoleErrors = [];

        _.forEach(successfulResults, (result) => {
            let row: RowNode;
            grid.forEachNode(x =>  {
                if (x.data.n10908 === result.taskID) {
                    row = x;
                }
            });
            if (row) {
                row.data.warning = false;
                row.data.error = false;
            }

            this._errors = _.reject(this._errors, {taskID: result.taskID});
        });

        _.forEach(errorResults, (result) => {
            consoleErrors.push(`${result.taskID  }: ${  result.errorMessage || result.warningMessage}`);
            this._errors.push(result);

            let row: RowNode;
            grid.forEachNode(x =>  {
                if (x.data.n10908 === result.taskID) {
                    row = x;
                }
            });
            if (row) {
                if (!result.errorMessage && result.warningMessage) {
                    row.data.warning = true;
                } else {
                    row.data.error = true;
                }
            }
        });

        if (consoleErrors.length > 0) {
            console.log(`Processing errors (taskID: error):\n${  consoleErrors.join('\n')}`);
        }

        this._actionViewPersistenceService.selectionData = await gridTracker.getSelectedRowIds();
        grid.redrawRows();
    }

    private _correctForAsyncError(result: any): Core.BulkOperationResult[] {
        if (result.asyncState !== undefined && result.result !== undefined) {
            console.log('WARNING: An asyncronous task result appears to have come back from the API; please check for a Task return and correct if necessary');
            return result.result;
        }
        else {
            return result;
        }
    }

    private async _processResult(grid: GridApi, gridTracker: AgGridMultiSelectTracker, processingResult): Promise<void> {
        try {
            const result = await this._processResultPromise(grid, gridTracker, processingResult);
            this._toastProcessResult(result);
        } catch(errorResult) {
            this._toastr.error(errorResult.message, 'Error!');
        }
    }

    private async _processAddPaymentsToBatchResult(grid: GridApi, gridTracker: AgGridMultiSelectTracker, processingResult: Core.BulkOperationResult[]): Promise<void> {
        try {
            const result = await this._processResultPromise(grid, gridTracker, processingResult);
            this._toastProcessResult(result);
        } catch (errorResult) {
            const errors = _.uniq(_.map(_.filter(errorResult.errors, (error) => {
                return error.errorMessage;
            }), 'errorMessage'));
            const warnings = _.uniq(_.map(_.filter(errorResult.errors, (error) => {
                return !error.errorMessage;
            }), 'warningMessage'));
            this._taskService.showErrorNotificationModal(errors, warnings,
                'The following error(s) or warning(s) were encountered attempting to add payments ' +
                'to a batch; please attempt to correct these errors and try again');
            //this._toastr.error(errorResult.message, 'Error!');
        }
    }

    private _toastProcessResult(resolution) {
        if (resolution.errorResults.length < 1) {
            this._toastr.success(`Successfully processed ${  resolution.successfulResults.length  } tasks`);
        } else if (!resolution.successfulResults || resolution.successfulResults.length < 1) {
            this._toastr.error('An unexpected error has occurred');
        } else {
            this._toastr.warning(`Only some tasks successfully processed; ${  resolution.successfulResults.length  } succeeded and ${  resolution.errorResults.length  } failed`);
        }
    }

    private async _updateChangedGridRows(grid: GridApi, gridTracker: AgGridMultiSelectTracker): Promise<void> {
        const updatedNodes: RowNode[] = [];
        grid.forEachNode((row) => {
            if (gridTracker.isRowSelected(row.id)) {
                row.data.stale = true;
                updatedNodes.push(row);
            }
        });

        this._actionViewPersistenceService.selectionData = await gridTracker.getSelectedRowIds();
        grid.redrawRows({ rowNodes: updatedNodes });
    }
}
